export default {
    user: {
        login: '',
        username: '',
        password: '',
        token: '',
        loggedIn: false,
        mensagem: '',
        menus: []
    },
    cadastro_login: {
        usuario: '',
        nome_completo: '',
        telefone: '',
        cidade: '',
        estado: '',
        corporacao: '',
        senha: '',
        senhaProvisorio: false
    },
    usuario_zero: {
        usuario: '',
        nome: '',
        telefone: '',
        cidade: '',
        estado: '',
        corporacao: '',
        password: '',
        senhaProvisorio: false,
        ativo: true
    },
    lista_usuarios: [],
    pesquisa_lista_usuarios: [],
    selected_usuario_list: [],
    campo_usuario: true,
    campo_usuario_modo: '',
    cadastro_usuario: [],
    altera_usuario: [],
    menu: [],
}