
<script>
import { mapState } from "vuex"
import Vue from "vue"

export default {
  name: "EntradaProgramador",
  props: { 
    subProgramadores: Array,
    programador: Object, 
    posicaoProgramador: Number
  },
  data() {
    return {
      opcaoDiasSemana: [
        { text: "Domingo", value: "1" },
        { text: "Segunda", value: "2" },
        { text: "Terça", value: "3" },
        { text: "Quarta", value: "4" },
        { text: "Quinta", value: "5" },
        { text: "Sexta", value: "6" },
        { text: "Sabado", value: "7" },
      ],
      opcaoDiasSemanaMobile: [
        { text: "Dom", value: "1" },
        { text: "Seg", value: "2" },
        { text: "Ter", value: "3" },
        { text: "Qua", value: "4" },
        { text: "Qui", value: "5" },
        { text: "Sex", value: "6" },
        { text: "Sab", value: "7" },
      ],
      value: "",
      flavours: ["1", "2", "3", "4", "5", "6", "7"],
    }
  },
  computed: {
    ...mapState({
      programador_editar: (state) => state.moduleProgramador.programador_editar,
    }),
  },
  methods: {
    toggleAll(checked, planoEntrada) {
      planoEntrada.dias_semana = checked ? this.flavours.slice() : []
    },
    planosEntradas() {
      if (this.programador.planos.length == 0) {
        this.$swal.fire({
          text: "Insira pelo menos um plano",
          icon: "warning",
        })
        return
      }

      if (this.posicaoProgramador > 0 && this.programador.entradas.length == 0){
        this.$swal.fire({
          title: 'Deseja copiar a entrada do Anel anterior?',
          showDenyButton: true,
          confirmButtonText: 'Sim',
          denyButtonText: `Não copiar`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.programador.entradas = JSON.parse(JSON.stringify(this.subProgramadores[this.posicaoProgramador-1].entradas));
            this.$swal.fire('Copiado!', '', 'success')
          } else if (result.isDenied) {
            this.programador.entradas.push({
              horario: "00:00:00",
              dias_semana: [],
              plano: 1,
            }) 
          }
        })
      }else{
        this.programador.entradas.push({
          horario: "00:00:00",
          dias_semana: [],
          plano: 1,
        })  
      }
      
    },
    removeEntrada(entrada) {
      this.programador.entradas = this.programador.entradas.filter(function (
        item
      ) {
        return item !== entrada
      })
    },
  },
  watch: {
    programador:{
      handler(val){
        let entradas = []
        for (let aux in val.entradas) {
          let entrada = val.entradas[aux]
          let horario = new Date(0, 0, 0, entrada.hora, entrada.minuto, entrada.segundo, 0)
          horario =
            horario.getHours().toString().padStart(2, "0") + ":" +
            horario.getMinutes().toString().padStart(2, "0") + ":" +
            horario.getSeconds().toString().padStart(2, "0")

          if (entradas.length == 0){
            let dias_semana = []
            dias_semana.push(parseInt(entrada.dia.toString()))
            entradas.push({
              horario: horario,
              dias_semana: dias_semana,
              plano: entrada.plano,
            })
          }else{
            let possui = false
            let posicao = 0
            for (let aux1 in entradas){
              let entrada1 = entradas[aux1]
              let horario1 = entrada1.horario
              if (horario1 == horario && entrada.plano == entrada1.plano){
                possui = true
                posicao = aux1
              }
            }
            if (possui) {
              entradas[posicao].dias_semana.push(parseInt(entrada.dia.toString()))
              entradas[posicao].allSelected = entradas[posicao].dias_semana.length == 7 ? true : false
            } else {
              let dias_semana = []
              dias_semana.push(parseInt(entrada.dia.toString()))
              entradas.push({
                horario: horario,
                dias_semana: dias_semana,
                plano: entrada.plano,
              })
            }
          }
        }
        Vue.set(this.programador, "entradas", entradas)
      },
      immediate: true 
    },
  },
}
</script>

<template>
  <div>
    <b-row v-for="(planoEntrada, index) in programador.entradas" :key="index">
      <b-col>
        <b-row>
          <b-col :cols="$isMobile() ? 3 : 1">
            <b-form-text>Entrada: {{ index + 1 }}</b-form-text>
          </b-col>
        </b-row>
        <b-row class="entrada_n">
          <b-col :cols="$isMobile() ? 12 : 5" class="text-left">
            <b-form-text>Horario</b-form-text>
            <b-form-timepicker class="shadow rounded" v-model="planoEntrada.horario" placeholder="Horario" :disabled="programador_editar" reset-button show-seconds />
          </b-col>
          <b-col :cols="$isMobile() ? 12 : 7" class="text-left">
            <b-form-group>
              <template #label>
                <b-form-text>Dias Semanas</b-form-text>
                <b-form-checkbox v-model="planoEntrada.allSelected" :indeterminate="planoEntrada.indeterminate" :disabled="programador_editar"
                  aria-describedby="flavours" aria-controls="flavours" @input="toggleAll($event, planoEntrada)">
                  {{ planoEntrada.allSelected ? "Desmarcar Todos" : "Todos" }}
                </b-form-checkbox>
              </template>

              <template v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group v-model="planoEntrada.dias_semana" :options="$isMobile() ? opcaoDiasSemanaMobile : opcaoDiasSemana"
                  :disabled="programador_editar" :aria-describedby="ariaDescribedby" name="flavors" aria-label="Individual flavours">
                </b-form-checkbox-group>
              </template>
            </b-form-group>
          </b-col>
          <b-col :cols="$isMobile() ? 6 : 5" class="text-left">
            <b-form-text>Planos</b-form-text>
            <b-form-spinbutton :readonly="programador_editar" class="input-cadastro shadow rounded" v-model="planoEntrada.plano" :max="programador.planos.length - 1" min="0"/>
          </b-col>
          <b-col :cols="$isMobile() ? 6 : 7" class="text-left">
            <b-form-text>Opção</b-form-text>
            <b-button class="button_remover_entrada shadow rounded" @click="removeEntrada(planoEntrada)" :disabled="programador_editar" variant="danger">
              Remover
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br/>
    <b-button class="button_entrada shadow rounded" @click="planosEntradas" :disabled="programador_editar" :variant="programador_editar == true ? 'outline-success' : 'success'">
      Inserir Entrada
    </b-button>
    <hr/>
  </div>
</template>


<style>
.input-cadastro {
  box-sizing: border-box;
  margin: 2px;
}

.entrada_n {
  margin: 1px;
  border: 1px solid black;
  padding: 5px;
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.button_remover_entrada {
  padding: 5px 5px 5px 5px;
  margin: 2px;
}

.button_entrada {
  padding: 15px 20px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
}
</style>