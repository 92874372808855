import { render, staticRenderFns } from "./CabecalhoProgramador.vue?vue&type=template&id=cb92e27e&scoped=true&"
import script from "./CabecalhoProgramador.vue?vue&type=script&lang=js&"
export * from "./CabecalhoProgramador.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb92e27e",
  null
  
)

export default component.exports