
<script>
import Funcao from "@/components/Programador/FuncaoProgramador"
import { mapState } from "vuex"

export default {
  name: "AjustarFusoHorario",
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      user: state => state.moduleUsuario.user,
    }),
  },
  methods:{
    ajustarHorario() {
      let localIp = ''
      this.$swal.fire({
        title: "Ajustar fuso horario para padrão",
        html: `
          <b-form-text>Digite o IP do Beagle:</b-form-text>
          <input type="text" id="ip" class="swal2-input" placeholder="192.168.254.101">`,
        preConfirm: () => {
          localIp = this.$swal.getPopup().querySelector("#ip").value
        },
        icon: "warning",
        confirmButtonText: "Confirmar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.ip = localIp
            if (Funcao.validacaoIP(this.ip)) {
              let ip = localIp
              this.$store.dispatch("AJUSTAR_FUSO_HORARIO_CPU", { ip }).then(() => {
                this.monitorar("Colocou fuso horario no padrão no IP: "+ ip +" com Sucesso")
                Funcao.alertaSimples("Ajustar Fuso horario da CPU", "Comando enviado com Sucesso", "success")
              }).catch(() => {
                this.monitorar("Não foi possivel alterar fuso horario no IP: "+ ip)
                Funcao.alertaSimples("Ajustar fuso horario da CPU", "Não foi possivel alteração do fuso horario!", "error")
              })
            } else {
              Funcao.alertaSimples("IP Invalido","Cancelado!","error")
            }
          } else {
            Funcao.alertaSimples("Ajustar fuso horario da CPU","Cancelado!","error")
          }
      })
    },
    monitorar(valor){
      var login = this.user.login
      var procedimento = 'ajustar fuso horario'
      var descricao = valor
      this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
    }
  },
}
</script>

<template>
  <b-button class="float-left btn_filtro shadow rounded" @click="ajustarHorario" variant="info">
    Ajustar Fuso Horario
  </b-button>
</template>

<style>
</style>