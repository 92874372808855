import Vue from 'vue'
export default new Vue({
  methods: {
    setLoadPlanos() {
      this.$emit('loadPlanos')
    },
    onLoadPlanos() {
      this.$on('loadPlanos')
    }
  }
})