
<script>
import { mapState } from "vuex"
import Vue from "vue"

export default {
  name: "TipoFaseProgramador",
  props: { 
    programador: Object,
  },
  data() {
    return {
      opcaoTipoFasesMobile: [
        { text: "V. Comum", value: "cm" },
        { text: "V. Gradativo", value: "cv", disabled: false },
        { text: "P. Comum", value: "p" },
      ],
      opcaoTipoFases: [
        { text: "Veicular Comum", value: "cm" },
        { text: "Veicular Gradativo", value: "cv", disabled: false },
        { text: "Pedestre Comum", value: "p" },
      ],
      linhaFerrea: 'linhaFerrea'
    }
  },
  computed: {
    ...mapState({
      programador_editar: (state) => state.moduleProgramador.programador_editar,
    }),
    linhaFerrea1() {
      return this.programador.linhaFerrea
    },
    fasesQtde(){
      return this.programador.fasesQtde
    }
  },
  watch: {
    linhaFerrea1(val) {
      if(val){
        for (const key in this.programador.tipoFases) {
          if (Object.hasOwnProperty.call(this.programador.tipoFases, key)) {
            var element = this.programador.tipoFases[key];
            if (element == 'cv'){
              Vue.set(this.programador.tipoFases,key, 'cm')

            }
          }
        }
        this.opcaoTipoFases[1].disabled = true
        this.opcaoTipoFasesMobile[1].disabled = true
      }
      else{
        this.opcaoTipoFases[1].disabled = false
        this.opcaoTipoFasesMobile[1].disabled = false
      }
    },
    fasesQtde(){
      for (let index = 0; index < this.programador.fasesQtde; index++) {
        if (this.programador.tipoFases[index] == undefined) {
          if(this.programador.linhaFerrea){
            Vue.set(this.programador.tipoFases, index, "cm")
          }else{
            Vue.set(this.programador.tipoFases, index, "cv")
          }
        }
        if (this.programador.aproximacoes[index] == undefined) {
          Vue.set(this.programador.aproximacoes, index, "")
        }
      }
    }
  }
}
</script>

<template>
  <div>
    <b-row class="text-left" align-v="center">
      <b-col v-for="(aux, index) in programador.fasesQtde" :key="index" class="shadow rounded" :cols="$isMobile() ? 6 : 3" >
        <b-form-group>
          <b-form-text>{{ "Tipo Fase: " + (programador.inicioFase + index).toString() }}</b-form-text>
          <b-form-radio-group :disabled="programador_editar" v-model="programador.tipoFases[index]" :options="$isMobile() ? opcaoTipoFasesMobile : opcaoTipoFases"/>          
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<style>
</style>