<script>
import barramento_filtro from "@/components/Barramentos/Filtros"
import { mapState } from "vuex"

export default {
  name: "AlterarFiltro",
  props: { 
    file: File
  },
  data() {
    return {
      btnAlterarDesativa: true,
    }
  },
  computed: {
    ...mapState({
      user: state => state.moduleUsuario.user,
    }),
  },
  methods:{
    alterar() {
      barramento_filtro.setLoadFileName(this.file.name)
      let editar = false
      let modo = "alterar"
      this.arquivoJson = []
      this.$store.dispatch("PROGRAMADOR_STATUS", { editar, modo })
      this.monitorar()
    },
    monitorar(){
      var login = this.user.login
      var procedimento = 'alterar'
      var descricao = 'clicou em alterar'
      this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
    }
  },
  watch: {
    file() {
      if (this.file == undefined) {
        this.btnAlterarDesativa = true
      } else {
        this.btnAlterarDesativa = false
      }
    },
  }
}
</script>

<template>
  <b-button class="float-right btn_filtro shadow rounded" v-b-toggle.collapse-2 @click="alterar" variant="warning" :disabled="btnAlterarDesativa">
    Alterar
  </b-button>
</template>

<style>
</style>