export default {
  programador_lista: '',
  programador_editar: true,
  programador_modo: '',
  retorno_autenticacao_tabela: 2,
  retorno_autenticacao: '',
  ativar_autenticacao: '',
  desativar_autenticacao: '',
  retorno_envio_tabela_local: '',
  login: {
    usuario: '',
    senha: '',
    getLocation: false,
    latitude: 0,
    longitude: 0,
    localIp: '',
    isMobile: '',
    osName: '',
    osVersion: '',
    fabricante: '',
    modelo: '',
  },
  ajustar_horario_cpu: '',
  ajustar_fuso_horario_cpu: ''
}