import Vue from 'vue'
import crc32 from 'crc/crc32'
import { isMobile, osName, osVersion, mobileVendor, mobileModel } from 'mobile-device-detect'
import { versionJson } from "../../../package.json"

export default new Vue({
  methods: {
    zerarProgramador() {
      return {
        id: '',
        linhaFerrea: false,
        cruzamento: '',
        cidade: '',
        saida: '',
        estado: 17,
        timeZone: 'America/Sao_Paulo',
        showCollapse: false,
        fasesQtde: 2,
        inicioFase: 1,
        maxFases: 8,
        aproximacoes: [],
        tipoFases: ["cv", "cv" ],
        conflitos: {},
        estagios: [],
        estagiosOpcoes: [],
        ordens: [],
        ordemFerrea: [],
        ordensOpcoes: [],
        planos: [],
        planoFerrea: [],
        entradas: [],
        especiais: [],
        versao: ''
      }
    },
    calculoTempoCiclo(aux) {
      return parseInt(aux.vd1) + parseInt(aux.am1) + parseInt(aux.vmb1) + parseInt(aux.vd2) + parseInt(aux.am2) + parseInt(aux.vmb2) + parseInt(aux.vd3) + parseInt(aux.am3) + parseInt(aux.vmb3) + parseInt(aux.vd4) + parseInt(aux.am4) + parseInt(aux.vmb4)
    },
    gerarCrc32(listagem) {
      listagem = JSON.stringify(listagem)
      let utf8Encode = new TextEncoder().encode(listagem)
      return crc32(utf8Encode).toString(16)
    },
    reverseString(str) {
      var splitString = str.split("")
      var reverseArray = splitString.reverse()
      var joinArray = reverseArray.join("")
      return joinArray
    },
    tradutorConflito(value){
      let conflitos = ''
      let valor = value
      let binario = valor.toString(2)
      binario = binario.padStart(8, "0")
      let rBinario = this.reverseString(binario)
      let aBinario = rBinario.split("")
      for (let aux1 = 0; aux1 < aBinario.length; aux1++) {
        if (aBinario[aux1] == 1) {
          conflitos += 'Fase ' + (aux1 + 1) + '| '
        }
      }
      return conflitos
    },
    converterConflitoBinarioDecimal(aux, programador) {
      const binario = []
      if (aux <= programador.fasesQtde) {
        for (let key in programador.conflitos) {
          let estado = programador.conflitos[key]
          if (estado) {
            let posicao = key.split('_')
            if (posicao[0] == aux) {
              binario[posicao[1] - 1] = 1
            }
          }
        }
        binario[aux - 1] = 0
        for (let aux = 0; aux < programador.fasesQtde; aux++) {
          if (binario[aux] == undefined) {
            binario[aux] = 0
          }
        }
      }
      for (let aux = 0; aux < 8; aux++) {
        if (binario[aux] == undefined) {
          binario[aux] = 1
        }
      }
      const rBinario = binario.reverse().toString().replaceAll(',', '')
      const decimal = parseInt(rBinario, 2)
      return decimal
    },
    converterConflitoFerreaBinarioDecimal(aux, programador) {
      const binario = []
      for (let key in programador.conflitos) {
        let estado = programador.conflitos[key]
        if (estado) {
          let posicao = key.split('_')
          if (posicao[0] == aux) {
            binario[posicao[1]] = 1
          }
        }
      }
      
      for (let aux = 0; aux < programador.fasesQtde; aux++) {
        if (binario[aux] == undefined) {
          binario[aux] = 0
        }
      }
      
      for (let aux = 0; aux < 8; aux++) {
        if (binario[aux] == undefined) {
          binario[aux] = 0
        }
      }
      const rBinario = binario.reverse().toString().replaceAll(',', '')
      const decimal = parseInt(rBinario, 2)
      return decimal
    },
    gerarJson(programadores) {
      let stgGerarJson = {}
      stgGerarJson['Cabecalho'] = {}
      stgGerarJson['Subcontrolador'] = []
      programadores.versao = versionJson

      for (let index = 0; index < programadores.length; index++) {
        const programador = programadores[index]

        if (index == 0) {
          stgGerarJson['Cabecalho'] = {
            cidade: programador.cidade,
            estado: programador.estado,
            nome: programador.cruzamento,
            timeZone: programador.timeZone,
          }
        }
        
        let subcontrolador = {}
        stgGerarJson.Subcontrolador.push(subcontrolador)
        subcontrolador['identificacao'] = programador.identificacao,
        subcontrolador['cruzamentoLinhaFerrea'] = programador.linhaFerrea
        subcontrolador['FasesQtde'] = programador.fasesQtde
        subcontrolador['Aproximacoes'] = []
        subcontrolador['TipoFases'] = []
        for (let aux = 0; aux < programador.fasesQtde; aux++) {
          if(programador.aproximacoes[aux] == undefined)
            subcontrolador.Aproximacoes.push('')
          else
            subcontrolador.Aproximacoes.push(programador.aproximacoes[aux])
          subcontrolador.TipoFases.push(programador.tipoFases[aux])
        }

        subcontrolador['Conflitos'] = {}
        for (let aux = 1; aux <= 8; aux++) {
          subcontrolador.Conflitos['cf' + aux] = this.converterConflitoBinarioDecimal(aux, programador)
        }
        if(programador.linhaFerrea)
          subcontrolador.Conflitos['cft'] = this.converterConflitoFerreaBinarioDecimal('ferrea', programador)
        else
          subcontrolador.Conflitos['cft'] = 255

        
        subcontrolador['Estagios'] = []
        for (let index = 0; index < programador.estagios.length; index++) {
          let estagio = programador.estagios[index]
          let fases = []
          for (let key in estagio.faseSelect) {
            let faseSelect = estagio.faseSelect[key]
            if (faseSelect) {
              fases.push(parseInt(key))
            }
          }
          subcontrolador['Estagios'].push({
            "id": index + 1,
            "atuador": estagio.atuadorEstado,
            "atuadorSincronizado": estagio.atuadorSincronizado,
            "atuadorLinhaFerrea": estagio.atuadorLinhaFerrea,
            "atuadorEntrada": parseInt(estagio.atuador),
            "fases": fases
          })
        }

        subcontrolador['Ordem'] = []
        for (let index = 0; index < programador.ordens.length; index++) {
          const ordem = programador.ordens[index]
          let valorOrdem = []
          for (let index1 = 0; index1 < ordem.ordensSelect.length; index1++) {
            let valor = ordem.ordensSelect[index1]
            //valor = valor.replaceAll('Estagio ', '')
            valorOrdem.push(parseInt(valor))
          }
          subcontrolador['Ordem'].push({ "estagios": valorOrdem })
        }
        if(programador.linhaFerrea){
          const ordem = programador.ordemFerrea[0]
          let valorOrdem = []
          for (let index1 = 0; index1 < ordem.ordensSelect.length; index1++) {
            let valor = ordem.ordensSelect[index1]
            //valor = valor.replaceAll('Estagio ', '')
            valorOrdem.push(parseInt(valor))
          }
          subcontrolador['Ordem'].push({ "estagios": valorOrdem })
        }

        subcontrolador['Planos'] = []
        //Default para o amarelo piscante
        subcontrolador['Planos'].push({
          "defasagem": 0,
          "defasagemE": 0,
          "id": 0,
          "ordem": 0,
          "semSincronismo": false,
          "tempoCiclo": 0,
          "tempoCicloE": 0,
          "tempos": [{
            "am": 0,
            "id": 1,
            "vd": 0,
            "vmb": 0,
            "pa": 0
          }]
        })
        let indexUltimoPlano = 0
        for (let index = 1; index < programador.planos.length; index++) {
          const plano = programador.planos[index]
          const tempo = []
          for (let index1 = 0; index1 < programador.ordens[plano.ordensSelect].ordensSelect.length; index1++) {
            let estagioName = programador.ordens[plano.ordensSelect].ordensSelect[index1]
            tempo.push({
              "id": index1 + 1,
              "vd": plano['vd_' + estagioName],
              "am": plano['am_' + estagioName],
              "vmb": plano['vmb_' + estagioName],
              "pa": plano['pa_' + estagioName],
              "vde": plano['vd_' + estagioName + '_ext'],
              "ame": plano['am_' + estagioName + '_ext'],
              "vmbe": plano['vmb_' + estagioName + '_ext'],
              "pae": plano['pa_' + estagioName + '_ext']
            })
          }
          subcontrolador['Planos'].push({
            "tempos": tempo,
            "defasagem": plano.defasagem,
            "defasagemE": plano.defasagemExt,
            "id": index,
            "tempoCiclo": plano.tempoCiclo,
            "tempoCicloE": plano.tempoCicloExt,
            "ordem": plano.ordensSelect,
            "semSincronismo": plano.semSincronismo
          })
          indexUltimoPlano = index + 1
        }
        if(programador.linhaFerrea){
          const plano = programador.planoFerrea[0]
          const tempo = []
          for (let index1 = 0; index1 < programador.ordemFerrea[0].ordensSelect.length; index1++) {
            let estagioName = programador.ordemFerrea[0].ordensSelect[index1]
            tempo.push({
              "id": index1 + 1,
              "vd": plano['vd_' + estagioName],
              "am": plano['am_' + estagioName],
              "vmb": plano['vmb_' + estagioName],
              "pa": plano['pa_' + estagioName],
              "vde": 0,
              "ame": 0,
              "vmbe": 0,
              "pae": 0
            })
          }
          subcontrolador['Planos'].push({
            "tempos": tempo,
            "defasagem": 0,
            "defasagemE": 0,
            "id": indexUltimoPlano,
            "tempoCiclo": plano.tempoCiclo,
            "tempoCicloE": 0,
            "ordem": programador.ordens.length,
            "semSincronismo": false,
          })
        }

        subcontrolador['Entradas'] = []
        for (let aux = 0; aux < programador.entradas.length; aux++) {
          let horarios = programador.entradas[aux].horario.split(':')
          let hora = horarios[0]
          let minuto = horarios[1]
          let segundo = horarios[2]
          for (const key in programador.entradas[aux].dias_semana) {
            let diaSemana = programador.entradas[aux].dias_semana[key].toString()
            subcontrolador['Entradas'].push({
              "dia": parseInt(diaSemana),
              "hora": parseInt(hora),
              "minuto": parseInt(minuto),
              "plano": parseInt(programador.entradas[aux].plano),
              "segundo": parseInt(segundo)
            })
          }
        }
        subcontrolador.Entradas.sort((a, b)=> {
          if (a.dia === b.dia){
            if (a.hora === b.hora)
              return a.minuto < b.minuto ? -1 : 1
            return a.hora < b.hora ? -1 : 1
          } 
          return a.dia < b.dia ? -1 : 1
        })
        
        subcontrolador['DatasEspeciais'] = []
        for (let aux = 0; aux < programador.especiais.length; aux++) {
          let data = new Date(programador.especiais[aux].data)
          let dia = data.getDate().toString().padStart(2, '0')
          let mes = (data.getMonth() + 1).toString().padStart(2, '0') //+1 pois no getMonth Janeiro começa com zero.
          let ano = data.getFullYear()
          subcontrolador['DatasEspeciais'].push({
            diaExecutado: parseInt(programador.especiais[aux].dias_semana.toString()),
            mesDiaAno: "".concat(mes, "/", dia, "/", ano, " 00:00:00")
          })
        }
        
      }
      stgGerarJson['Versao'] = programadores.versao
      stgGerarJson['Crc'] = this.gerarCrc32(stgGerarJson)
      return stgGerarJson
    },
    validacaoIP(ipaddress) {
      if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
        return (true)
      }
      return (false)
    },
    getIpLocalizacao(window, user) {
      user.isMobile = isMobile
      user.osName = osName
      user.osVersion = osVersion
      user.fabricante = mobileVendor
      user.modelo = mobileModel

      window.$store.dispatch('IP_CLIENTE').then((resolve) => { user.localIp = resolve.ip })
      if (!("geolocation" in navigator)) {
        window.$swal.fire({ title: 'Captura da localização', text: 'Error na localização', icon: 'error' })
        user.latitude = -1
        user.longitude = -1
        return
      }
      user.getLocation = true
      navigator.geolocation.getCurrentPosition(pos => {
        user.latitude = pos.coords.latitude
        user.longitude = pos.coords.longitude
      }, err => {
        user.getLocation = false
        user.errorStr = err.message
        user.latitude = -1
        user.longitude = -1
      })
    },
    alertaSimples(titulo, texto, tipo){
      this.$swal.fire({
        title: titulo,
        text: texto,
        icon: tipo,
      })
    },
  }
})
