import Vue from 'vue'
import Vuex from "vuex";
import module_principal from '@/store/module_principal'
import module_programador from '@/store/module_programador'
import module_beagle from '@/store/module_beagle'
import module_usuario from '@/store/module_usuario'
import module_menu from '@/store/module_menu'
import module_monitorar from '@/store/module_monitorar'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    moduleProgramador: module_programador,
    modulePrincipal: module_principal,
    moduleBeagle: module_beagle,
    moduleUsuario: module_usuario,
    moduleMenu: module_menu,
    moduleMonitorar: module_monitorar
  }
})