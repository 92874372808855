<script>
import { mapState } from 'vuex'

export default {
  name: 'FiltrosListaUsuario',
  data () {
    return {
      showCollapse: false,
      selectedEstadoUsuario: null,
      optionsEstadoUsuario: [
        {value: true, text: 'Ativo'},
        {value: false, text: 'Desativado'},
        {value: null, text: 'Todos'}
      ]      
    }
  },
  computed: {
    ...mapState({
        user: state => state.moduleUsuario.user, 
        pesquisa_lista_usuarios: state => state.moduleUsuario.pesquisa_lista_usuarios
    }),
    nomeIcone() {
      return this.showCollapse ? 'chevron-up' : 'chevron-down'
    },
    currentValue() {
      return this.optionsEstadoUsuario.find(optionsEstadoUsuario => optionsEstadoUsuario.value === this.selectedEstadoUsuario)
    }
  },
  methods: {
    gravar_rastreio(descricao){
      var login = this.user.login
      var procedimento = 'lista usuario'
      this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
    },
    pesquisar(){
      var estado_usuario = this.selectedEstadoUsuario
      var descricao = 'Estado Usuario: ' + this.currentValue.text
      this.gravar_rastreio(descricao)
      this.$store.dispatch('PESQUISA_LISTA_USUARIOS', {estado_usuario}).then((resolve) =>{
        if (resolve.pesquisaEnd == false) {
          this.$swal.fire({
            title: 'Pesquisa Lista Usuario',
            text: resolve.mensagem,
            icon: 'warning'
          })
        }
      })
    },
    salvar_csv(){
      try {
        if(this.pesquisa_lista_usuarios.length > 0){
          let csvContent = "data:text/csv;charset=utf-8,";
          csvContent += [
            Object.keys(this.pesquisa_lista_usuarios[0]).join(";"),
            ...this.pesquisa_lista_usuarios.map(item => Object.values(item).join(";"))
          ]
            .join("\n")
            .replace(/(^\[)|(\]$)/gm, "");

          const data = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", data);
          link.setAttribute("download", "lista_usuario.csv");
          link.click();
          this.gravar_rastreio('Salvou a pesquisa realizado com usuario: ' + this.currentValue.text)  
        }
        else{
          this.$swal.fire({
            title: 'Lista Usuario',
            text: 'Sem dados para salvar',
            icon: 'warning'
          })
        }
      } catch (error) {
        this.$swal.fire({
          title: 'Lista Usuario',
          text: 'Error ao Salvar csv.',
          icon: 'error'
        })
      }
    }
  }
}
</script>

<template>
  <b-container class="container">
    <b-row class="filtros-row">
      <b-col cols="9" md="10">
        <div class="textoFiltros">Filtros Lista de Usuarios</div>
      </b-col>
      <b-col>
        <b-button class="float-right" @click="salvar_csv" title="Click para salvar em csv." :disabled="pesquisa_lista_usuarios.length == 0">
          <icon name="file-csv"/>
        </b-button>
      </b-col>
      <b-col>
        <b-button v-b-toggle.collapse-2 class="collapse-button float-right" :class="showCollapse ? 'collapsed' : null"
          :aria-expanded="showCollapse ? 'true' : 'false'" aria-controls="collapse-4" @click="showCollapse = !showCollapse">
          <icon :name="nomeIcone" />
        </b-button>
      </b-col>
    </b-row>
    <b-collapse id="collapse-2" visible>
      <b-row>
        <b-col cols="12">
          <b-form-text class="textoLabels float-left">Usuario:</b-form-text>
          <b-form-select v-model="selectedEstadoUsuario" :options="optionsEstadoUsuario"></b-form-select>
        </b-col>
        <b-col>
          <b-button class="float-right btn_filtro" v-b-toggle="'collapse-2'" @click="pesquisar" >Pesquisar</b-button>
        </b-col>
      </b-row>
    </b-collapse>
  </b-container>
</template>

<style>
  .btn_filtro {
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .container {
    background-color: white;
    border-width: .2 rem;
  }

  .collapse-button {
    margin-top: 2px;
    margin-bottom: 2px;
    right:0;
  }

  .filtros-row {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .textoFiltros {
    font-weight: bold;
    color: #2c3e50;
    left: 0;
    text-align: left;
    padding-top: 1.5vh;
  }

  @media screen and (max-width: 500px){
    .textoLabels {
      color: #2c3e50;
      left: 0;
      text-align: left;
      padding-top: 1.5vh;
    }
  }

  @media screen and (min-width: 501px){
    .textoLabels {
      color: #2c3e50;
      left: 0;
      text-align: left;
      padding-top: 0.75vh;
    }
  }
  
  .form-pesquisa-filtros {
    padding-bottom: 2vh;
  }
</style>
