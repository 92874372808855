<script>
import FiltrosListaUsuario from '@/components/Usuario/FiltrosListaUsuario'
import EditarListaUsuario from '@/components/Usuario/EditarListaUsuario'
import { mapState } from "vuex"

export default {
  name: 'VListaUsuario',
  components: {
    FiltrosListaUsuario,
    EditarListaUsuario
  },
  computed: {
    ...mapState({
      user: state => state.moduleUsuario.user,
    }),
  },
  created(){
    var login = this.user.login
    var procedimento = 'lista usuario'
    var descricao = 'acessou lista usuario'
    this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
  }
}
</script>

<template>
  <div style="background-color:#505668;">
    <FiltrosListaUsuario/>
    <EditarListaUsuario/>  
  </div>
</template>
