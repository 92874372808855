<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import VueMask from 'v-mask'
Vue.use(VueMask);

export default {
  name: 'EditarCadastroUsuario',
  data() {
    return {
      validacaoUsuario: null,
      labelBotaoGravar: '---',
      tipoBotao: '',
      editarSenha: true,
      editarLogin: true,
      checkedMenu: [],
      checkedMenuName: [],
      tamanhoColuna: 0,
      checkSenha: false
    }
  },
  computed: mapState({
    user: state => state.moduleUsuario.user,
    menu: state => state.moduleMenu.menu,
    selected_usuario_menu: state => state.moduleUsuario.menu,
    selected_usuario_list: state => state.moduleUsuario.selected_usuario_list,
    campo_usuario: state => state.moduleUsuario.campo_usuario,
    campo_usuario_modo: state => state.moduleUsuario.campo_usuario_modo,
    usuario_zero: state => state.moduleUsuario.usuario_zero

  }),
  methods: {
    menu_name(){
      this.checkedMenuName = []
      for(var aux in this.menu){
        var idMenu = this.menu[aux].id
        for(var auxCheck in this.checkedMenu){
          var idCheck = this.checkedMenu[auxCheck]
          if (idMenu == idCheck){
            this.checkedMenuName.push(this.menu[aux].descricao)
          }
        }
      }
    },
    zerar_campos(){
      this.selectedUsuario = null
      this.$store.state.moduleUsuario.selected_usuario_list = { ...this.usuario_zero }
      var estado = true
      this.botaoClick = "original"
      var modo = this.botaoClick
      this.$store.dispatch('CAMPO_USUARIO', {estado, modo}) 
    },
    gravar() {
      var dados_usuario = this.selected_usuario_list
      var login = dados_usuario.login
      if(this.campo_usuario_modo == 'novo'){
        var gravou = 'gravou | login: ' + login + ' | nome completo: ' + dados_usuario.nome + ' | telefone: ' + dados_usuario.telefone + ' | numero_oficio: ' + dados_usuario.numero_oficio 
                + ' | cidade: ' + dados_usuario.cidade + ' | estado: ' + dados_usuario.estado 
        this.gravar_rastreio(gravou)
        this.$store.dispatch('CADASTRO_LOGIN', { dados_usuario }).then((resolve) =>{
          if (resolve.cadastro == false){
            this.$swal.fire({
              title: 'Salvar Usuario',
              text: resolve.mensagem,
              icon: 'error'
            })
          }
          if (resolve.cadastro == true) {
            var janelas = this.checkedMenu
            this.menu_name()
            var monitoramento = login + ' permissao na janela: ' + this.checkedMenuName 
            this.gravar_rastreio(monitoramento)
            this.$store.dispatch('CADASTRO_PERMISSAO_MENUS', { login, janelas }).then(() =>{
              this.zerar_campos()
            })
            this.$swal.fire({
              title: 'Salvar Usuario',
              text: resolve.mensagem,
              icon: 'success'
            })
            this.$store.dispatch('LISTA_USUARIOS')
            this.$store.state.moduleUsuario.campo_usuario_modo = 'original'
          }
        })
        
      }
      if(this.campo_usuario_modo == 'alterar'){
        var alterar = ''
        var descricao = ''
        if (this.checkSenha) {
          descricao = 'alterar | login: ' + login + ' | senha: ***** | nome completo: ' + dados_usuario.nome + ' | telefone: ' + dados_usuario.telefone + ' | numero_oficio: ' + dados_usuario.numero_oficio 
                    + ' | cidade: ' + dados_usuario.cidade + ' | estado: ' + dados_usuario.estado 
          alterar = 'ALTERA_LOGIN_SENHA'
        }
        else{
          descricao = 'alterar | login: ' + login + ' | nome completo: ' + dados_usuario.nome + ' | telefone: ' + dados_usuario.telefone + ' | numero_oficio: ' + dados_usuario.numero_oficio 
                    + ' | cidade: ' + dados_usuario.cidade + ' | estado: ' + dados_usuario.estado 
          alterar = 'ALTERA_LOGIN'
        }
        this.gravar_rastreio(descricao)
        this.$store.dispatch(alterar, { dados_usuario }).then((resolve) =>{
          if (resolve.alteracao == false){
            this.$swal.fire({
              title: 'Alterar Usuario',
              text: resolve.mensagem,
              icon: 'error'
            })
          }
          if (resolve.alteracao == true) {
            var janelas = this.checkedMenu
            this.menu_name()
            var monitoramento = login + ' permissao na janela: ' + this.checkedMenuName 
            this.gravar_rastreio(monitoramento)
            this.$store.dispatch('ALTERA_PERMISSAO_MENUS', { login, janelas }).then((resolve) =>{
              if(resolve.permissao == false){
                this.$swal.fire({
                  title: 'Alterar Permissão',
                  text: resolve.mensagem,
                  icon: 'error'
                })
              }
              if(resolve.permissao == true){
                this.$swal.fire({
                  title: 'Alterar Permissão',
                  text: resolve.mensagem,
                  icon: 'success'
                }) 
                this.$store.dispatch('LISTA_USUARIOS')
                this.$store.state.moduleUsuario.campo_usuario_modo = 'original'
              }
            })
          }
        })
      }
    },
    gravar_rastreio(descricao){
      var login = this.user.login
      var procedimento = 'cadastro usuario'
      this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
    },
    verificandoLogin(){
      if (this.selected_usuario_list.login != '' && this.campo_usuario_modo == 'novo'){
        var login = this.selected_usuario_list.login
        this.$store.dispatch('CHECK_LOGIN', {login}).then((resolve) =>{
          this.validacaoUsuario = resolve.resultado
        })
        
      }
    },
    changeSenha(){
      if (this.checkSenha) {
        this.editarSenha = false  
      } else {
        this.editarSenha = true
      }
    }
  },
  created(){
    if(this.$isMobile()){
      this.tamanhoColuna = 6
    }else
    {
      this.tamanhoColuna = 4
    }
  },
  watch: {
    selected_usuario_list(){
      if (this.selected_usuario_list == undefined){
        this.checkedMenu = []
      }else{
        if (this.selected_usuario_list.permissao == undefined){
          this.checkedMenu = []
        }else{
          this.checkedMenu = this.selected_usuario_list.permissao
        }
      }
    },
    campo_usuario_modo(){
      if(this.campo_usuario_modo == 'original'){
        this.labelBotaoGravar = '---'
        this.estadoBotaoGravar = true
        this.tipoBotao = ''
        this.editarSenha = true
        this.editarLogin = true
        this.checkedMenu = []
        this.validacaoUsuario = null
        this.$store.state.moduleUsuario.selected_usuario_list = { ...this.usuario_zero }
      }
      if(this.campo_usuario_modo == 'novo'){
        this.labelBotaoGravar = 'Cadastrar'
        this.estadoBotaoGravar = false
        this.tipoBotao = 'primary'
        this.editarSenha = false
        this.editarLogin = false
        this.checkedMenu = []
        this.$store.state.moduleUsuario.selected_usuario_list = { ...this.usuario_zero }
      }
      if(this.campo_usuario_modo == 'alterar'){
        this.labelBotaoGravar = 'Alterar'
        this.estadoBotaoGravar = false
        this.tipoBotao = 'warning'
        this.editarSenha = true
        this.editarLogin = true
        this.checkedMenu = this.selected_usuario_list.permissao
        this.validacaoUsuario = true
      }
    },
  }
}
</script>

<template>
  <div>
    <b-container class="container">
      <b-form class="form-login">
        <b-form-group>
          <hr>
          <div v-if="$isMobile()">
            <h5>Cadastro de Usuario</h5>
          </div>
          <div v-else>
            <h3>Cadastro de Usuario</h3>
          </div>

          <b-row>
            <b-col cols="12">
              <b-form-text class="label_input" for="nome_completo">Nome Completo*</b-form-text>
              <b-form-input id="nome_completo" class="input-cadastro" :readonly="campo_usuario" v-model="selected_usuario_list.nome"  required/> 
            </b-col>
            <b-col cols="6" >
              <b-form-text class="label_input" for="login">Login*</b-form-text>
              <b-form-input id="login" class="input-cadastro" :readonly="editarLogin" v-model="selected_usuario_list.login" required @blur="verificandoLogin" :state="validacaoUsuario" aria-describedby="input-cadastro-feedback"/> 
              <b-form-invalid-feedback id="input-cadastro-feedback" class="campo_obrigadorio">Login já cadastrado</b-form-invalid-feedback>
            </b-col>
            <b-col cols="6">
              <b-form-text class="label_input" for="senha">Senha*</b-form-text>
              <b-form-input id="senha" class="input-cadastro" v-show="!selected_usuario_list.senhaProvisorio" :readonly="editarSenha" v-model="selected_usuario_list.password" type="password" required/> 
              <b-form-checkbox class="label_input" size="sm" v-show="campo_usuario_modo == 'alterar'" @change="changeSenha" v-model="checkSenha">Alterar a Senha</b-form-checkbox>
              <b-form-checkbox class="label_input" size="sm" v-model="selected_usuario_list.senhaProvisorio" :disabled="campo_usuario">Senha a provisório</b-form-checkbox>
            </b-col>
            <b-col :cols="tamanhoColuna">
              <b-form-text class="label_input" for="telefone"  >Telefone</b-form-text>
              <b-form-input id="telefone" class="input-cadastro" :readonly="campo_usuario" v-model="selected_usuario_list.telefone" v-mask="'(##)#####-####'"/>     
            </b-col>
            <b-col :cols="tamanhoColuna">
              <b-form-text class="label_input" for="cidade">Cidade</b-form-text>
              <b-form-input id="cidade" class="input-cadastro" :readonly="campo_usuario" v-model="selected_usuario_list.cidade"/>     
            </b-col>
            <b-col :cols="tamanhoColuna">
              <b-form-text class="label_input" for="estado">Estado</b-form-text>
              <b-form-input id="estado" class="input-cadastro" :readonly="campo_usuario" v-model="selected_usuario_list.estado"/>     
            </b-col>
            <b-col :cols="tamanhoColuna">
              <b-form-text class="label_input" for="corporacao">Corporação*</b-form-text>
              <b-form-input id="corporacao" class="input-cadastro" :readonly="campo_usuario" v-model="selected_usuario_list.corporacao"/>     
            </b-col>
            <b-col cols="6">
              <b-form-text class="label_input" for="estado">Estado*</b-form-text>
              <b-form-checkbox class="check-cadastro" v-model="selected_usuario_list.ativo" :disabled="campo_usuario"> Ativo </b-form-checkbox>
            </b-col>
          </b-row>
          <p class="campo_obrigadorio">(*) campos obrigadorio</p>
          <hr>
          <div  v-for="aux in menu" :key="aux.id">
            <div class="caixa_permissao" v-if="aux.id_pai == 0">
              <b-form-checkbox v-model="checkedMenu" :disabled="campo_usuario" :value="aux.id">{{ aux.descricao }}</b-form-checkbox>
              <div v-for="sub_aux in menu" :key="sub_aux.id"> 
                <b-form-checkbox class="sub_caixa_permissao" v-model="checkedMenu" :disabled="campo_usuario" v-if="aux.id == sub_aux.id_pai" :value="sub_aux.id"> {{ sub_aux.descricao }} </b-form-checkbox>
              </div>
            </div>
          </div>
        
        <b-button class="button-gravar" @click="gravar" :disabled="!validacaoUsuario" :variant="tipoBotao">{{labelBotaoGravar}}</b-button>
        </b-form-group>
      </b-form>
    </b-container >
  </div>
</template>

<style scoped>
  .label_input{
    text-align: left;
  }

  .check-cadastro{
    float: left;
    padding-bottom: 10px;
  }

  .campo_obrigadorio{
    font-size: 9px;
    text-align: right;
    color: red;
  }

  .sub_caixa_permissao {
    padding-left: 40px !important;
  }

  .caixa_permissao {
    float: left;
    width: 150px;
    height: auto;
    margin: 2px;
    padding-top: 10px;
    padding-left: 6px;
    border-color: #b2dbbf;
    border-radius: 5px ;
    background-color: #c5c5c5;
    text-align: left;
    font-size: 14px;
  }

  .login-card {
    display: inline-block;
    border-color: lightgrey;
    background-color: ghostwhite;
  }
  @media screen and (max-width: 500px){
    .login-card {
      border: none;
      width: 95vw;
    }
  }
  .input-cadastro {
    padding: 25px 20px;
    margin: 5px 0;
    box-sizing: border-box;
  }
  .button-gravar {
    padding: 15px 20px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>