<script>
import Vue from "vue"
import Funcao from "@/components/Programador/FuncaoProgramador"

export default {
  name: "FerramentaProgramador",
  components: {
  },
  data() {
    return {
      planos: [],
      ordens: [],
      file: undefined,
      arquivoLido: [],
      arquivoJson: [],
      estagiosOpcoes: [
        { value: 0, text: 'Fase 1' },
        { value: 1, text: 'Fase 2' },
        { value: 2, text: 'Fase 3' },
        { value: 3, text: 'Fase 4' },
        { value: 4, text: 'Fase 5' },
        { value: 5, text: 'Fase 6' },
        { value: 6, text: 'Fase 7' },
        { value: 7, text: 'Fase 8' }],
      ordensSelect: [],
      ordensOpcoes: []
    }
  },
  computed: {
  },
  methods: {
    ordemSelect(valor, plano) {
      Vue.set(plano, "fases", this.ordens[valor].ordensSelect)
      for (let index in plano.fases) {
        plano["vd_" + plano.fases[index]] == undefined ? Vue.set(plano, "vd_" + plano.fases[index], 0) : plano["vd_" + plano.fases[index]]
        plano["am_" + plano.fases[index]] == undefined ? Vue.set(plano, "am_" + plano.fases[index], 0) : plano["am_" + plano.fases[index]]
        plano["vmb_" + plano.fases[index]] == undefined ? Vue.set(plano, "vmb_" + plano.fases[index], 0) : plano["vmb_" + plano.fases[index]]
        
      }
      for (let index = 0; index < plano.fases.length; index++) {
        for (let index1 = index + 1; index1 < plano.fases.length; index1++) {
          if (plano.fases[index] == plano.fases[index1]) {
            Vue.set(plano, "repetidos_" + index1, true)
          }
        }
      }
      
      this.tempoCicloTotal()
      this.calculoSincronismo()
    },
    inserirSelect(valor, ordensSelect) {
      ordensSelect.push(valor)
    },
    inserirOrdem() {
      this.ordens.push({
        ordensSelect: [],
      })
      this.ordensOpcoes.push({
        value: this.ordens.length - 1,
        text: "Ordem " + this.ordens.length
      })
    },
    carregarPlano(ev){
      this.file = ev.target.files[0]
      let reader = new FileReader()
      reader.onloadend = (e) => (this.arquivoLido = e.target.result)
      reader.readAsText(this.file)
    },
    salvarPlano(){
      this.$swal
        .fire({
          title: "Salvar Ferramenta Local",
          text: "Digite o nome do arquivo: ",
          input: "text",
          icon: "question",
          inputPlaceholder: "ferramenta",
          confirmButtonText: "Confirmar",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let exportObj = this.planos
            let exportName = result.value == "" ? "ferramenta" : result.value
            var dataStr =
              "data:text/json;charset=utf-8," +
              encodeURIComponent(JSON.stringify(exportObj))
            var downloadAnchorNode = document.createElement("a")
            downloadAnchorNode.setAttribute("href", dataStr)
            downloadAnchorNode.setAttribute("download", exportName + ".json")
            document.body.appendChild(downloadAnchorNode) // required for firefox
            downloadAnchorNode.click()
            downloadAnchorNode.remove()
          } else {
            Funcao.alertaSimples("Salvar Ferramenta Local", "Cancelado!","error")
          }
        })
    },
    inserirFase(index) {
      this.planos[index].fases.push({
        vd: 0,
        am: 0,
        vmb: 0,
        cs: 0,
      })
    },
    inserirPlano() {
      this.planos.push({
        tempoCiclo: 0,
        defasagem: 0,
      })
    },
    tempoCicloTotal() {
      let tempo = 0
      for (var planoIndex in this.planos){
        for (let index1 in this.planos[planoIndex].fases) {
          tempo += this.planos[planoIndex]['vd_' + index1]
          tempo += this.planos[planoIndex]['am_' + index1]
          tempo += this.planos[planoIndex]['vmb_' + index1]
        }
        Vue.set(this.planos[planoIndex], 'tempoCiclo', tempo)
      }
    },
    calculoSincronismo(){
      for (let indexPlano in this.planos){
        let plano = this.planos[indexPlano]
        for (let indexEstagios in plano.fases){
            plano['cs_' + plano.fases[indexEstagios]] = plano.defasagem 
            if (indexEstagios > 0){
              for (let aux = indexEstagios - 1; aux >= 0; aux--) {
                plano['cs_' + plano.fases[indexEstagios]] += plano["vd_" + plano.fases[aux]]
                plano['cs_' + plano.fases[indexEstagios]] += plano["am_" + plano.fases[aux]]
                plano['cs_' + plano.fases[indexEstagios]] += plano["vmb_" + plano.fases[aux]]
              }
              if (plano['cs_' + plano.fases[indexEstagios]] > plano.tempoCiclo ){
                plano['cs_' + plano.fases[indexEstagios]] -= plano.tempoCiclo 
              }
            }
        }
      }
    },
    removerFase(fases, faseIndex) {
      Vue.delete(fases, faseIndex)
    },
    removerPlano(plano) {
      this.planos = this.planos.filter(function (item) {
        return item !== plano
      })
    },
  },
  watch: {
    arquivoLido() {
      this.planos = JSON.parse(this.arquivoLido)
    },
  },
}
</script>

<template>
  <div>
    <br v-show="planos.length == 0 && ordens.length == 0"/>
      <b-form-file class="shadow rounded" id="ferramenta-json" accept=".json" placeholder="Carregar um arquivo" v-model="arquivoJson" browse-text="json" @change="carregarPlano" v-show="planos.length == 0 && ordens.length == 0"/>
    <hr/>  
    <div>
      <b-row class="text-left" v-for="(ordem, index) in ordens" :key="index + Math.random()">
        <b-col class="ordem_estilo">
          <b-form-group :label="'Ordem: ' + (index + 1)">
            <ul class="list-unstyled d-inline-flex flex-wrap mb-2" >
              <b-card v-for="(tag, index1) in ordem.ordensSelect" :key="index1" class="mt-1 mr-1 shadow" body-class="py-1 pr-2 text-nowrap">
                {{estagiosOpcoes[tag].text}}
              </b-card>
            </ul>
            <b-row>
              <b-col :cols="$isMobile() ? 8 : 10">
                <b-form-select class="shadow rounded" :options="estagiosOpcoes" @input="inserirSelect($event, ordem.ordensSelect)">
                  <template #first>
                    <option disabled>
                      Escolha a Ordem do estagio...
                    </option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col :cols="$isMobile() ? 2 : 2">
                <b-button class="shadow rounded" variant="danger" @click="removerOrdem(ordem)">
                  Remover
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <br />
      <b-button class="button_ordem shadow rounded" @click="inserirOrdem" variant="success">
        Inserir Ordem
      </b-button>
      <hr />
    </div>
    <div>
      <b-row class="plano_n_geral" v-for="(plano, planoIndex) in planos" :key="planoIndex">
        <b-col cols="12"> 
          <b-row>
            <b-col cols="1">
              <b-form-text>Plano: {{ planoIndex }}</b-form-text>
            </b-col>
            <b-col cols="2">
              <b-form-select size="sm" class="shadow rounded" :options="ordensOpcoes" v-model="plano.ordensSelect" @input="ordemSelect($event, plano)"/>
            </b-col>
            <b-col>
              <div class="input-group">
              <b-form-input  required="" type="text" class="input" v-model="plano.descricao" size="sm"/>
                <label class="user-label">Descrição do Plano</label>
              </div>
            </b-col>
          </b-row>
        </b-col>
          
      
        <b-col class="plano_n_individual" v-for="(fase, faseIndex) in plano.fases" :key="faseIndex">
          <b-row>
            <b-col>
              <label>Fase {{ fase + 1 }}</label>
              <b-button class="float-right shadow rounded" variant="outline-danger" size="sm" @click="removerFase(plano.fases, faseIndex)">X</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-text>Verde</b-form-text>
              <b-form-input class="input-cadastro shadow rounded" v-model.number="plano['vd_' + fase]" type="number" required
                @change="tempoCicloTotal(), calculoSincronismo()" min="0" oninput="this.value = Math.abs(this.value)"/>
            </b-col>
            <b-col cols="12">
              <b-form-text>Amarelo</b-form-text>
              <b-form-input class="input-cadastro shadow rounded" v-model.number="plano['am_' + fase]" type="number" required
                @change="tempoCicloTotal(), calculoSincronismo()" min="0" oninput="this.value = Math.abs(this.value)"/>
            </b-col>
            <b-col cols="12">
              <b-form-text>Vermelho Bloqueio</b-form-text>
              <b-form-input class="input-cadastro shadow rounded" v-model.number="plano['vmb_' + fase]" type="number" required
                @change="tempoCicloTotal(), calculoSincronismo()" min="0" oninput="this.value = Math.abs(this.value)"/>
            </b-col> 
            <b-col cols="12">
              <b-form-text>Calculo Sincronismo</b-form-text>
              <b-form-input readonly class="input-cadastro shadow rounded" min="0" v-model.number="plano['cs_' + fase]" type="number"/>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="plano_n_individual">
          <b-row>
            <b-col>
              <b-form-text>Tempo de Ciclo</b-form-text>
              <label> {{ plano.tempoCiclo }}</label>
            </b-col>
            <b-col>
              <b-form-text>Defasagem onda verde</b-form-text>
              <b-form-input 
                class="input-cadastro shadow rounded" v-model.number="plano.defasagem" type="number" 
                @change="calculoSincronismo()" required/>
            </b-col>
          </b-row>
          <b-row>
            <b-col >
              <b-button class="btn-personalisado noselect" variant="success" @click="inserirFase(planoIndex)">
                <span class="text">Inserir</span>
                <span class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 448 512">
                    <path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"/>
                  </svg>
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-button class="button_plano shadow rounded" @click="removerPlano(plano)" variant="outline-danger">
          Remover Plano
        </b-button>
      </b-row>
      <b-button class="button_plano shadow rounded" @click="inserirPlano" variant="success">
        Novo Plano
      </b-button>
      <hr />
      <b-button class="button_plano shadow rounded" @click="salvarPlano" variant="primary" v-show="planos.length > 0">
        Salvar Plano
      </b-button>
    </div>
  </div>
</template>

<style scoped>
.button_plano {
  padding: 15px 20px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
 font-weight: bold;
}

.plano_n_geral {
  padding: 15px;
}

/* Efeito do Botao */
.btn-personalisado {
  width: 150px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
}

.btn-personalisado, .btn-personalisado span {
  transition: 200ms;
}

.btn-personalisado .text {
  transform: translateX(25px);
  color: white;
  font-weight: bold;
}

.btn-personalisado .icon {
  position: absolute;
  border-left: 1px solid #222121;
  transform: translateX(100px);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-personalisado svg {
  width: 15px;
  fill: #eee;
}

.btn-personalisado:hover .text {
  color: transparent;
}

.btn-personalisado:hover .icon {
  width: 120px;
  border-left: none;
  transform: translateX(0);
}

.btn-personalisado:focus {
  outline: none;
}

.btn-personalisado:active .icon svg {
  transform: scale(0.5);
}

/* Efeito do textBox */
.input-group {
  position: relative;
}

.input {
  border: solid 1.5px #3f3f3f;
  background: none;
  padding: 0.5rem;
  font-size: 1rem;
  color: #3f3f3f;
  transition: border 150ms cubic-bezier(0.4,0,0.2,1);
}

.user-label {
 position: absolute;
 left: 12px;
 color: #aaa8a8;
 pointer-events: none;
 transform: translateY(0.5rem);
 transition: 150ms cubic-bezier(0.4,0,0.2,1);
}

.input:focus, .input:valid {
 outline: none;
 border: 1.5px solid #1a73e8;
}

.input:focus ~ label, .input:valid ~ label {
 transform: translateY(-50%) scale(0.8);
 background-color: #f8f8f8;
 padding: 0 .2em;
 color: #2196f3;
}
</style>
