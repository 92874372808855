import api_usuario from '@/api/api_usuario'
import Vue from 'vue'
import { asyncLoading } from 'vuejs-loading-plugin'
import VueLoading from 'vuejs-loading-plugin'


Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Carregando', // default 'Loading'
  loading: false, // default false
  background: 'rgba(10,23,55,0.7)', // set custom background
})

export default {
  LOGIN({ commit }, { login, password, localIp, isMobile, osName, osVersion, fabricante, modelo, latitude, longitude }) {
    let loading = new Promise((resolve, reject) => {
      try {
        let user = api_usuario.login(login, password, localIp, isMobile, osName, osVersion, fabricante, modelo, latitude, longitude)
        commit('LOGIN', user)
        resolve(user)
      } catch (error) {
        reject(error)
      }
    })
    asyncLoading(Promise.resolve(loading)).then().catch()
    return loading
  },
  LOGIN_SENHA({ commit }, { login, password, localIp, isMobile, osName, osVersion, fabricante, modelo, latitude, longitude }) {
    let loading = new Promise((resolve, reject) => {
      try {
        let user = api_usuario.login_senha(login, password, localIp, isMobile, osName, osVersion, fabricante, modelo, latitude, longitude)
        commit('LOGIN', user)
        resolve(user)
      } catch (error) {
        reject(error)
      }
    })
    asyncLoading(Promise.resolve(loading)).then().catch()
    return loading
  },
  CADASTRO_LOGIN({ commit }, { dados_usuario }) {
    let loading = new Promise((resolve, reject) => {
      try {
        let cadastro = api_usuario.cadastro_login(dados_usuario)
        commit('CADASTRO_LOGIN', cadastro)
        resolve(cadastro)
      } catch (error) {
        alert(`retorno:${error}`)
        reject(error)
      }
    })
    asyncLoading(Promise.resolve(loading)).then().catch()
    return loading
  },
  ALTERA_LOGIN({ commit }, { dados_usuario }) {
    let loading = new Promise((resolve, reject) => {
      try {
        let altera = api_usuario.altera_login(dados_usuario)
        commit('ALTERA_LOGIN', altera)
        resolve(altera)
      } catch (error) {
        alert(`retorno:${error}`)
        reject(error)
      }
    })
    asyncLoading(Promise.resolve(loading)).then().catch()
    return loading
  },
  ALTERA_LOGIN_SENHA({ commit }, { dados_usuario }) {
    let loading = new Promise((resolve, reject) => {
      try {
        let altera = api_usuario.altera_login_senha(dados_usuario)
        commit('ALTERA_LOGIN', altera)
        resolve(altera)
      } catch (error) {
        alert(`retorno:${error}`)
        reject(error)
      }
    })
    asyncLoading(Promise.resolve(loading)).then().catch()
    return loading
  },
  CHECK_LOGIN({ commit }, { login }) {
    let loading = new Promise((resolve, reject) => {
      try {
        let check = api_usuario.check_login(login)
        commit('CHECK_LOGIN', check)
        resolve(check)
      } catch (error) {
        alert(`retorno:${error}`)
        reject(error)
      }
    })
    asyncLoading(Promise.resolve(loading)).then().catch()
    return loading
  },
  LISTA_USUARIOS({ commit }) {
    let loading = new Promise((resolve, reject) => {
      try {
        let lista_usuarios = api_usuario.lista_usuarios()
        commit('LISTA_USUARIOS', lista_usuarios)
        resolve(lista_usuarios)
      } catch (error) {
        reject(error)
      }
    })
    asyncLoading(Promise.resolve(loading)).then().catch()
    return loading
  },
  PESQUISA_LISTA_USUARIOS({ commit }, { estado_usuario }) {
    let loading = new Promise((resolve, reject) => {
      try {
        let pesquisa_lista_usuarios = api_usuario.pesquisa_lista_usuarios(estado_usuario)
        commit('PESQUISA_LISTA_USUARIOS', pesquisa_lista_usuarios)
        resolve(pesquisa_lista_usuarios)
      } catch (error) {
        reject(error)
      }
    })
    asyncLoading(Promise.resolve(loading)).then().catch()
    return loading
  },
  SELECTED_USUARIO_LIST({ commit }, { usuario }) {
    let loading = new Promise((resolve, reject) => {
      try {
        commit('SELECTED_USUARIO_LIST', usuario)
        resolve(usuario)
      } catch (error) {
        reject(error)
      }
    })
    asyncLoading(Promise.resolve(loading)).then().catch()
    return loading
  },
  CAMPO_USUARIO({ commit }, { estado, modo }) {
    let loading = new Promise((resolve, reject) => {
      try {
        var valores = { 'estado': estado, 'modo': modo }
        commit('CAMPO_USUARIO', valores)
        resolve(estado)
      } catch (error) {
        reject(error)
      }
    })
    asyncLoading(loading).then().catch()
    return loading
  },
}