
<script>
import { mapState } from "vuex"
import Vue from "vue"
import Funcao from "@/components/Programador/FuncaoProgramador"

export default {
  name: "ConflitoProgramador",
  props: { 
    programador: Object
  },
  computed: {
    ...mapState({
      programador_editar: (state) => state.moduleProgramador.programador_editar,
      programador_modo: (state) => state.moduleProgramador.programador_modo,
    }),
    conflitos(){
      return this.programador.conflitosJson
    }
  },
  methods: {
    changeConflito(aux, aux1, valor) {
      Vue.set(this.programador.conflitos, aux + "_" + aux1, valor) 
      Vue.set(this.programador.conflitos, aux1 + "_" + aux, valor)
    },
    isDisabledConflito(aux, aux1) {
      if (aux == aux1) return true
      else return false
    },
  },
  watch: {
    conflitos(val){
      var carregarConflitos = []

      if(this.programador.linhaFerrea){
        for (let key in val) {
          let valor = val[key]
          let binario = valor.toString(2)
          binario = binario.padStart(8, "0")
          let rBinario = Funcao.reverseString(binario)
          let aBinario = rBinario.split("")
          let aux = key.replace("cf", "")
          if(aux == 't'){
            aux = 'ferrea'
            for (let aux1 = 0; aux1 < aBinario.length; aux1++) {
              if (aBinario[aux1] == 1) {
                carregarConflitos[aux + "_" + (aux1)] = true
              } else {
                carregarConflitos[aux + "_" + (aux1)] = false
              }
            }
          }else{
            for (let aux1 = 0; aux1 < aBinario.length; aux1++) {
              if (aBinario[aux1] == 1) {
                carregarConflitos[aux + "_" + (aux1 + 1)] = true
              } else {
                carregarConflitos[aux + "_" + (aux1 + 1)] = false
              }
            }
          }
        }
      }else{
        for (let key in val) {
          let valor = val[key]
          let binario = valor.toString(2)
          binario = binario.padStart(8, "0")
          let rBinario = Funcao.reverseString(binario)
          let aBinario = rBinario.split("")
          let aux = key.replace("cf", "")
          if(aux != 't'){
            for (let aux1 = 0; aux1 < aBinario.length; aux1++) {
              if (aBinario[aux1] == 1) {
                carregarConflitos[aux + "_" + (aux1 + 1)] = true
              } else {
                carregarConflitos[aux + "_" + (aux1 + 1)] = false
              }
            }
          }
        }
      }
      this.programador.conflitos = { ...carregarConflitos }
    }
  },
}
</script>

<template>
  <div>
    <b-row class="text-left" align-v="center">
      <b-col :cols="$isMobile() ? 8 : 3">
        <b-form-text>Conflitos Entre Fases</b-form-text>
      </b-col>
    </b-row>
    <b-row v-for="(aux, index) in programador.fasesQtde" :key="index" class="text-left shadow rounded" align-v="center">
      <b-col>
        <b-form-text>Fases {{ (programador.inicioFase + index).toString() }}</b-form-text>
        <b-form-checkbox v-for="(aux1, index1) in programador.fasesQtde" :key="index1" @change="changeConflito(aux, aux1, $event)"
          v-model="programador.conflitos[aux + '_' + aux1]" inline switch :disabled="programador_editar || isDisabledConflito(aux, aux1)">
          Fase {{ (programador.inicioFase + index1).toString() }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <hr />
    <b-row class="text-left shadow rounded" align-v="center" v-show="programador.linhaFerrea">
      <b-col>
        <b-form-text>Conflito Linha Férrea</b-form-text>
        <b-form-checkbox v-for="(aux1, index2) in programador.fasesQtde" :key="index2"
          v-model="programador.conflitos['ferrea_' + index2]" inline switch :disabled="programador_editar">
          Fase {{ (programador.inicioFase + index2).toString() }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>

<style>
</style>