import api_menu from '@/api/api_menu'
import Vue from 'vue'
import { asyncLoading } from 'vuejs-loading-plugin'
import VueLoading from 'vuejs-loading-plugin'


Vue.use(VueLoading, {
    dark: true, // default false
    text: 'Carregando', // default 'Loading'
    loading: false, // default false
    background: 'rgba(10,23,55,0.7)', // set custom background
})

export default {
    CADASTRO_PERMISSAO_MENUS({ commit }, { login, janelas }) {
        let loading = new Promise((resolve, reject) => {
            try {
                let cadastro = api_menu.cadastro_permissao_menus(login, janelas)
                commit('CADASTRO_PERMISSAO_MENUS', cadastro)
                resolve(cadastro)
            } catch (error) {
                alert(`retorno:${error}`)
                reject(error)
            }
        })
        asyncLoading(Promise.resolve(loading)).then().catch()
        return loading
    },
    ALTERA_PERMISSAO_MENUS({ commit }, { login, janelas }) {
        let loading = new Promise((resolve, reject) => {
            try {
                let altera = api_menu.altera_permissao_menus(login, janelas)
                commit('ALTERA_PERMISSAO_MENUS', altera)
                resolve(altera)
            } catch (error) {
                alert(`retorno:${error}`)
                reject(error)
            }
        })
        asyncLoading(Promise.resolve(loading)).then().catch()
        return loading
    },
    MENU({ commit }) {
        let loading = new Promise((resolve, reject) => {
            try {
                let menu = api_menu.menu()
                commit('MENU', menu)
                resolve(menu)
            } catch (error) {
                reject(error)
            }
        })
        asyncLoading(Promise.resolve(loading)).then().catch()
        return loading
    },    
}