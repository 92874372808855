import axios from 'axios'
export default {
  envio_tabela_local (ip, tabela) {
    //return axios.post('http://' + ip + ':8080/programar', JSON.stringify(tabela), {
    //  headers: {
    //    //'Access-Control-Allow-Origin': '*',
    //    //'content-type': 'application/json',
    //    //'rejectUnauthorized': false,
    //    'Content-Security-Policy': 'upgrade-insecure-requests'
    //  },
    //  //mode: 'no-cors',
    //}).then(result => result.data)
    return axios.post('http://' + ip + ':8080/programar', JSON.stringify(tabela), {}).then(result => result.data)
  },
  ler_tabela_local (ip) {
    return axios.post('http://' + ip + ':8080/ler_tabela').then(result => result.data)
  },
  amarelo_piscante_local (ip) {
    return axios.post('http://' + ip + ':8080/set_amarelo_piscante').then(result => result.data)
  },
  mudar_rede_beagle (rede) {
    var ip = rede['ip']
    var aux = {
      ip_novo : rede.ip_novo,
      mascara : rede.mascara,
      gateway : rede.gateway
    }
    return axios.post('http://' + ip + ':8080/mudar_ip', JSON.stringify(aux)).then(result => result.data)
  },
  verificar_autenticacao (ip) {
    return axios.post('http://' + ip + ':8080/tem_autenticacao', '', {
      headers: {
        //'Access-Control-Allow-Origin': '*',
        //'Access-Control-Allow-Credentials': 1,
        //'rejectUnauthorized': false,
        //'Content-Type': 'application/json',
      },
    }).then(result => result.data)
  },
  ativar_autenticacao (ip, senha) {
    return axios.post('http://' + ip + ':8080/definir_autenticacao', senha).then(result => result.data)
  },
  desativar_autenticacao (ip, senha) {
    //var config = {
    //    method: 'post',
    //    url: 'http://' + ip + ':8080/remover_autenticacao',
    //    headers: { 
    //        'Authorization': 'Basic ' + senha, 
    //        
    //    },
    //}
    //return axios(config).then(function (response) {
    //    console.log(JSON.stringify(response.data));
    //})
    //.catch(function (error) {
    //    console.log(error);
    //});
    return axios.post('http://' + ip + ':8080/remover_autenticacao', {}, {
      //auth: {
      //    username: 'usuario',
      //    password: senha
      //},
      headers: {
      //    //'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
      //    'Accept-Language': 'pt-BR',
          'Authorization': 'Basic ' + senha,
      //    //'Upgrade-Insecure-Requests': 1,
      //    'Content-Type': 'application/x-www-form-urlencoded',
      //    'Access-Control-Allow-Origin': '*',
      //    'Access-Control-Allow-Credentials': true,
      //    //'Content-Type': 'application/json',
      //    //'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
      //    //'Content-Type': 'application/json;charset=utf-8'
      //
      },
      withCredentials: true,
      crossDomain: true,
      //credentials: 'same-origin',
      //mode: 'no-cors',
    }).then(result => result.data)
 },
  alterar_autenticacao (ip, antiga, nova) {
    return axios.post('http://' + ip + ':8080/definir_autenticacao', nova, {
      headers: {
        'Authorization': 'Basic ' + antiga,
      }
    }).then(result => result.data)
  },
  ajustar_horario_cpu (ip, dataAtual) {
    return axios.post('http://' + ip + ':8080/ajustar_horario', dataAtual, {}).then(result => result.data)
  },
  ajustar_fuso_horario_cpu (ip) {
    return axios.post('http://' + ip + ':8080/ajustar_fuso_default').then(result => result.data)
  },
}
