export default {
    LER_HORA_MAQUINA(state, retorno) {
        if(retorno != 0)
            state.horaBeagle = retorno
    },
    LER_PLANOS_ESTAGIOS(state, retorno) {
        if (retorno != '')
        {
            state.planosEstagios = retorno['Anel']
        }
    },
    LER_VERSAO(state, retorno) {
        state.versaoBeagle = retorno
    },
}