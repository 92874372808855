import Vue from 'vue'
export default new Vue({
  methods: {
    setAdicionarEstagiosOrdem(event) {
      this.$emit('adicionarEstagiosOrdem', event)
    },
    onAdicionarEstagiosOrdem(onCallback) {
      this.$on('adicionarEstagiosOrdem', onCallback)
    },
    setAdicionarEstagiosOrdemFerrea(event) {
      this.$emit('adicionarEstagiosOrdemFerrea', event)
    },
    onAdicionarEstagiosOrdemFerrea(onCallback) {
      this.$on('adicionarEstagiosOrdemFerrea', onCallback)
    },
    setRemoverEstagiosOrdem(event) {
      this.$emit('removerEstagiosOrdem', event)
    },
    onRemoverEstagiosOrdem(onCallback) {
      this.$on('removerEstagiosOrdem', onCallback)
    },
  }
})