
<script>
import { mapState } from "vuex"
import barramento_ordens from "@/components/Barramentos/Ordens"
import barramento_cabecalho from "@/components/Barramentos/Cabecalho"
import barramento_plano from "@/components/Barramentos/Plano"

import Vue from "vue"

export default {
  name: "PlanoProgramador",
  props: { programador: Object },
  data() {
    return {
      
    }
  },
  created() {
    barramento_plano.onLoadPlanos(
      this.calculoSincronismo(),
      this.calculoSincronismoExt(),
    ),
    barramento_ordens.onRemoverEstagiosOrdem(() => {
      try {
        for (let index in this.programador.planos) {
          let plano = this.programador.planos[index];
          let tempo = 0
          let tempoExt = 0
          for (let index in plano.estagios) {
            tempo += plano["vd_" + plano.estagios[index]]
            tempo += plano["am_" + plano.estagios[index]]
            tempo += plano["vmb_" + plano.estagios[index]]
            tempo += plano["pa_" + plano.estagios[index]]
            tempoExt += plano["vd_" + plano.estagios[index] + '_ext']
            tempoExt += plano["am_" + plano.estagios[index] + '_ext']
            tempoExt += plano["vmb_" + plano.estagios[index] + '_ext']
            tempoExt += plano["pa_" + plano.estagios[index] + '_ext']
          }
          plano.tempoCiclo = tempo
          plano.tempoCicloExt = tempoExt
          plano.alertaVisivel = plano.tempoCicloExt == plano.tempoCiclo ? false : true
        }  
      } catch(error){
        console.log(error)
      }
      
    }),
    barramento_ordens.onAdicionarEstagiosOrdem((onCallback) => {
      let ordemAlterado = onCallback
      for (let index = 0; index < this.programador.planos.length; index++) {
        let plano = this.programador.planos[index]
        let ordem = this.programador.planos[index].ordensSelect
        if (ordem == ordemAlterado) 
          this.ordemSelect(ordem, plano)
      }
    }),
    barramento_ordens.onAdicionarEstagiosOrdemFerrea((onCallback) => {
      let ordemAlterado = onCallback
      let plano = this.programador.planoFerrea[ordemAlterado]
      this.ordemSelectFerrea(ordemAlterado, plano)
    }),
    barramento_cabecalho.onTipoFaseChange((onCallbackFase,onCallbackTipo) => {
      let numFase = onCallbackFase + 1
      let aEstagio = []
      for (let fase in this.programador.estagios){
        let estagio = this.programador.estagios[fase]
        for (let faseSelect in estagio.faseSelect){
          let ativo = estagio.faseSelect[faseSelect]
          if(ativo){
            if(faseSelect == numFase){
              aEstagio.push(parseInt(fase) + 1)
            }
          }
        }
      }
      for (let iPlano in this.programador.planos){
        let plano = this.programador.planos[iPlano]
        for (let iEstagio in aEstagio){
          let estagio = aEstagio[iEstagio]
          if(onCallbackTipo == 'p'){
            Vue.set(plano,'pa_' + estagio + '_ativo', false)
            Vue.set(plano,'pa_' + estagio + '_ativo_ext', false)
          }else{
            Vue.set(plano,'pa_' + estagio + '_ativo', true)
            Vue.set(plano,'pa_' + estagio + '_ativo_ext', true)
            Vue.set(plano,'pa_' + estagio, 0)
            Vue.set(plano,'pa_' + estagio + '_ext', 0)
          }
        }
      }
    })
  },
  computed: {
    ...mapState({
      programador_editar: (state) => state.moduleProgramador.programador_editar,
      programador_modo: (state) => state.moduleProgramador.programador_modo,
    }),
    ordens() {
      return this.programador.ordens
    },
    linhaFerrea1() {
      return this.programador.linhaFerrea;
    },
    //planos(){
    //  return this.programador.planosJson
    //}
  },
  methods: {
    copiarPlano() {
      var listaPlanos = {}
      for (var plano in this.programador.planos){
        if(plano != 0){
          listaPlanos[plano] = "Plano " + plano
        }
      }
      this.$swal.fire({
          title: "Selecione o Plano que deseja copiar:",
          input: "select",
          inputOptions: listaPlanos,
          inputPlaceholder: "Selecione o Plano",
          showCancelButton: true,
        }).then((result) => {
          if(result.isConfirmed){
            var copy = {...this.programador.planos[result.value]};
            this.programador.planos.push(copy)
          }
        })
    },
    inserirPlano() {
      if (this.programador.planos.length == 0) {
        this.programador.planos.push({
          tempoCiclo: 0,
          tempoCicloExt: 0,
          defasagem: 0,
          defasagemExt: 0
        })
      }
      this.programador.planos.push({
        tempoCiclo: 0,
        tempoCicloExt: 0,
        defasagem: 0,
        defasagemExt: 0
      })
    },
    removerPlano(plano) {
      this.programador.planos = this.programador.planos.filter(function (item) {
        return item !== plano
      })
    },
    tempoCicloTotalExt(plano){
      let tempo = 0
      for (let index in plano.estagios) {
        tempo += plano["vd_" + plano.estagios[index] + '_ext']
        tempo += plano["am_" + plano.estagios[index] + '_ext']
        tempo += plano["vmb_" + plano.estagios[index] + '_ext']
      }
      plano.tempoCicloExt = tempo
      plano.alertaVisivel = plano.tempoCicloExt == plano.tempoCiclo ? false : true
    },
    tempoCicloTotal(plano) {
      let tempo = 0
      for (let index in plano.estagios) {
        if (!plano['pa_' + plano.estagios[index] + '_atuador']){
          tempo += plano["vd_" + plano.estagios[index]]
          tempo += plano["am_" + plano.estagios[index]]
          tempo += plano["vmb_" + plano.estagios[index]]
        }
      }
      plano.tempoCiclo = tempo
      plano.alertaVisivel = plano.tempoCicloExt == plano.tempoCiclo ? false : true
    },
    calculoSincronismo(){
      for (let indexPlano in this.programador.planos){
        let plano = this.programador.planos[indexPlano]
        if (indexPlano > 0){
          for (let indexEstagios in plano.estagios){
            //if (!plano['pa_' + plano.estagios[indexEstagios] + '_atuador']){
              plano['cs_' + plano.estagios[indexEstagios]] = plano.defasagem 
              if (indexEstagios > 0){
                for (let aux = indexEstagios - 1; aux >= 0; aux--) {
                  plano['cs_' + plano.estagios[indexEstagios]] += plano["vd_" + plano.estagios[aux]]
                  plano['cs_' + plano.estagios[indexEstagios]] += plano["am_" + plano.estagios[aux]]
                  plano['cs_' + plano.estagios[indexEstagios]] += plano["vmb_" + plano.estagios[aux]]
                }
                if (plano['cs_' + plano.estagios[indexEstagios]] > plano.tempoCiclo ){
                  plano['cs_' + plano.estagios[indexEstagios]] -= plano.tempoCiclo 
                }
              }
            //}
          }
        }
      }
    },
    calculoSincronismoFerrea(){
      for (let indexPlano in this.programador.planoFerrea){
        let plano = this.programador.planoFerrea[indexPlano]
        for (let indexEstagios in plano.estagios){
          plano['cs_' + plano.estagios[indexEstagios]] = plano.defasagem 
          if (indexEstagios > 0){
            for (let aux = indexEstagios - 1; aux >= 0; aux--) {
              plano['cs_' + plano.estagios[indexEstagios]] += plano["vd_" + plano.estagios[aux]]
              plano['cs_' + plano.estagios[indexEstagios]] += plano["am_" + plano.estagios[aux]]
              plano['cs_' + plano.estagios[indexEstagios]] += plano["vmb_" + plano.estagios[aux]]
            }
            if (plano['cs_' + plano.estagios[indexEstagios]] > plano.tempoCiclo ){
              plano['cs_' + plano.estagios[indexEstagios]] -= plano.tempoCiclo 
            }
          }
        }
      }
    },
    calculoSincronismoExt(){
      for (let indexPlano in this.programador.planos){
        let plano = this.programador.planos[indexPlano]
        if (indexPlano > 0){
          for (let indexEstagios in plano.estagios){
            //if (!plano['pa_' + plano.estagios[indexEstagios] + '_atuador']){
              plano['cs_' + plano.estagios[indexEstagios] + '_ext'] = plano.defasagemExt
              if (indexEstagios > 0){
                for (let aux = indexEstagios - 1; aux >= 0; aux--) {
                  plano['cs_' + plano.estagios[indexEstagios] + '_ext'] += plano["vd_" + plano.estagios[aux] + '_ext']
                  plano['cs_' + plano.estagios[indexEstagios] + '_ext'] += plano["am_" + plano.estagios[aux] + '_ext']
                  plano['cs_' + plano.estagios[indexEstagios] + '_ext'] += plano["vmb_" + plano.estagios[aux] + '_ext']
                }
                if (plano['cs_' + plano.estagios[indexEstagios] + '_ext'] > plano.tempoCicloExt ){
                  plano['cs_' + plano.estagios[indexEstagios] + '_ext'] -= plano.tempoCicloExt 
                }
              }
            //}
          }
        }
      }
    },
    ordemSelectFerrea(valor, plano){
      Vue.set(plano, "estagios", this.programador.ordemFerrea[valor].ordensSelect)

      for (let index in plano.estagios) {
        plano["vd_" + plano.estagios[index]] == undefined ? Vue.set(plano, "vd_" + plano.estagios[index], 0) : plano["vd_" + plano.estagios[index]]
        plano["am_" + plano.estagios[index]] == undefined ? Vue.set(plano, "am_" + plano.estagios[index], 0) : plano["am_" + plano.estagios[index]]
        plano["vmb_" + plano.estagios[index]] == undefined ? Vue.set(plano, "vmb_" + plano.estagios[index], 0) : plano["vmb_" + plano.estagios[index]]
        plano["cs_" + plano.estagios[index]] == undefined ? Vue.set(plano, "cs_" + plano.estagios[index], 0) : plano["cs_" + plano.estagios[index]]
        plano["tempoCiclo"] == undefined ? Vue.set(plano, "tempoCiclo", 0) : plano["tempoCiclo"]
        
      }
      for (let index = 0; index < plano.estagios.length; index++) {
        for (let index1 = index + 1; index1 < plano.estagios.length; index1++) {
          if (plano.estagios[index] == plano.estagios[index1]) {
            Vue.set(plano, "repetidos_" + index1, true)
          }
        }
      }
      this.tempoCicloTotal(plano)
      this.calculoSincronismoFerrea()
    },
    ordemSelect(valor, plano) {
      Vue.set(plano, "estagios", this.programador.ordens[valor].ordensSelect)
      for (let index in plano.estagios) {
        plano["vd_" + plano.estagios[index]] == undefined ? Vue.set(plano, "vd_" + plano.estagios[index], 0) : plano["vd_" + plano.estagios[index]]
        plano["am_" + plano.estagios[index]] == undefined ? Vue.set(plano, "am_" + plano.estagios[index], 0) : plano["am_" + plano.estagios[index]]
        plano["vmb_" + plano.estagios[index]] == undefined ? Vue.set(plano, "vmb_" + plano.estagios[index], 0) : plano["vmb_" + plano.estagios[index]]
        plano["pa_" + plano.estagios[index]] == undefined ? Vue.set(plano, "pa_" + plano.estagios[index], 0) : plano["pa_" + plano.estagios[index]]
        
        plano["cs_" + plano.estagios[index]] == undefined ? Vue.set(plano, "cs_" + plano.estagios[index], 0) : plano["cs_" + plano.estagios[index]]
        plano["cs_" + plano.estagios[index]  + '_ext'] == undefined ? Vue.set(plano, "cs_" + plano.estagios[index] + '_ext', 0) : plano["cs_" + plano.estagios[index] + '_ext']

        plano["tempoCiclo"] == undefined ? Vue.set(plano, "tempoCiclo", 0) : plano["tempoCiclo"]
        plano["tempoCicloExt"] == undefined ? Vue.set(plano, "tempoCicloExt", 0) : plano["tempoCicloExt"]
        plano["defasagem"] == undefined ? Vue.set(plano, "defasagem", 0) : plano["defasagem"]
        plano["defasagemExt"] == undefined ? Vue.set(plano, "defasagemExt", 0) : plano["defasagemExt"]
          
        plano["vd_" + plano.estagios[index]  + '_ext'] == undefined ? Vue.set(plano, "vd_" + plano.estagios[index] + '_ext', 0) : plano["vd_" + plano.estagios[index] + '_ext']
        plano["am_" + plano.estagios[index]  + '_ext'] == undefined ? Vue.set(plano, "am_" + plano.estagios[index] + '_ext', 0) : plano["am_" + plano.estagios[index] + '_ext']
        plano["vmb_" + plano.estagios[index]  + '_ext'] == undefined ? Vue.set(plano, "vmb_" + plano.estagios[index] + '_ext', 0) : plano["vmb_" + plano.estagios[index] + '_ext']
        plano["pa_" + plano.estagios[index]  + '_ext'] == undefined ? Vue.set(plano, "pa" + plano.estagios[index] + '_ext', 0) : plano["pa" + plano.estagios[index] + '_ext']
        
        Vue.set(plano,'pa_' + plano.estagios[index] + '_ativo', true)
        Vue.set(plano,'pa_' + plano.estagios[index] + '_ativo_ext', true)
        //ativa se for pedestre ou nao piscante adicional
        let posicaoEstagios = plano.estagios[index] - 1
        Vue.set(plano,'pa_' + plano.estagios[index] + '_atuador', this.programador.estagios[posicaoEstagios].atuadorEstado)
        for (let select in this.programador.estagios[posicaoEstagios].faseSelect){
          let ativado = this.programador.estagios[posicaoEstagios].faseSelect[select]
          if (ativado == true){
            let i = parseInt(select) - 1
            let tipoFase = this.programador.tipoFases[i]
            if(tipoFase == 'p'){
              Vue.set(plano,'pa_' + plano.estagios[index] + '_ativo', false)
              Vue.set(plano,'pa_' + plano.estagios[index] + '_ativo_ext', false)
            }
          }
        }
        let status = this.programador.estagios[posicaoEstagios].atuadorSincronizado
        Vue.set(plano, "estendido_ativo", status)
        Vue.set(plano, "vd_" + plano.estagios[index] + '_ativo', status)
        Vue.set(plano, "am_" + plano.estagios[index] + '_ativo', status)
        Vue.set(plano, "vmb_" + plano.estagios[index] + '_ativo', status)
        Vue.set(plano, "am_" + plano.estagios[index] + '_ativo_ext', !status)
        Vue.set(plano, "vmb_" + plano.estagios[index] + '_ativo_ext', !status)
        
      }
      for (let index = 0; index < plano.estagios.length; index++) {
        for (let index1 = index + 1; index1 < plano.estagios.length; index1++) {
          if (plano.estagios[index] == plano.estagios[index1]) {
            Vue.set(plano, "repetidos_" + index1, true)
            Vue.set(plano, "repetidos_" + index1 + '_ext', true)
          }
        }
      }
      this.tempoCicloTotal(plano)
      this.tempoCicloTotalExt(plano)
      this.calculoSincronismo()
      this.calculoSincronismoExt()
    },
    atualizarValores(plano, posicao, index){
      if (plano.estendido_ativo){
        Vue.set(plano, posicao + "_" + index + '_ext', plano[posicao + "_" + index])
        this.tempoCicloTotalExt(plano)
        this.calculoSincronismoExt()
      }
    }
  },
  watch: {
    //planos(val){
    //  console.log('chamou aqui 3')
    //  console.log(val)
    //},
    programador:{
      handler(val){
        const planos = []
        if(this.programador.linhaFerrea){
          for (let key = 0; key < val.planosJson.length - 1; key++) {
          //for (let key in val.planosJson) {
            let valor = val.planosJson[key]
            planos.push({
              tempoCiclo: valor.tempoCiclo,
              tempoCicloExt: valor.tempoCicloE,
              defasagem: valor.defasagem,
              defasagemExt: valor.defasagemE,
              ordensSelect: valor.ordem,
              semSincronismo: valor.semSincronismo,
              estagios: [],
              estendido_ativo: valor.tempoCicloE > 0 ? true :false,
            })
            for (let key1 in valor.tempos) {
              let valor1 = valor.tempos[key1]
              if(this.programador.ordens[valor.ordem] != undefined){
                planos[key].estagios.push(this.programador.ordens[valor.ordem].ordensSelect[key1])
                let posicaoEstagios = this.programador.ordens[valor.ordem].ordensSelect[key1] - 1
                planos[key]['pa_' + this.programador.ordens[valor.ordem].ordensSelect[key1] + '_ativo'] = true
                planos[key]['pa_' + this.programador.ordens[valor.ordem].ordensSelect[key1] + '_ativo_ext'] = true
                for (let select in this.programador.estagios[posicaoEstagios].faseSelect){
                  let ativado = this.programador.estagios[posicaoEstagios].faseSelect[select]
                  if (ativado == true){
                    let i = parseInt(select) - 1
                    let tipoFase = this.programador.tipoFases[i]
                    if(tipoFase == 'p'){
                      planos[key]['pa_' + this.programador.ordens[valor.ordem].ordensSelect[key1] + '_ativo'] = false
                      planos[key]['pa_' + this.programador.ordens[valor.ordem].ordensSelect[key1] + '_ativo_ext'] = false
                    }
                  }
                }
              }
              for (let key2 in valor1) {
                let valor2 = valor1[key2]
                if (key2 != "id") {
                  if (key2[key2.length -1] == 'e') {
                    planos[key][key2.slice(0, -1) + "_" + this.programador.ordens[valor.ordem].ordensSelect[key1] +  "_ext"] = valor2
                  }else {
                    planos[key][key2 + "_" + this.programador.ordens[valor.ordem].ordensSelect[key1]] = valor2
                  }
                }
              }
            }
            for (let index = 0; index < planos[key].estagios.length; index++) {
              for (let index1 = index + 1; index1 < planos[key].estagios.length; index1++) {
                if (planos[key].estagios[index] == planos[key].estagios[index1]) {
                  planos[key] ["repetidos_" + index1] = true
                }
              }
            }
            for (let index = 0; index < this.programador.estagios.length; index++) {
              planos[key]['vd_' + (index + 1)  + '_ativo'] = this.programador.estagios[index].atuadorSincronizado
              planos[key]['am_' + (index + 1)  + '_ativo'] = this.programador.estagios[index].atuadorSincronizado
              planos[key]['vmb_' + (index + 1)  + '_ativo'] = this.programador.estagios[index].atuadorSincronizado
              planos[key]['pa_' + (index + 1)  + '_ativo'] = this.programador.estagios[index].atuadorSincronizado
              planos[key]['am_' + (index + 1)  + '_ativo_ext'] = !this.programador.estagios[index].atuadorSincronizado
              planos[key]['vmb_' + (index + 1)  + '_ativo_ext'] = !this.programador.estagios[index].atuadorSincronizado
            }
          }
          Vue.set(this.programador, "planos", planos)

          let key = val.planosJson.length - 1 
          let valor = val.planosJson[key]
          let planoFerrea = []
          planoFerrea.push({
            tempoCiclo: valor.tempoCiclo,
            estagios: [],
          })
          for (let key1 in valor.tempos) {
            let valor1 = valor.tempos[key1]
            if(this.programador.ordemFerrea[0] != undefined){
              planoFerrea[0].estagios.push(this.programador.ordemFerrea[0].ordensSelect[key1])
            }
            for (let key2 in valor1) {
              let valor2 = valor1[key2]
              if (key2 != "id") {
                planoFerrea[0][key2 + "_" + this.programador.ordemFerrea[0].ordensSelect[key1]] = valor2
              }
            }
          }
          for (let index = 0; index < planoFerrea[0].estagios.length; index++) {
            for (let index1 = index + 1; index1 < planoFerrea[0].estagios.length; index1++) {
              if (planoFerrea[0].estagios[index] == planoFerrea[0].estagios[index1]) {
                planoFerrea[0] ["repetidos_" + index1] = true
              }
            }
          }

          Vue.set(this.programador, "planoFerrea", planoFerrea)
        }else{
          for (let key in val.planosJson) {
            let valor = val.planosJson[key]
            planos.push({
              tempoCiclo: valor.tempoCiclo,
              tempoCicloExt: valor.tempoCicloE,
              defasagem: valor.defasagem,
              defasagemExt: valor.defasagemE,
              ordensSelect: valor.ordem,
              semSincronismo: valor.semSincronismo,
              estagios: [],
              estendido_ativo: valor.tempoCicloE > 0 ? true :false,
            })
            for (let key1 in valor.tempos) {
              let valor1 = valor.tempos[key1]
              if(this.programador.ordens[valor.ordem] != undefined){
                planos[key].estagios.push(this.programador.ordens[valor.ordem].ordensSelect[key1])
                let posicaoEstagios = this.programador.ordens[valor.ordem].ordensSelect[key1] - 1
                planos[key]['pa_' + this.programador.ordens[valor.ordem].ordensSelect[key1] + '_ativo'] = true
                planos[key]['pa_' + this.programador.ordens[valor.ordem].ordensSelect[key1] + '_ativo_ext'] = true
                for (let select in this.programador.estagios[posicaoEstagios].faseSelect){
                  let ativado = this.programador.estagios[posicaoEstagios].faseSelect[select]
                  if (ativado == true){
                    let i = parseInt(select) - 1
                    let tipoFase = this.programador.tipoFases[i]
                    if(tipoFase == 'p'){
                      planos[key]['pa_' + this.programador.ordens[valor.ordem].ordensSelect[key1] + '_ativo'] = false
                      planos[key]['pa_' + this.programador.ordens[valor.ordem].ordensSelect[key1] + '_ativo_ext'] = false
                    }
                  }
                }
              }
              for (let key2 in valor1) {
                let valor2 = valor1[key2]
                if (key2 != "id") {
                  if (key2[key2.length -1] == 'e') {
                    planos[key][key2.slice(0, -1) + "_" + this.programador.ordens[valor.ordem].ordensSelect[key1] +  "_ext"] = valor2
                  }else {
                    planos[key][key2 + "_" + this.programador.ordens[valor.ordem].ordensSelect[key1]] = valor2
                  }
                }
              }
            }
            for (let index = 0; index < planos[key].estagios.length; index++) {
              for (let index1 = index + 1; index1 < planos[key].estagios.length; index1++) {
                if (planos[key].estagios[index] == planos[key].estagios[index1]) {
                  planos[key] ["repetidos_" + index1] = true
                }
              }
            }
            for (let index = 0; index < this.programador.estagios.length; index++) {
              planos[key]['vd_' + (index + 1)  + '_ativo'] = this.programador.estagios[index].atuadorSincronizado
              planos[key]['am_' + (index + 1)  + '_ativo'] = this.programador.estagios[index].atuadorSincronizado
              planos[key]['vmb_' + (index + 1)  + '_ativo'] = this.programador.estagios[index].atuadorSincronizado
              planos[key]['pa_' + (index + 1)  + '_ativo'] = this.programador.estagios[index].atuadorSincronizado
              planos[key]['am_' + (index + 1)  + '_ativo_ext'] = !this.programador.estagios[index].atuadorSincronizado
              planos[key]['vmb_' + (index + 1)  + '_ativo_ext'] = !this.programador.estagios[index].atuadorSincronizado
            }
          }
          Vue.set(this.programador, "planos", planos)
        }
      },
      immediate: true 
    },
    linhaFerrea1(val) {
      this.programador.planoFerrea = []
      if(val){
        this.programador.planoFerrea.push({
          tempoCiclo: 0,
          defasagem: 0,
          ordensSelect: 0
        })
      }
    }
  },
}
</script>

<template>
  <div>
    <b-row v-for="(plano, planoIndex) in programador.planos" :key="planoIndex">
      <b-col v-show="planoIndex != 0" cols="12">
        <b-row>
          <b-col :cols="$isMobile() ? 3 : 1">
            <b-form-text>Plano: {{ planoIndex }}</b-form-text>
          </b-col>
          <b-col :cols="$isMobile() ? 5 : 5">
            <b-form-select class="shadow rounded" :options="programador.ordensOpcoes" :disabled="programador_editar"
              v-model="plano.ordensSelect" @input="ordemSelect($event, plano)"/>
          </b-col>
          <b-col>
            <b-form-checkbox v-model="plano.semSincronismo" :disabled="programador_editar">
              Sem Sincronismo
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="plano_n_geral">
          <b-col v-for="(estagio, estagioIndex) in plano.estagios" :key="estagioIndex"
            class="plano_n_individual">
            <b-row>
              <b-col>
                <b-form-text>Estagio {{ estagio }}</b-form-text>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-text>Verde</b-form-text>
                <b-form-input :readonly="programador_editar || plano['repetidos_' + estagioIndex] || plano['vd_' + estagio + '_ativo']"
                  class="input-cadastro shadow rounded" v-model.number="plano['vd_' + estagio]" type="number"
                  required @input="tempoCicloTotal(plano), calculoSincronismo()" min="0" oninput="this.value = Math.abs(this.value)"/>
              </b-col>
              <b-col cols="12">
                <b-form-text>Amarelo</b-form-text>
                <b-form-input :readonly="programador_editar || plano['repetidos_' + estagioIndex] || plano['am_' + estagio + '_ativo']"
                  class="input-cadastro shadow rounded" v-model.number="plano['am_' + estagio]" type="number" 
                  required @input="tempoCicloTotal(plano), calculoSincronismo()" min="0" oninput="this.value = Math.abs(this.value)" @update="atualizarValores(plano, 'am',estagio)" />
              </b-col>
              <b-col cols="12">
                <b-form-text>Vermelho Bloqueio</b-form-text>
                <b-form-input :readonly="programador_editar || plano['repetidos_' + estagioIndex] || plano['vmb_' + estagio + '_ativo']"
                  class="input-cadastro shadow rounded" v-model.number="plano['vmb_' + estagio]" type="number"
                  required @input="tempoCicloTotal(plano), calculoSincronismo()" min="0" oninput="this.value = Math.abs(this.value)" @update="atualizarValores(plano, 'vmb',estagio)"/>
              </b-col>
              <b-col cols="12">
                <b-form-text>Piscante Adicional</b-form-text>
                <b-form-input :readonly="programador_editar || plano['repetidos_' + estagioIndex] || plano['pa_' + estagio + '_ativo']"
                  class="input-cadastro shadow rounded" v-model.number="plano['pa_' + estagio]" type="number"
                  required @input="tempoCicloTotal(plano), calculoSincronismo()" min="0" :max="plano['vd_' + estagio]" oninput="this.value = Math.abs(this.value)"/>
              </b-col>
              <b-col cols="12">
                <b-form-text>Calculo Sincronismo</b-form-text>
                <b-form-input readonly
                  class="input-cadastro shadow rounded" min="0" v-model.number="plano['cs_' + estagio]" type="number"/>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="plano_n_individual">
            <b-row>
              <b-col>
                <b-form-text>Tempo de Ciclo</b-form-text>
                <label> {{ programador.planos[planoIndex].tempoCiclo }}</label>
              </b-col>
              <b-col>
                <b-form-text>Defasagem onda verde</b-form-text>
                <b-form-input :readonly="programador_editar" @input="calculoSincronismo()"
                  class="input-cadastro shadow rounded" v-model.number="programador.planos[planoIndex].defasagem" type="number" required/>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button class="button_remover_plano shadow rounded"
                  @click="removerPlano(plano)" :disabled="programador_editar"
                  variant="danger">Remover
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <!-- repetido para plano estendido -->
      <b-col v-show="plano.estendido_ativo && planoIndex != 0" cols="12">
        <b-row class="plano_n_geral">
          <b-col v-for="(estagio, estagioIndex) in plano.estagios" :key="estagioIndex"
            class="plano_n_individual">
            <b-row>
              <b-col>
                <b-form-text>Estagio {{ estagio }} estendido</b-form-text>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-text>Verde</b-form-text>
                <b-form-input :readonly="programador_editar || plano['repetidos_' + estagioIndex + '_ext'] || plano['vd_' + estagio + '_ativo_ext']"
                  class="input-cadastro shadow rounded" v-model.number="plano['vd_' + estagio + '_ext']"
                  type="number" @input="tempoCicloTotalExt(plano), calculoSincronismoExt()" min="0" oninput="this.value = Math.abs(this.value)" required/>
              </b-col>
              <b-col cols="12">
                <b-form-text>Amarelo</b-form-text>
                <b-form-input :readonly="programador_editar || plano['repetidos_' + estagioIndex + '_ext'] || plano['am_' + estagio + '_ativo_ext']"
                  class="input-cadastro shadow rounded" v-model.number="plano['am_' + estagio + '_ext']"
                  type="number" @input="tempoCicloTotalExt(plano), calculoSincronismoExt()" min="0" oninput="this.value = Math.abs(this.value)" required/>
              </b-col>
              <b-col cols="12">
                <b-form-text>Vermelho Bloqueio</b-form-text>
                <b-form-input :readonly="programador_editar || plano['repetidos_' + estagioIndex + '_ext'] || plano['vmb_' + estagio + '_ativo_ext']"
                  class="input-cadastro shadow rounded" v-model.number="plano['vmb_' + estagio + '_ext']"
                  type="number" @input="tempoCicloTotalExt(plano), calculoSincronismoExt()" min="0" oninput="this.value = Math.abs(this.value)" required/>
              </b-col>
              <b-col cols="12">
                <b-form-text>Piscante Adicional</b-form-text>
                <b-form-input
                  :readonly="programador_editar || plano['repetidos_' + estagioIndex + '_ext'] || plano['pa_' + estagio + '_ativo_ext']"
                  class="input-cadastro shadow rounded" v-model.number="plano['pa_' + estagio  + '_ext']"
                  type="number" @input="tempoCicloTotalExt(plano), calculoSincronismoExt()" min="0" oninput="this.value = Math.abs(this.value)" required/>
              </b-col>
              <b-col cols="12">
                <b-form-text>Calculo Sincronismo</b-form-text>
                <b-form-input readonly
                  class="input-cadastro shadow rounded" min="0" v-model.number="plano['cs_' + estagio + '_ext']" type="number"/>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="plano_n_individual">
            <b-row>
              <b-col>
                <b-form-text>Tempo de Ciclo</b-form-text>
                <label> {{ plano.tempoCicloExt }}</label>
              </b-col>
              <b-col>
                <b-form-text>Defasagem onda verde</b-form-text>
                <b-form-input :readonly="programador_editar" @input="calculoSincronismoExt()"
                  class="input-cadastro shadow rounded" v-model.number="plano.defasagemExt" type="number" required/>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label id='alerta' v-show='plano.alertaVisivel'>
                  Tempos estão diferentes
                </label>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
      <!-- plano ferrea -->
    <b-row v-for="(plano, planoIndex) in programador.planoFerrea" :key="planoIndex + 255">
      <b-col cols="12">
        <b-row>
          <b-col :cols="$isMobile() ? 3 : 2">
            <b-form-text>Plano: Férrea</b-form-text>
          </b-col>
        </b-row>
        <b-row class="plano_n_geral">
          <b-col v-for="(estagio, estagioIndex) in plano.estagios" :key="estagioIndex"
            class="plano_n_individual">
            <b-row>
              <b-col>
                <b-form-text>Estagio {{ estagio }}</b-form-text>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-text>Verde</b-form-text>
                <b-form-input :readonly="programador_editar || plano['repetidos_' + estagioIndex]"
                  class="input-cadastro shadow rounded" v-model.number="plano['vd_' + estagio]"  type="number"
                  required @input="tempoCicloTotal(plano), calculoSincronismoFerrea()" min="0" oninput="this.value = Math.abs(this.value)"/>
              </b-col>
              <b-col cols="12">
                <b-form-text>Amarelo</b-form-text>
                <b-form-input :readonly="programador_editar || plano['repetidos_' + estagioIndex]"
                  class="input-cadastro shadow rounded" v-model.number="plano['am_' + estagio]" type="number" 
                  required @input="tempoCicloTotal(plano), calculoSincronismoFerrea()" min="0" oninput="this.value = Math.abs(this.value)" @update="atualizarValores(plano, 'am',estagio)" />
              </b-col>
              <b-col cols="12">
                <b-form-text>Vermelho Bloqueio</b-form-text>
                <b-form-input :readonly="programador_editar || plano['repetidos_' + estagioIndex]"
                  class="input-cadastro shadow rounded" v-model.number="plano['vmb_' + estagio]" type="number"
                  required @input="tempoCicloTotal(plano), calculoSincronismoFerrea()" min="0" oninput="this.value = Math.abs(this.value)" @update="atualizarValores(plano, 'vmb',estagio)"/>
              </b-col>
              <b-col cols="12">
                <b-form-text>Calculo Sincronismo</b-form-text>
                <b-form-input readonly
                  class="input-cadastro shadow rounded" min="0" v-model.number="plano['cs_' + estagio]" type="number"/>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="plano_n_individual">
            <b-row>
              <b-col>
                <b-form-text>Tempo de Ciclo</b-form-text>
                <label> {{ programador.planoFerrea[planoIndex].tempoCiclo }}</label>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br />
    <b-button class="button_plano shadow rounded" @click="inserirPlano" :disabled="programador_editar" :variant="programador_editar == true ? 'outline-success' : 'success'">
      Inserir Plano
    </b-button>
    <b-button class="button_plano shadow rounded" @click="copiarPlano" :disabled="programador_editar" :variant="programador_editar == true ? 'outline-warning' : 'warning'">
      Copiar Plano
    </b-button>
    <hr />
  </div>
</template>

<style>
#alerta{
  color: red !important;
}

.input-cadastro {
  box-sizing: border-box;
  margin: 2px;
}

.plano_n_geral {
  padding: 15px;
}

.plano_n_individual {
  margin: 1px;
  border: 1px solid black;
}

.button_remover_plano {
  padding: 5px 5px 5px 5px;
  margin: 2px;
}

.button_plano {
  padding: 15px 20px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
}
</style>