
<script>
import { mapState } from "vuex"

export default {
  name: "Cancelar",
  computed: {
    ...mapState({
      programador_editar: (state) => state.moduleProgramador.programador_editar,
    }),
  },
  methods:{
    cancelar() {
      let programador = "limpar"
      this.$store.dispatch("LIMPA_PROGRAMADOR", { programador })
      let editar = true
      let modo = "original"
      this.$store.dispatch("PROGRAMADOR_STATUS", { editar, modo })
    },
  }
}
</script>

<template>
  <b-button class="button-gravar shadow rounded" @click="cancelar" variant="danger">
    Cancelar
  </b-button>
</template>


<style>

</style>