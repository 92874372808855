import axios from 'axios'
export default {
    menu() {
        return axios.post(process.env.VUE_APP_URL_BACKEND + 'menu', '', {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            },
            //withCredentials: true
        }).then(result => result.data)
    },
    cadastro_permissao_menus(login, janelas) {
        var cadastro = { 'usuario': login, 'janelas': janelas }
        return axios.post(process.env.VUE_APP_URL_BACKEND + 'cadastro_permissao_menus', JSON.stringify(cadastro), {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            },
            //withCredentials: true
        }).then(result => result.data)
    },
    altera_permissao_menus(login, janelas) {
        var altera = { 'usuario': login, 'janelas': janelas }
        return axios.post(process.env.VUE_APP_URL_BACKEND + 'altera_permissao_menus', altera, {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            },
            //withCredentials: true
        }).then(result => result.data)
    }
}