<script>
import { mapState } from "vuex"
import LerTabelaBeagle from "@/components/Botao/LerTabelaBeagle"
import MudarRedeBeagle from "@/components/Botao/MudarRedeBeagle"
import FerramentaPlano from "@/components/Botao/FerramentaPlano"
import Novo from "@/components/Botao/NovoFiltro"
import Alterar from "@/components/Botao/AlterarFiltro"
import AjustarHorario from "@/components/Botao/AjustarHorario"
import AjustarFusoHorario from "@/components/Botao/AjustarFusoHorario.vue"

export default {
  name: "FiltrosProgramador",
  components: {
    LerTabelaBeagle,
    MudarRedeBeagle,
    FerramentaPlano,
    Novo,
    Alterar,
    AjustarHorario,
    AjustarFusoHorario
  },
  data() {
    return {
      visivelInfoCPU: true,
      arrayTempo: [1,3,5],
      tempo: 3,
      showCollapse: false,
      file: undefined,
      arquivoLido: [],
      arquivoJson: [],
      tempoAtualizacaoHoraBeagle: 2000,
      tempoAtualizacaoPlanosEstagios: 2000,
      ipCPU: '192.168.254.101'
    }
  },
  computed: {
    ...mapState({
      programador_lista: (state) => state.moduleProgramador.programador_lista,
      versaoBeagle: (state) => state.moduleBeagle.versaoBeagle,
      horaBeagle: (state) => state.moduleBeagle.horaBeagle,
      planosEstagios: (state) => state.moduleBeagle.planosEstagios,
      user: state => state.moduleUsuario.user
    }),
    nomeIcone() {
      let editar = true
      let modo = "original"
      this.$store.dispatch("PROGRAMADOR_STATUS", { editar, modo })
      if (this.showCollapse) {
        let programador = "limpar"
        this.$store.dispatch("LIMPA_PROGRAMADOR", { programador })
      }
      return this.showCollapse ? "plus" : "minus"
    },
  },
  methods: {
    loadTextFromFile(ev) {
      this.file = ev.target.files[0]
      let reader = new FileReader()
      reader.onloadend = (e) => (this.arquivoLido = e.target.result)
      reader.readAsText(this.file)
    },
    atualizaHoraBeagle(){
      this.$store.dispatch("LER_HORA_MAQUINA", { ip: this.ipCPU }).then(()=>{
        if (this.visivelInfoCPU) {
          setTimeout(this.atualizaHoraBeagle, this.tempoAtualizacaoHoraBeagle);
        }
      })
    },
    atualizaPlanosEstagios(){
      this.$store.dispatch("LER_PLANOS_ESTAGIOS", { ip: this.ipCPU }).then(()=>{
        if (this.visivelInfoCPU) {
          setTimeout(this.atualizaPlanosEstagios, this.tempoAtualizacaoPlanosEstagios);
        }
      })
    },
    setTempo(){
      let index = this.arrayTempo.indexOf(this.tempo)
      if(index >= 0 && index < this.arrayTempo.length - 1)
        this.tempo = this.arrayTempo[index + 1]
      else
        this.tempo = this.arrayTempo[0]
      this.tempoAtualizacaoPlanosEstagios = this.tempo * 1000
      this.tempoAtualizacaoHoraBeagle = this.tempo * 1000
    },
    setVisivelInfoCPU(){
      this.visivelInfoCPU = !this.visivelInfoCPU
      if(this.visivelInfoCPU){
        this.atualizaPlanosEstagios()
        this.atualizaHoraBeagle()
      }
    },
    setIPCPU(){
      this.$swal.fire({
        title: "IP do Beagle",
        html: `
          <b-form-text>Digite o IP do Beagle:</b-form-text>
          <input type="text" id="ip" class="swal2-input" placeholder="192.168.254.101">`,
        preConfirm: () => {
          this.ipCPU = this.$swal.getPopup().querySelector("#ip").value
        },
        icon: "warning",
        confirmButtonText: "Confirmar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      })
    }
  },
  mounted() {
    this.arquivoJson = []
    this.$store.dispatch("LER_VERSAO", { ip: this.ipCPU})
    this.atualizaHoraBeagle()
    this.atualizaPlanosEstagios()
  },
  watch: {
    ipCPU(){
      this.$store.state.moduleBeagle.versaoBeagle = ''
      this.$store.state.moduleBeagle.horaBeagle = ''
      this.$store.state.moduleBeagle.planosEstagios = ''
      this.$store.dispatch("LER_VERSAO", { ip: this.ipCPU})
      this.atualizaHoraBeagle()
      this.atualizaPlanosEstagios()
    },
    arquivoLido() {
      if(this.arquivoLido.length > 0){
        var programador = JSON.parse(this.arquivoLido)
        this.$store.dispatch("PROGRAMADOR_LISTA", { programador })
      }
    },
    programador_lista(){
      if(this.programador_lista == "limpar"){
        this.file = undefined
        this.arquivoLido = []
        this.arquivoJson = []
      }
    }
  },
}
</script>

<template>
  <div>
    <b-row class="filtros-row">
      <b-col cols="9" md="10">
        <div class="textoFiltros">Filtros do Programador</div>
        <b-row v-show="visivelInfoCPU">
          <b-col cols="12">
            <b-form-text class="cpuTextoFiltros">IP: {{ipCPU}} || Versão da CPU: {{versaoBeagle}} || Horario da CPU: {{horaBeagle}}</b-form-text>
          </b-col>
          <b-col cols="3" v-for="planoEstagio, index in planosEstagios" :key="index" class="cpuTextoFiltros">
            <b-form-text>
              Anel {{index + 1}} || Plano: {{planoEstagio.Plano}} || Estagio:  {{planoEstagio.Estagio}}
            </b-form-text>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-button class="collapse-button float-right shadow rounded" v-b-toggle.collapse-2 :class="showCollapse ? 'collapsed' : null"
          :aria-expanded="showCollapse ? 'true' : 'false'" aria-controls="collapse-2" @click="showCollapse = !showCollapse">
          <icon :name="nomeIcone" />
        </b-button>
        <b-button-group class="float-right shadow rounded" size="sm">
          <b-button title="Tempo de Atualização" @click='setTempo()'>
            <b-icon icon="clock" aria-hidden="true"/>
            {{tempo}}s
            </b-button>
          <b-button title="Mostrar informações da CPU" @click="setVisivelInfoCPU()">{{visivelInfoCPU ? 'Ocultar' : 'Visivel'}}</b-button>
          <b-button title="IP do CPU" @click="setIPCPU()">IP</b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-collapse id="collapse-2" visible>
      <b-row>
        <b-col cols="12">
          <b-form-file class="shadow rounded" id="form-json" accept=".json" placeholder="Escolha um arquivo" v-model="arquivoJson" browse-text="json" @change="loadTextFromFile">
          </b-form-file>
        </b-col>
        <b-col>
          <LerTabelaBeagle @ativarBtnAlterar="file = $event"/>
          <MudarRedeBeagle/>
          <AjustarHorario/>
          <AjustarFusoHorario/>
          <FerramentaPlano/>
          <Alterar :file="file" />
          <Novo/>
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<style>
.btn_filtro {
  margin: 1%;
}


.collapse-button {
  margin-top: 2px;
  margin-bottom: 2px;
  right: 0;
}

.filtros-row {
  padding-top: 3px;
  padding-bottom: 3px;
}

.textoFiltros {
  font-weight: bold;
  color: #2c3e50;
  left: 0;
  text-align: left;
  padding-top: 1.5vh;
}

.cpuTextoFiltros {
  font-weight: bold;
  color: #2c3e50;
  left: 0;
  text-align: left;
}



@media screen and (max-width: 500px) {
  .textoLabels {
    color: #2c3e50;
    left: 0;
    text-align: left;
    padding-top: 1.5vh;
  }
}

@media screen and (min-width: 501px) {
  .textoLabels {
    color: #2c3e50;
    left: 0;
    text-align: left;
    padding-top: 0.75vh;
  }
}

.row {
  margin-top: 2px;
}

.form-pesquisa-filtros {
  padding-bottom: 2vh;
}
</style>
