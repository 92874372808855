
<script>
import { mapState } from "vuex"
import Vue from "vue"
import barramento_cabecalho from "@/components/Barramentos/Cabecalho"

export default {
  name: "CabecalhoProgramador",
  props: { 
    programador: Object,
    posicaoProgramador: Number,
    subprogramadores: Array
  },
  data() {
    return {
      opcaoEstados: [
        { value: 0, text: "Acre" },
        { value: 1, text: "Alagoas" },
        { value: 2, text: "Amapa" },
        { value: 3, text: "Amazonas - Leste" },
        { value: 4, text: "Amazonas - Oeste" },
        { value: 5, text: "Bahia" },
        { value: 6, text: "Ceara" },
        { value: 7, text: "Distrito Federal" },
        { value: 8, text: "Espirito Santo" },
        { value: 9, text: "Goias" },
        { value: 10, text: "Maranhao" },
        { value: 11, text: "Mato Grosso" },
        { value: 12, text: "Mato Grosso do Sul" },
        { value: 13, text: "Minas Gerais" },
        { value: 14, text: "Para - Leste" },
        { value: 15, text: "Para - Oeste" },
        { value: 16, text: "Paraiba" },
        { value: 17, text: "Parana" },
        { value: 18, text: "Pernambuco" },
        { value: 19, text: "Piaui" },
        { value: 20, text: "Rio de Janeiro" },
        { value: 21, text: "Rio Grande do Norte" },
        { value: 22, text: "Rio Grande do Sul" },
        { value: 23, text: "Rondonia" },
        { value: 24, text: "Roraima" },
        { value: 25, text: "Santa Catarina" },
        { value: 26, text: "Sao Paulo" },
        { value: 27, text: "Sergipe" },
        { value: 28, text: "Tocantins" },
      ],
    }
  },
  methods: {
    tipoFaseChange(fase, valor){
      barramento_cabecalho.setTipoFaseChange(fase, valor)
    },
    qtdMaxFases(){
      let qtd = 0
      let valorMaxFases = 8
      let inicioFase = 0
      for (let index = 0; index < this.subprogramadores.length; index++) {
        this.subprogramadores[index].inicioFase = 1 + inicioFase
        inicioFase += this.subprogramadores[index].fasesQtde
        
        if (index != this.posicaoProgramador){
          qtd += this.subprogramadores[index].fasesQtde
        }
      }
      valorMaxFases = 8 - qtd
      
      for (let index = 0; index < this.subprogramadores.length; index++) {
        this.subprogramadores[index].maxFases = valorMaxFases
      }
    }
  },
  computed: {
    ...mapState({
      programador_editar: (state) => state.moduleProgramador.programador_editar,
      programador_modo: (state) => state.moduleProgramador.programador_modo,
      programador_lista: (state) => state.moduleProgramador.programador_lista,
    }),
    estados() {
      return this.programador.estado
    },
    fasesQtde() {
      return this.programador.fasesQtde
    },
    qtdSubProgramador() {
      return this.subprogramadores.length
    },
  },
  created(){
  },
  watch: {
    programador_lista:{
      handler(val){
        try {
          let subcontrolador = this.programador_lista.Subcontrolador[this.posicaoProgramador]
          if (this.posicaoProgramador == 0) {
            Vue.set(this.programador, "cidade", val.Cabecalho.cidade)
            Vue.set(this.programador, "estado", val.Cabecalho.estado)
            Vue.set(this.programador, "timeZone", val.Cabecalho.timeZone)
            Vue.set(this.programador, "cruzamento", val.Cabecalho.nome)
          }
          Vue.set(this.programador, "identificacao", subcontrolador.identificacao)
          Vue.set(this.programador, "fasesQtde", subcontrolador.FasesQtde)
          try{
            Vue.set(this.programador, "linhaFerrea", subcontrolador.cruzamentoLinhaFerrea)
          } catch{
            Vue.set(this.programador, "linhaFerrea", false)
          }
        } catch {
          return 
        }
      },
      immediate: true 
    },
    estados() {
      this.programador.estado == 0
        ? Vue.set(this.programador, "timeZone", "America/Rio_Branco") //ACT
        : this.programador.estado == 1 || this.programador.estado == 27
        ? Vue.set(this.programador, "timeZone", "America/Maceio") //BRST
        : this.programador.estado == 2 || this.programador.estado == 14
        ? Vue.set(this.programador, "timeZone", "America/Belem") //BRT
        : this.programador.estado == 3
        ? Vue.set(this.programador, "timeZone", "America/Manaus") //AMT
        : this.programador.estado == 4
        ? Vue.set(this.programador, "timeZone", "America/Eirunepe") //ACT
        : this.programador.estado == 5
        ? Vue.set(this.programador, "timeZone", "America/Bahia") //BRST
        : this.programador.estado == 6 ||
          this.programador.estado == 10 ||
          this.programador.estado == 16 ||
          this.programador.estado == 19 ||
          this.programador.estado == 21
        ? Vue.set(this.programador, "timeZone", "America/Fortaleza") //BRST
        : this.programador.estado == 7 ||
          this.programador.estado == 8 ||
          this.programador.estado == 9 ||
          this.programador.estado == 13 ||
          this.programador.estado == 17 ||
          this.programador.estado == 20 ||
          this.programador.estado == 22 ||
          this.programador.estado == 25 ||
          this.programador.estado == 26
        ? Vue.set(this.programador, "timeZone", "America/Sao_Paulo") //BRST
        : this.programador.estado == 11
        ? Vue.set(this.programador, "timeZone", "America/Cuiaba") //AMST
        : this.programador.estado == 12
        ? Vue.set(this.programador, "timeZone", "America/Campo_Grande") //AMST
        : this.programador.estado == 15
        ? Vue.set(this.programador, "timeZone", "America/Santarem") //AMT
        : this.programador.estado == 18
        ? Vue.set(this.programador, "timeZone", "America/Recife") //BRST
        : this.programador.estado == 23
        ? Vue.set(this.programador, "timeZone", "America/Porto_Velho") //AMT
        : this.programador.estado == 24
        ? Vue.set(this.programador, "timeZone", "America/Boa_Vista") //AMST
        : this.programador.estado == 28
        ? Vue.set(this.programador, "timeZone", "America/Araguaina") //BRST
        : ""
    },
    qtdSubProgramador(){
      let qtd = 0
      let inicioFase = 1
      let valorMaxFases = 8
      for (let index = 0; index < this.subprogramadores.length; index++) {
        this.subprogramadores[index].inicioFase = inicioFase
        inicioFase += this.subprogramadores[index].fasesQtde
        if (index != this.subprogramadores.length -1){
          qtd += this.subprogramadores[index].fasesQtde
        }
      }
      valorMaxFases = 8 - qtd
      
      for (let index = 0; index < this.subprogramadores.length; index++) {
        this.subprogramadores[index].maxFases = valorMaxFases
      }
    }
  },
}
</script>

<template>
  <div>
    <b-row class="text-left" align-v="center">
      <b-col cols="6" v-show="posicaoProgramador == 0">
        <b-form-text>Cruzamento:</b-form-text>
        <b-form-input class="input-cadastro shadow rounded" :readonly="programador_editar" v-model="programador.cruzamento" required trim/>
      </b-col>
      <b-col cols="6" v-show="posicaoProgramador == 0">
        <b-form-text>Cidade:</b-form-text>
        <b-form-input class="input-cadastro shadow rounded" :readonly="programador_editar" v-model="programador.cidade" required trim/>
      </b-col>
      <b-col cols="6" v-show="posicaoProgramador > 0">
        <b-form-text>Identificação:</b-form-text>
        <b-form-input class="input-cadastro shadow rounded" :readonly="programador_editar" v-model="programador.identificacao" required trim/>
      </b-col>
      <b-col cols="6" v-show="posicaoProgramador == 0">
        <b-form-text>Fuso:</b-form-text>
        <b-form-select class="input-cadastro shadow rounded" :disabled="programador_editar" v-model="programador.estado" :options="opcaoEstados" required/>
      </b-col>
      <b-col cols="6">
        <b-form-text>Quantidade de Fases:</b-form-text>
        <b-form-spinbutton class="input-cadastro shadow rounded" :readonly="programador_editar" v-model="programador.fasesQtde"
          required min="2" :max="programador.maxFases" @change="qtdMaxFases"/>
      </b-col>
      <b-col cols="6">
        <br>
        <b-form-checkbox :disabled="programador_editar" v-model="programador.linhaFerrea" id="">
          Cruzamento com Linha Ferrea
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>

</style>