import axios from 'axios'
export default {
    //pesquisa_rastreio(login, data_inicio, data_fim, procedimento, dispositivo) {
    //    var pesquisa_rastreio = { 'login': login, 'data_inicio': data_inicio, 'data_fim': data_fim, 'procedimento': procedimento, 'dispositivo': dispositivo }
    //    return axios.post(process.env.VUE_APP_URL_BACKEND + 'pesquisa_rastreio', pesquisa_rastreio, {
    //        headers: {
    //            'content-type': 'application/x-www-form-urlencoded',
    //        },
    //        //withCredentials: true
    //    }).then(result => result.data)
    //},
    monitoramento_processos(login, procedimento, descricao) {
        var offset = Intl.DateTimeFormat().resolvedOptions().timeZone
        var monitoramento = { 'login': login, 'procedimento': procedimento, 'descricao': descricao, 'offset': offset }
        return axios.post(process.env.VUE_APP_URL_BACKEND + 'monitoramento_processos', monitoramento, {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            },
            //withCredentials: true
        }).then(result => result.data)
    },
    //lista_procedimentos() {
    //    console.log(process.env)
    //    return axios.post(process.env.VUE_APP_URL_BACKEND + 'lista_procedimentos', '', {
    //        headers: {
    //            'content-type': 'application/x-www-form-urlencoded',
    //        },
    //        //withCredentials: true
    //    }).then(result => result.data)
    //},
}