<script>
import FiltrosCadastroUsuario from '@/components/Usuario/FiltrosCadastroUsuario'
import EditarCadastroUsuario from '@/components/Usuario/EditarCadastroUsuario'
import { mapState } from "vuex"

export default {
  name: 'VCadastroUsuario',
  components:{
    FiltrosCadastroUsuario,
    EditarCadastroUsuario
  },
  computed: {
    ...mapState({
      user: state => state.moduleUsuario.user,
    }),
  },
  created(){
    var login = this.user.login
    var procedimento = 'cadastro usuario'
    var descricao = 'acessou cadastro usuario'
    this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
  }
}
</script>

<template>
  <div style="background-color:#505668;">
      <FiltrosCadastroUsuario/>
      <EditarCadastroUsuario/>
  </div>
</template>

