export default {
    LOGIN(state, result) {
        result.then((val) => (
            state.user.login = val.login,
            state.user.username = val.nome,
            state.user.token = val.token,
            state.user.loggedIn = val.loggedIn,
            state.user.menus = val.menus
        ));
    },
    CADASTRO_LOGIN(state, result) {
        result.then((val) =>{
            state.cadastro_login = {...val }
        })
    },
    ALTERA_LOGIN(state, result) {
        result.then((val) => {
            state.altera_login = {...val }
        })
    },
    LISTA_USUARIOS(state, lista_usuarios) {
        lista_usuarios.then((val) => {
            state.lista_usuarios = { ...val}
        });
    },
    CHECK_LOGIN(state, result) {
        result.then((val) => {
            state.check_login = val
        })
    },
    PESQUISA_LISTA_USUARIOS(state, result) {
        result.then((val) => {
            state.pesquisa_lista_usuarios = val
        });
    },
    SELECTED_USUARIO_LIST(state, result) {
        state.selected_usuario_list = result
    },
    CAMPO_USUARIO(state, valores) {
        state.campo_usuario = valores['estado']
        state.campo_usuario_modo = valores['modo']
    }
}