import Vue from 'vue'
export default new Vue({
  methods: {
    setLoadFileName(name) {
      this.$emit('loadFileName', name)
    },
    onLoadFileName(callback) {
      this.$on('loadFileName', callback)
    }
  }
})