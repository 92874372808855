
<script>
import { mapState } from 'vuex'
import { isMobile, osName, osVersion, mobileVendor, mobileModel } from 'mobile-device-detect'
import { version } from "../../package.json"

export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      validacao: false,
      validacaoLogin: false,
      validacaoSenha: false,
      appVersion: version,
      login: '',
      password: '',
      estilo_password: 'password',
      estilo_legivel: 'eye',
      isMobile: isMobile,
      osName: osName,
      osVersion: osVersion,
      fabricante: mobileVendor,
      modelo: mobileModel,
      latitude: null,
      longitude: null,
      gettingLocation: false,
      errorStr: null,
      localIp: null
    }
  },
  methods: {
    status_visivel: function() {
      if (this.estilo_password == 'password'){
        this.estilo_password = 'text'
        this.estilo_legivel= 'eye-slash'
      }
      else{
        this.estilo_password = 'password'
        this.estilo_legivel= 'eye'
      }
    },
    buscar_menu(){
      this.$store.dispatch('MENU', {}).then( function(resolve){
        const tamanho = Object.keys(resolve).length
        if (tamanho == 0)
          this.$swal.fire({
            text: 'Não foi encontrado nenhum menu',
            icon: 'error'
          })
      })
    },
    entrar() {
      if (!this.validacao){
        this.$swal.fire({ title: 'Login', text: 'Preencha os dados necessario para login!', icon: 'error'})  
        return 
      }
      var login = this.login
      var password = this.password
      var isMobile = this.isMobile
      var osName = this.osName
      var osVersion = this.osVersion
      var fabricante = this.fabricante
      var modelo = this.modelo
      var latitude = this.latitude
      var longitude = this.longitude
      var localIp = this.localIp
      if (isMobile && longitude == -1 && latitude == -1){
        this.$swal.fire({
          title: 'Login',
          text: 'Libere autorização da localização',
          icon: 'warning'
        })
      }
      else{
        this.$store.dispatch('LOGIN', { login, password, localIp, isMobile, osName, osVersion, fabricante, modelo, latitude, longitude }).then((resolve) =>{
          if (resolve.loggedIn == false){
            this.$swal.fire({
              title: 'Login',
              text: resolve.mensagem,
              icon: 'error'
            })
          }
          if (resolve.senhaProvisorio == true){
            this.$swal.fire({
              title: "Digite sua Senha",
              input: "password",
              inputPlaceholder: "Digite sua Senha",
              inputAttributes: {
                maxlength: "10",
                autocapitalize: "off",
                autocorrect: "off"
              }
            }).then((resolve) =>{
              if(resolve.isConfirmed){
                var password = resolve.value
                this.$store.dispatch('LOGIN_SENHA', { login, password, localIp, isMobile, osName, osVersion, fabricante, modelo, latitude, longitude }).then((resolve) =>{
                  if (resolve.loggedIn == false){
                    this.$swal.fire({
                      title: 'Login',
                      text: resolve.mensagem,
                      icon: 'error'
                    })
                  }else{
                    this.buscar_menu()
                    this.$router.push('/programador')
                  }
                })
              }else{
                this.$router.push('/login')
                window.location.reload() 
              }

            })
            
          }else{
            if (resolve.loggedIn == true) {
              this.$swal.fire({
                title: 'Login',
                text: 'Login Realizado',
                icon: 'success'
              })
              this.buscar_menu()
              this.$router.push('/programador')
            }
          }
        })
      }
    },
    validacaoGeral(){
      this.validacao = this.validacaoLogin && this.validacaoSenha
    }
  },
  computed: mapState({
    user: state => state.moduleUsuario.user
  }),
  created(){
    this.user.loggedIn = false
    this.$store.dispatch('IP_CLIENTE').then((resolve) =>{ this.localIp = resolve.ip })
    if(!("geolocation" in navigator)) {
      this.errorStr = 'Geolocation is not available.';
      this.latitude = -1;
      this.longitude = -1;
      return;
    }
    this.gettingLocation = true;
    // get position
    navigator.geolocation.getCurrentPosition(pos => {
      this.gettingLocation = false;
      this.latitude = pos.coords.latitude;
      this.longitude = pos.coords.longitude;
    }, err => {
      this.gettingLocation = false;
      this.errorStr = err.message;
      this.latitude = -1;
      this.longitude = -1;
    })
  },
  watch:{
    login(){
      if (this.login == '') {
        this.validacaoLogin = false
      }
      else{
        this.validacaoLogin = true
      }
      this.validacaoGeral()
    },
    password(){
      if( this.password == ''){
        this.validacaoSenha = false
      }else{
        this.validacaoSenha = true
      }
      this.validacaoGeral()
    }
  }
}
</script>

<template>
  <div v-if="user.loggedIn == false" style="background-color:#505668;"> 
    <b-card class="login-card col-xl-4 col-md-6 col-sm-10 mx-auto my-auto" >
      <b-form class="form-login">
        <b-form-group>
          <div class="titulo">
            <h6>Bem Vindo!</h6>
            <h5>Central Programador</h5>
          </div>
          <b-input-group  class="mb-2">
            <b-input-group-prepend>
              <div class="input-group-text">
                <icon class="icones" name="user" />
              </div>
            </b-input-group-prepend>
            <b-form-input class="input-login" v-model="login" placeholder="Usuário" @keyup.enter="entrar"/> 
          </b-input-group>

          <b-input-group class="mb-2 fab fa-accusoft">
            <b-input-group-prepend>
              <div class="input-group-text">
                <icon class="icones" name="lock" />
              </div>
            </b-input-group-prepend>
            <b-form-input aria-label="Small text input with custom switch" v-model="password" placeholder="Senha" :type="estilo_password" @keyup.enter="entrar" /> 
            <b-input-group-append>
              <b-button @click="status_visivel"><icon :name="estilo_legivel" /></b-button>
            </b-input-group-append>
          </b-input-group>

          <b-button class="button-login" @click="entrar" :disabled='!validacao' :variant="validacao ? 'success' : ''">Entrar</b-button>
          <div v-if="$isMobile()">
            <h6 class="versao">Versão: {{ appVersion }}</h6>
          </div>
          <div v-else>
            <h5 class="versao">Versão: {{ appVersion }}</h5>
          </div>
        </b-form-group>
      </b-form>
    </b-card>
  </div>
  
</template>

<style scoped>
.icones{
  color: rgb(119, 119, 119);
}
.titulo{
  color: rgb(119, 119, 119);
}
.versao{
  text-align: right;
  color: rgb(119, 119, 119);
}

.login-card {
  display: inline-block;
  border-color: lightgrey;
  background-color: ghostwhite;
  left      : 50%;
  top       : 50%;
  position  : absolute;
  transform : translate(-50%, -50%);
}

.button-login {
  padding: 15px 20px;
  box-sizing: border-box;
  width: 100%;
}
</style>