import axios from 'axios'
import api from '@/api/api'
export default {
  login(login, password, localIp, isMobile, osName, osVersion, fabricante, modelo, latitude, longitude) {
    var offset = Intl.DateTimeFormat().resolvedOptions().timeZone
    var user = { 'login': login, 'password': password, 'localIp': localIp, 'isMobile': isMobile, 'osName': osName, 'osVersion': osVersion, 'fabricante': fabricante, 'modelo': modelo, 'latitude': latitude, 'longitude': longitude, 'offset': offset }
    var transferencia = { token: api.aesEncrypt(JSON.stringify(user)) }
    return axios.post(process.env.VUE_APP_URL_BACKEND + 'login', transferencia, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      //withCredentials: true
    }).then(result => result.data)
  },
  login_senha(login, password, localIp, isMobile, osName, osVersion, fabricante, modelo, latitude, longitude) {
    
    var offset = Intl.DateTimeFormat().resolvedOptions().timeZone
    var user = { 'login': login, 'password': password, 'localIp': localIp, 'isMobile': isMobile, 'osName': osName, 'osVersion': osVersion, 'fabricante': fabricante, 'modelo': modelo, 'latitude': latitude, 'longitude': longitude, 'offset': offset }
    var transferencia = { token: api.aesEncrypt(JSON.stringify(user)) }
    return axios.post(process.env.VUE_APP_URL_BACKEND + 'login_senha', transferencia, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      //withCredentials: true
    }).then(result => result.data)
  },
  cadastro_login(dados_usuario) {
    var cadastro = { 'usuario': dados_usuario.login, 'nome': dados_usuario.nome, 'telefone': dados_usuario.telefone, 'cidade': dados_usuario.cidade, 'estado': dados_usuario.estado, 'corporacao': dados_usuario.corporacao, 'senha': dados_usuario.password, 'senha_provisorio': dados_usuario.senhaProvisorio }
    return axios.post(process.env.VUE_APP_URL_BACKEND + 'cadastro_login', JSON.stringify(cadastro), {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    //withCredentials: true
    }).then(result => result.data)
  },
    altera_login(dados_usuario) {
        var altera = { 'login': dados_usuario.login, 'nome': dados_usuario.nome, 'telefone': dados_usuario.telefone, 'cidade': dados_usuario.cidade, 'estado': dados_usuario.estado, 'corporacao': dados_usuario.corporacao, 'ativo': dados_usuario.ativo }
        return axios.post(process.env.VUE_APP_URL_BACKEND + 'altera_login', JSON.stringify(altera), {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            },
            //withCredentials: true
        }).then(result => result.data)
    },
    altera_login_senha(dados_usuario) {
        var altera = { 'login': dados_usuario.login, 'senha': dados_usuario.password, 'nome': dados_usuario.nome, 'telefone': dados_usuario.telefone, 'cidade': dados_usuario.cidade, 'estado': dados_usuario.estado, 'corporacao': dados_usuario.corporacao, 'ativo': dados_usuario.ativo }
        return axios.post(process.env.VUE_APP_URL_BACKEND + 'altera_login_senha', JSON.stringify(altera), {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            },
            //withCredentials: true
        }).then(result => result.data)
    },
    check_login(login) {
      var check = { 'login': login }
      return axios.post(process.env.VUE_APP_URL_BACKEND + 'check_login', check, {
          headers: {
              'content-type': 'application/x-www-form-urlencoded',
          },
          //withCredentials: true
      }).then(result => result.data)
  },
  lista_usuarios() {
      return axios.post(process.env.VUE_APP_URL_BACKEND + 'lista_usuarios', '', {
          headers: {
              'content-type': 'application/x-www-form-urlencoded',
          },
          //withCredentials: true
      }).then(result => result.data)
  },
  pesquisa_lista_usuarios(valor) {
      var pesquisa = { 'ativo': valor }
      return axios.post(process.env.VUE_APP_URL_BACKEND + 'pesquisa_lista_usuarios', pesquisa, {
          headers: {
              'content-type': 'application/x-www-form-urlencoded',
          },
          //withCredentials: true
      }).then(result => result.data)
  },
}