import api_monitorar from '@/api/api_monitorar'
import Vue from 'vue'
import { asyncLoading } from 'vuejs-loading-plugin'
import VueLoading from 'vuejs-loading-plugin'


Vue.use(VueLoading, {
    dark: true, // default false
    text: 'Carregando', // default 'Loading'
    loading: false, // default false
    background: 'rgba(10,23,55,0.7)', // set custom background
})

export default {
    //LISTA_PROCEDIMENTOS({ commit }) {
    //    let loading = new Promise(async(resolve, reject) => {
    //        try {
    //            let lista_procedimentos = await api_monitorar.lista_procedimentos()
    //            commit('LISTA_PROCEDIMENTOS', lista_procedimentos)
    //            resolve(lista_procedimentos)
    //        } catch (error) {
    //            reject(error)
    //        }
    //    })
    //    asyncLoading(loading).then().catch()
    //    return loading
    //},
    //PESQUISA_RASTREIO({ commit }, { login, data_inicio, data_fim, procedimento, dispositivo }) {
    //    let loading = new Promise(async(resolve, reject) => {
    //        try {
    //            let rastreio = await api_monitorar.pesquisa_rastreio(login, data_inicio, data_fim, procedimento, dispositivo)
    //            commit('PESQUISA_RASTREIO', rastreio)
    //            resolve(rastreio)
    //        } catch (error) {
    //            alert(`retorno:${error}`)
    //            reject(error)
    //        }
    //    })
    //    asyncLoading(loading).then().catch()
    //    return loading
    //},
    MONITORAMENTO_PROCESSOS({ commit }, { login, procedimento, descricao }) {
        let loading = new Promise((resolve, reject) => {
            try {
                let monitoramento = api_monitorar.monitoramento_processos(login, procedimento, descricao)
                commit('MONITORAMENTO_PROCESSOS', monitoramento)
                resolve(monitoramento)
            } catch (error) {
                alert(`retorno:${error}`)
                reject(error)
            }
        })
        asyncLoading(Promise.resolve(loading)).then().catch()
        return loading
    },
}