
<script>
import { mapState } from "vuex"
import Vue from "vue"
import barramento_ordens from "@/components/Barramentos/Ordens"
import Funcao from "@/components/Programador/FuncaoProgramador"

export default {
  name: "OrdemProgramador",
  props: { programador: Object },
  data() {
    return {
      linhaFerrea: 'linhaFerrea'
    }
  },
  computed: {
    ...mapState({
      programador_editar: (state) => state.moduleProgramador.programador_editar,
      programador_modo: (state) => state.moduleProgramador.programador_modo,
    }),
    linhaFerrea1() {
      return this.programador.linhaFerrea;
    }
  },
  methods: {
    inserirOrdem() {
      if (this.programador.estagios.length == 0){
        Funcao.alertaSimples("Falta de Estagios","Adicione pelo menos um estagio primeiro!","warning")
      }else {
        this.programador.ordens.push({
          ordensSelect: [],
        })
        
        this.programador.ordensOpcoes.push({
          value: this.programador.ordens.length - 1,
          text: "Ordem " + this.programador.ordens.length
        })
      }
      
    },
    inserirSelect(valor, ordensSelect, ordemIndex) {
      ordensSelect.push(valor)
      barramento_ordens.setAdicionarEstagiosOrdem(ordemIndex)
    },
    inserirSelectFerrea(valor, ordensSelect, ordemIndex) {
      ordensSelect.push(valor)
      barramento_ordens.setAdicionarEstagiosOrdemFerrea(ordemIndex)
    },
    removeTag(ordensSelect, index) {
      Vue.delete(ordensSelect, index)
      barramento_ordens.setRemoverEstagiosOrdem(index)
    },
    removerOrdem(ordem) {
      this.programador.ordens = this.programador.ordens.filter(function (item) {
        return item !== ordem
      })
      this.programador.ordensOpcoes = []
      for (let index = 0; index < this.programador.ordens.length; index++) {
        this.programador.ordensOpcoes.push({
          value: index,
          text: "Ordem " + (index + 1)
        })
      }
    },
    textoOrderFerrea(tag){
      try {
        return this.programador.estagiosFerreaOpcoes[tag - 1].text
      } catch {
        return ''        
      }
    }
  },
  watch: {
    programador:{
      handler(val){
        const ordens = []
        const ordensOpcoes = []
        if(this.programador.linhaFerrea){
          for (let key = 0; key < val.ordensJson.length-1; key++) {
            let valor = val.ordensJson[key].estagios
            ordens.push({ ordensSelect: valor })
            ordensOpcoes.push({
              value: ordens.length - 1,
              text: "Ordem " + ordens.length
            })
          }
          Vue.set(this.programador, "ordens", ordens)
          Vue.set(this.programador, "ordensOpcoes", ordensOpcoes)
          
          const ordemFerrea = []
          let valor = val.ordensJson[val.ordensJson.length -1].estagios;
          ordemFerrea.push({ ordensSelect: valor })
          Vue.set(this.programador, "ordemFerrea", ordemFerrea)
        }else{
          for (let key in val.ordensJson) {
            let valor = val.ordensJson[key].estagios
            ordens.push({ ordensSelect: valor })
            ordensOpcoes.push({
              value: ordens.length - 1,
              text: "Ordem " + ordens.length
            })
          }
          Vue.set(this.programador, "ordens", ordens)
          Vue.set(this.programador, "ordensOpcoes", ordensOpcoes)
        }
        
      },
      immediate: true 
    },
    linhaFerrea1(val) {
      if(val){
        this.programador.ordemFerrea.push({
          ordensSelect: [],
        })
      }else{
        this.programador.ordemFerrea = []
      }
    }
  },
}
</script>

<template>
  <div>
    <b-row class="text-left" v-for="(ordem, index) in programador.ordens" :key="index + Math.random()">
      <b-col class="ordem_estilo">
        <b-form-group :label="'Ordem: ' + (index + 1)">
          <ul class="list-unstyled d-inline-flex flex-wrap mb-2" >
            <b-card v-for="(tag, index1) in ordem.ordensSelect" :key="index1" class="mt-1 mr-1 shadow" body-class="py-1 pr-2 text-nowrap" :disabled="programador_editar">
              {{programador.estagiosOpcoes[tag-1].text}}
              <b-button @click="removeTag(ordem.ordensSelect, index1)" variant="link" :disabled="programador_editar">
                X
              </b-button>
            </b-card>
          </ul>
          <b-row>
            <b-col :cols="$isMobile() ? 8 : 10">
              <b-form-select class="shadow rounded" :disabled="programador_editar || programador.estagiosOpcoes.length === 0"
                :options="programador.estagiosOpcoes" @input="inserirSelect($event, ordem.ordensSelect, index)">
                <template #first>
                  <option disabled>
                    Escolha a Ordem do estagio...
                  </option>
                </template>
              </b-form-select>
            </b-col>
            <b-col :cols="$isMobile() ? 2 : 2">
              <b-button class="shadow rounded" variant="danger" @click="removerOrdem(ordem)" :disabled="programador_editar">
                Remover
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="text-left" v-for="(ordemFerrea, index) in programador.ordemFerrea" :key="index + Math.random()">
      <b-col class="ordem_estilo">
        <b-form-group :label="'Ordem: Férrea'">
          <ul class="list-unstyled d-inline-flex flex-wrap mb-2" >
            <b-card v-for="(tag, index1) in ordemFerrea.ordensSelect" :key="index1" class="mt-1 mr-1 shadow" body-class="py-1 pr-2 text-nowrap" :disabled="programador_editar">
              {{ textoOrderFerrea(tag) }}
              <b-button @click="removeTag(ordemFerrea.ordensSelect, index1)" variant="link" :disabled="programador_editar">
                X
              </b-button>
            </b-card>
          </ul>
          <b-row>
            <b-col :cols="$isMobile() ? 8 : 10">
              <b-form-select class="shadow rounded" :disabled="programador_editar || programador.estagiosFerreaOpcoes.length === 0"
                :options="programador.estagiosFerreaOpcoes" @input="inserirSelectFerrea($event, ordemFerrea.ordensSelect, index)">
                <template #first>
                  <option disabled>
                    Escolha a Ordem do estagio...
                  </option>
                </template>
              </b-form-select>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
    <br />
    <b-button class="button_ordem shadow rounded" @click="inserirOrdem" :disabled="programador_editar" :variant="programador_editar == true ? 'outline-success' : 'success'">
      Inserir Ordem
    </b-button>
    <hr />
  </div>
</template>

<style>
.ordem_estilo {
  margin-right: 15px;
  margin-left: 15px;
  border: 1px solid #6c757d;
  border-radius: 5px;
}

.button_remover_ordem {
  padding: 5px 5px 5px 5px;
  margin: 2px;
}

.button_ordem {
  padding: 15px 20px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
}
</style>