
<script>
import Funcao from "@/components/Programador/FuncaoProgramador"
import { mapState } from "vuex"

export default {
  name: "LerTabelaBeagle",
  computed: {
    ...mapState({
      user: state => state.moduleUsuario.user,
    }),
  },
  methods:{
    lerTabelaBeagle(){
      let localIp = ''
      this.$swal.fire({
      title: "Ler a Tabela do Beagle",
      html: `
        <b-form-text>Digite o IP do Beagle:</b-form-text>
        <input type="text" id="ip" class="swal2-input" placeholder="192.168.254.101">`,
      preConfirm: () => {
        localIp = this.$swal.getPopup().querySelector("#ip").value
      },
      icon: "warning",
      confirmButtonText: "Confirmar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (Funcao.validacaoIP(localIp)) {
            let ip = localIp
            this.$store.dispatch("LER_TABELA_LOCAL", { ip }).then(() => {
                this.monitorar("leu o tabela da cpu " + ip)
                Funcao.alertaSimples("Ler Tabela Beagle", "Leu com Sucesso", "success")
            }, this.$emit('ativarBtnAlterar', new File([""], '')) 
            ).catch(() => {
                Funcao.alertaSimples("Ler Tabela Beagle", "Não foi possivel Ler!", "error")
            })
          } else {
            Funcao.alertaSimples("IP Invalido", "Cancelado!", "error")
          }
        } else {
          Funcao.alertaSimples("Ler a Tabela do Beagle","Cancelado!","error")
        }
      })
    },
    monitorar(valor){
      var login = this.user.login
      var procedimento = 'ler tabela do cpu'
      var descricao = valor
      this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
    }
  }
}
</script>

<template>
  <b-button class="float-left btn_filtro shadow rounded" @click="lerTabelaBeagle" variant="info">
    Ler Tabela 
  </b-button>
</template>


<style>

</style>