
<script>
import Funcao from "@/components/Programador/FuncaoProgramador"
import { mapState } from "vuex"

export default {
  name: "EnviarParaCPU",
  props: { 
    subprogramadores: Array,
    labelBotaoGravar: String,
    tipoBotao: String
  },
  computed: {
    ...mapState({
      user: state => state.moduleUsuario.user,
    }),
  },
  methods:{
    gravarLocal() {
      let localIp = ''
      this.$swal.fire({
        title: "Gravar tabela Local",
        html: `
        <b-form-text>Digite o IP do Beagle:</b-form-text>
        <input type="text" id="ip" class="swal2-input" placeholder="192.168.254.101">`,
        preConfirm: () => {
          localIp = this.$swal.getPopup().querySelector("#ip").value
        },
        icon: "warning",
        confirmButtonText: "Confirmar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          if (Funcao.validacaoIP(localIp)) {
            let tabela = Funcao.gerarJson(this.subprogramadores)
            let ip = localIp
            this.$store.dispatch("ENVIO_TABELA_LOCAL", { ip, tabela }).then(() => {
              this.monitorar(tabela)
              Funcao.alertaSimples("Tabela", "Gravado com Sucesso", "success")
            })
            .catch(() => {
              Funcao.alertaSimples("Tabela", "Não foi possivel gravar!", "error")
            })
          } else {
            Funcao.alertaSimples("IP Invalido", "Cancelado!", "error")
          }
        } else {
          Funcao.alertaSimples("Gravar tabela Local", "Cancelado!", "error")
        }
      })
    },
    monitorar(valor){
      var login = this.user.login
      var procedimento = 'cadastrar enviar para cpu'
      var descricao = JSON.stringify(valor)
      this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
    }
  }
}
</script>

<template>
  <b-button class="button-gravar shadow rounded" @click="gravarLocal" :variant="tipoBotao">
    {{ labelBotaoGravar }}
    <a v-show="labelBotaoGravar != '---'">Enviar Para CPU</a>
  </b-button>
</template>


<style>

</style>