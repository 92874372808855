
<script>
import Funcao from "@/components/Programador/FuncaoProgramador"
import { mapState } from "vuex"

export default {
  name: "MudarRedeBeagle",
  computed: {
    ...mapState({
      user: state => state.moduleUsuario.user,
    }),
  },
  methods:{
    mudarRedeBeagle(){
      let rede = []
      this.$swal.fire({
        title: "Mudar a rede Beagle",
        html: `
          <b-form-text>IP do Beagle Atual</b-form-text>
          <input type="text" id="ip" class="swal2-input" placeholder="192.168.254.101">
          <hr>
          <b-form-text>IP do Beagle Novo</b-form-text>
          <input type="text" id="ip_novo" class="swal2-input" placeholder="192.168.254.102">
          <hr>
          <b-form-text>Mascara do Beagle Novo</b-form-text>
          <input type="text" id="mascara" class="swal2-input" placeholder="255.255.255.0">
          <hr>
          <b-form-text>Gateway do Beagle Novo</b-form-text>
          <input type="text" id="gateway" class="swal2-input" placeholder="192.168.254.1">`,
          preConfirm: () => {
            rede['ip'] = this.$swal.getPopup().querySelector("#ip").value
            rede['ip_novo'] = this.$swal.getPopup().querySelector("#ip_novo").value
            rede['mascara'] = this.$swal.getPopup().querySelector("#mascara").value
            rede['gateway'] = this.$swal.getPopup().querySelector("#gateway").value
            !Funcao.validacaoIP(rede['ip']) ? this.$swal.showValidationMessage("IP incorreto")
            : !Funcao.validacaoIP(rede['ip_novo']) ? this.$swal.showValidationMessage("IP Novo incorreto")
            : !Funcao.validacaoIP(rede['mascara']) ? this.$swal.showValidationMessage("Mascara incorreto")
            : !Funcao.validacaoIP(rede['gateway']) ? this.$swal.showValidationMessage("Gateway incorreto") 
            : ''
          },
        icon: "warning",
        confirmButtonText: "Confirmar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("MUDAR_REDE_BEAGLE", { rede }).then(() => {
            this.monitorar('alteracao ' + rede + ' realizada com sucesso')
            Funcao.alertaSimples("Mudar a rede Beagle", "Alteração realizada com Sucesso", "success")
          }).catch(() => {
            Funcao.alertaSimples("Mudar a rede Beagle","Não foi possivel realizar a alteração!","error")
          })
        } else {
          Funcao.alertaSimples("Mudar a rede Beagle", "Cancelado!", "error")
        }
      })
    },
    monitorar(valor){
      var login = this.user.login
      var procedimento = 'mudar a rede cpu'
      var descricao = valor
      this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
    }
  }
}
</script>

<template>
  <b-button class="float-left btn_filtro shadow rounded" @click="mudarRedeBeagle" variant="info">
    Rede Beagle
  </b-button>        
</template>

<style>

</style>