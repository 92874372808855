<script>
import Vue from "vue"
import { BootstrapVueIcons } from "bootstrap-vue"
import VueMobileDetection from "vue-mobile-detection"
import VueSweetalert2 from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css"
import { mapState } from 'vuex'
import { version } from "../package.json"

Vue.use(VueMobileDetection)
Vue.use(VueSweetalert2)
Vue.use(BootstrapVueIcons)

export default {
  name: "App",
  components: {
  },
  data() {
    return {
      appVersion: version,
    }
  },
  computed: {
    ...mapState({
      user: state => state.moduleUsuario.user,
      menu: state => state.moduleMenu.menu
    })
  },
  methods:{
    nomeAbrev(){
      return this.user.username.charAt(0).toUpperCase()
    },
    sair(){
      this.monitorar()
      this.$router.push('/login')
      window.location.reload() 
    },
    monitorar(){
      var login = this.user.login
      var procedimento = 'logout'
      var descricao = 'clicou em sair'
      this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
    }
  },
}
</script>

<template>
  <div id="app">
    <b-nav  id="nav" v-show="user.loggedIn == true" > 
      <b-sidebar id="sidebar-left" title="Central Programador" backdrop-variant="dark" backdrop shadow left>
        <hr>
        <div v-for="aux in menu" :key="aux.id" class="text-light" v-show="user.menus.includes(aux.id)">
          <h6 class="menu_principal" v-if="aux.id_pai == 0">-- {{aux.descricao}} --</h6>
          <div v-for="sub_aux in menu" :key="sub_aux.id" v-show="user.menus.includes(sub_aux.id)"> 
            <b-dropdown-item v-if="aux.id == sub_aux.id_pai" :to="sub_aux.url">
              {{sub_aux.descricao}}
            </b-dropdown-item>
          </div>
          <hr v-show="aux.id_pai == 0">
        </div>
        
        <template #footer="{ hide }">
          <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
            <strong class="mr-auto">Versao: {{appVersion}}</strong>
            <b-button size="sm" @click="hide"><b-icon icon="x" aria-hidden="true" animation="fade"></b-icon> Fechar</b-button>
          </div>
        </template>
      </b-sidebar>

      <b-button-toolbar class="mb-2">
        <b-button-group>
          <b-button class="btn-margem" v-b-toggle.sidebar-left title="Menu">
            <b-icon icon="list" font-scale="1" color="white" animation="cylon"></b-icon>
          </b-button>
        </b-button-group>
      </b-button-toolbar>
      
      <b-nav-item class="ml-auto" >
        <h6>
          <b-avatar variant="dark" :badge="nomeAbrev()"></b-avatar>
          <strong v-show="!$isMobile()">
            {{ user.username }}
          </strong>
        </h6>
      </b-nav-item>

      <b-button @click="sair()" variant="danger" class="mb-2 ml-auto">
        <b-icon icon="power" aria-hidden="true" animation="fade"></b-icon> Sair
      </b-button>
    </b-nav>

    <router-view/>
  </div>
</template>

<style>
.btn-margem{
  margin: 2px
}

#sidebar-left___title__{
  color: whitesmoke !important;
}

.close{
  color: whitesmoke !important;
}


.b-sidebar-body{
  background-color: #505668;
}

.b-sidebar-header{
  background-color: #505668;
}

.menu_principal{
  color: whitesmoke !important;
  font-weight: bold;
}

.btn_sair{
  float: left !important;
  align-items: right !important;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  color: #242d36;
  background-color: #505668;
}


#nav {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.175);
}

#nav a {
  font-weight: bold;
  color: #F3FFBD;
}

#nav a.router-link-exact-active {
  color: #F3FFBD;
}

.dropdown-menu.show{
  background-color: #242d36;
}

html, body {
  height: 100vh;
}



</style>
