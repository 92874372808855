
<script>
import Funcao from "@/components/Programador/FuncaoProgramador"
import { mapState } from "vuex"

export default {
  name: "AjustarHorario",
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      user: state => state.moduleUsuario.user,
    }),
  },
  methods:{
    formatDateNow() {
      const timeElapsed = Date.now();
      const d = new Date(timeElapsed)
      let mes = '' + (d.getMonth() + 1)
      let dia = '' + d.getDate()
      let ano = d.getFullYear()
      let hora = d.getHours()
      let minuto = d.getMinutes()
      let segundos = d.getSeconds()

      if (mes.length < 2) 
        mes = '0' + mes
      if (dia.length < 2) 
        dia = '0' + dia
      if (hora.length < 2) 
        hora = '0' + hora
      if (minuto.length < 2) 
        minuto = '0' + minuto
      if (segundos.length < 2) 
        segundos = '0' + segundos

      return [ano, dia, mes].join('-') + ' ' + [hora, minuto, segundos].join(':')
    },
    ajustarHorario() {
      let localIp = ''
      this.$swal.fire({
        title: "Ajustar horario da CPU",
        html: `
          <b-form-text>Digite o IP do Beagle:</b-form-text>
          <input type="text" id="ip" class="swal2-input" placeholder="192.168.254.101">`,
        preConfirm: () => {
          localIp = this.$swal.getPopup().querySelector("#ip").value
        },
        icon: "warning",
        confirmButtonText: "Confirmar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.ip = localIp
            if (Funcao.validacaoIP(this.ip)) {
              let ip = localIp
              let dataAtual = this.formatDateNow()
              this.$store.dispatch("AJUSTAR_HORARIO_CPU", { ip, dataAtual }).then(() => {
                this.monitorar("Gravou horario " + dataAtual + " no IP: "+ ip +" com Sucesso")
                Funcao.alertaSimples("Ajustar horario da CPU", "Gravou horario " + dataAtual + " com Sucesso", "success")
              }).catch(() => {
                this.monitorar("Não foi possivel alterar horario no IP: "+ ip)
                Funcao.alertaSimples("Ajustar horario da CPU", "Não foi possivel alteração do horario!", "error")
              })
            } else {
              Funcao.alertaSimples("IP Invalido","Cancelado!","error")
            }
          } else {
            Funcao.alertaSimples("Ajustar horario da CPU","Cancelado!","error")
          }
      })
    },
    monitorar(valor){
      var login = this.user.login
      var procedimento = 'ajustar horario'
      var descricao = valor
      this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
    }
  },
}
</script>

<template>
  <b-button class="float-left btn_filtro shadow rounded" @click="ajustarHorario" variant="info">
    Ajustar Horario
  </b-button>
</template>

<style>
</style>