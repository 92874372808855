<script>
import { mapState } from 'vuex'

export default {
  name: 'EditarListaUsuario',
  data() {
    return {
      colunas: [
        {key: 'login', sortable: true}, 
        {key: 'nome', sortable: true}, 
        {key: 'estado', label: 'Estado', sortable: true},
        {key: 'detalhe'}],
      colunasMobile: [
        {key: 'login', sortable: true}, 
        {key: 'nome', sortable: true},
        {key: 'detalhe'}],
      perPage: 10,
      currentPage: 1,
    }
  },
  computed: mapState({
    pesquisa_lista_usuarios: state => state.moduleUsuario.pesquisa_lista_usuarios,
    user: state => state.moduleUsuario.user
  })
}
</script>

<template>
    <div id="pesquisa">
    <b-container class="container" v-show="Object.keys(pesquisa_lista_usuarios).length > 0">
      <b-table class="tabela_lista_usuario" hover :items="pesquisa_lista_usuarios" :fields="$isMobile() ? colunasMobile: colunas " :current-page="currentPage" :per-page="perPage">
        <template #cell(detalhe)="row">
          <b-button size="sm" @click="row.toggleDetails" class="mr-2">
            <a v-if="$isMobile()">{{ row.detailsShowing ? 'Esconder' : 'Mostrar'}}</a>
            <a v-else>{{ row.detailsShowing ? 'Esconder' : 'Mostrar'}} Detalhes</a>
          </b-button>
        </template>

        <template #row-details="row">
          <b-form-row class="tabela_detalhe">
            <b-col cols=12 md="6">
              <b-row v-show="$isMobile()">
                <b-col cols="4" class="text-sm-right"><b>Estado:</b></b-col>
                <b-col cols="8">{{ row.item.estado }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4" class="text-sm-right"><b>Telefone:</b></b-col>
                <b-col cols="8">{{ row.item.telefone }}</b-col>
              </b-row>
            </b-col>

            <b-col cols=12 md="6">
              <b-row>
                <b-col cols="4" class="text-sm-right"><b>Cidade:</b></b-col>
                <b-col cols="8">{{ row.item.cidade }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4" class="text-sm-right"><b>Ativo:</b></b-col>
                <b-col cols="8">{{ row.item.ativo }}</b-col>
              </b-row>
            </b-col>
          </b-form-row>
        </template>
      </b-table>
      <b-pagination class="paginacao" v-model="currentPage" :total-rows="Object.keys(pesquisa_lista_usuarios).length" :per-page="perPage"
        align="fill" first-number last-number
      ></b-pagination>
    </b-container>
  </div>
    
</template>

<style scoped>
@media screen and (max-width: 500px){
  #pesquisa {
    font-size: 13px;
  }
}

@media screen and (min-width: 501px){
  #pesquisa{
    font-size: 16px;
  }
}
</style>