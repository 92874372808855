<script>
import { mapState } from "vuex"
import Vue from "vue"
import Cabecalho from "@/components/Programador/CabecalhoProgramador"
import Tipo from "@/components/Programador/TipoFaseProgramador"
import Croquei from "@/components/Programador/CroqueiProgramador"
import Conflito from "@/components/Programador/ConflitoProgramador"
import Estagio from "@/components/Programador/EstagioProgramador"
import Ordem from "@/components/Programador/OrdemProgramador"
import Plano from "@/components/Programador/PlanoProgramador"
import Entrada from "@/components/Programador/EntradaProgramador"
import Especial from "@/components/Programador/EspecialProgramador"
import Botoes from "@/components/Programador/BotoesProgramador"
import Funcao from "@/components/Programador/FuncaoProgramador"
import barramento_plano from "@/components/Barramentos/Plano"


export default {
  name: "EditarProgramador",
  components: {
    Cabecalho,
    Tipo,
    Croquei,
    Conflito,
    Estagio,
    Ordem,
    Plano,
    Entrada,
    Especial,
    Botoes,
  },
  data() {
    return {
      subprogramadores: []
    }
  },
  computed: {
    ...mapState({
      programador_lista: (state) => state.moduleProgramador.programador_lista,
      programador_modo: (state) => state.moduleProgramador.programador_modo,
      programador_editar: (state) => state.moduleProgramador.programador_editar,
    }),
  },
  methods: {
    inserirSubprogramador () {
      if (this.subprogramadores.length > 3){
        Funcao.alertaSimples("Quantidade Limite", "Não é permitido inserir mais de 4 subcontroladores", "error")
      }if ((this.subprogramadores[0].maxFases <= 2) || (this.subprogramadores.length == 1 && this.subprogramadores[0].fasesQtde >  6)){
        Funcao.alertaSimples("Quantidade Limite", "Não possui mais fases para criar novo subcontroladores", "error")
      }else{
        this.subprogramadores.push(Funcao.zerarProgramador())
      }
    },
    nomeIcone(showCollapse) {
      return showCollapse ? "plus" : "minus"
    },
    zerar_campos() {
      //this.programador = Funcao.zerarProgramador()
      var editar = true
      var modo = "original"
      this.$store.dispatch("PROGRAMADOR_STATUS", { editar, modo })
    },
    validacaoJson(listagem) {
      try {
        let crc_documento = listagem.Crc
        delete listagem.Crc
        let crc_check = Funcao.gerarCrc32(listagem)
        if (crc_documento == crc_check) {
          Funcao.alertaSimples("Carregando Arquivo", "Arquivo carregado com sucesso", "success")
          this.$root.$emit('bv::toggle::collapse', 'sub1')
          return true
        } else {
          Funcao.alertaSimples("Carregando Arquivo", "Arquivo não confiavel, crc diferente", "error")
          this.zerar_campos()
          return false
        }
      } catch (error) {
        Funcao.alertaSimples("Carregando Arquivo", "Arquivo não possivel carregar", "error")
        this.zerar_campos()
        return false
      }
    },
    clickCollapseSub(subprogramador,indexSub){
      Vue.set(subprogramador, 'showCollapse', !subprogramador.showCollapse)
      this.$root.$emit('bv::toggle::collapse', 'sub'+indexSub)
    },
    clickRemoveSub(subprogramador){
      this.subprogramadores = this.subprogramadores.filter(function (item) {
        return item !== subprogramador
      })
    }
  },
  watch: {
    programador_lista() {
      if (this.programador_lista != "limpar" && this.programador_modo != "novo") {
        if (!this.validacaoJson(this.programador_lista)) return

        this.subprogramadores = []
        for (let subIndex in this.programador_lista.Subcontrolador){
          this.subprogramadores.push(Funcao.zerarProgramador())
          let subcontrolador = this.programador_lista.Subcontrolador[subIndex]
          let programador = this.subprogramadores[subIndex]

          programador.tipoFases = subcontrolador.TipoFases

          programador.aproximacoes = subcontrolador.Aproximacoes  

          programador.conflitosJson = subcontrolador.Conflitos

          programador.estagiosJson = subcontrolador.Estagios
          
          programador.ordensJson = subcontrolador.Ordem

          programador.planosJson = subcontrolador.Planos

          programador.entradas = subcontrolador.Entradas
          
          programador.especiais = subcontrolador.DatasEspeciais
        }
        barramento_plano.setLoadPlanos()
      } 
      else {
        this.subprogramadores = []
        this.subprogramadores.push(Funcao.zerarProgramador())
      }
    },
    programador_modo() {
      if (this.programador_modo == "novo") {
        this.subprogramadores = []
        this.subprogramadores.push(Funcao.zerarProgramador())
      }
    },
  },
  created(){
    
  }
}
</script>

<template>
  <div>
    <hr/>
    <Botoes :subprogramadores="subprogramadores"/>
    <div v-for="(subprogramador, indexSub) in subprogramadores" :key="'sub' + indexSub">
      <hr/>
      <b-row class="filtros-row">
        <b-col cols="9">
          <div v-if="subprogramadores.length == 1" class="textoFiltros">Programador Padrão</div>
          <div v-else class="textoFiltros">Anel {{indexSub + 1}}
            <b-button pill variant="outline-danger" size="sm" class="right shadow rounded" :disabled="programador_editar" @click="clickRemoveSub(subprogramador)">
              Remover
            </b-button>
          </div>
        </b-col>
        <b-col>
          <b-button class="collapse-button float-right shadow rounded" aria-controls="collapse-4" @click="clickCollapseSub(subprogramador,indexSub)">
            <icon :name="nomeIcone(subprogramador.showCollapse)" />
          </b-button>
        </b-col>
      </b-row>
      <b-collapse :id="'sub' + indexSub" visible>
        <b-form class="form-login">
          <b-form-group>
            <div v-if="subprogramadores.length == 1">
              <h5 v-if="$isMobile()">Programador</h5>
              <h3 v-else>Programador</h3>
            </div>
            <div v-else>
              <h5 v-if="$isMobile()">Anel {{indexSub + 1}}</h5>
              <h3 v-else>Anel {{indexSub + 1}}</h3>
            </div>
            <Cabecalho :programador="subprogramador"  :posicaoProgramador="indexSub" :subprogramadores="subprogramadores"/>
            <hr/>
            <Croquei :programador="subprogramador"/>
            <hr/>
            <Tipo :programador="subprogramador"/>
            <Conflito :programador="subprogramador"/>
            <Estagio :programador="subprogramador"/>
            <Ordem :programador="subprogramador"/>
            <Plano :programador="subprogramador"/>
            <Entrada :subProgramadores="subprogramadores" :programador="subprogramador" :posicaoProgramador="indexSub"/>
            <Especial :programador="subprogramador"/>
          </b-form-group>
        </b-form>
      </b-collapse>
    </div>
    <b-button class="button_plano shadow rounded" @click="inserirSubprogramador" :disabled="programador_editar" :variant="programador_editar == true ? 'outline-success' : 'success'">
      Inserir Anel
    </b-button>
    <hr/>
    <Botoes :subprogramadores="subprogramadores"/>
  </div>
</template>

<style scoped>
.b-icon.bi {
  color: #6c757d !important;
}
</style>
