
<script>
import { mapState } from "vuex"

export default {
  name: "NovoFiltro",
  methods:{
    novo() {
      let programador = []
      this.$store.dispatch("LIMPA_PROGRAMADOR", { programador })
      let editar = false
      let modo = "novo"
      this.$store.dispatch("PROGRAMADOR_STATUS", { editar, modo })
    },
    monitorar(){
      var login = this.user.login
      var procedimento = 'novo'
      var descricao = 'clicou em novo'
      this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
    }
  },
  computed: {
    ...mapState({
      user: state => state.moduleUsuario.user,
    }),
  }
}
</script>

<template>
  <b-button class="float-right btn_filtro shadow rounded" v-b-toggle.collapse-2 @click="novo" variant="primary">
    Novo
  </b-button>
</template>


<style>

</style>