export default {
  ENVIO_TABELA_LOCAL(state, retorno_envio_tabela_local) {
    state.retorno_envio_tabela_local = retorno_envio_tabela_local
  },
  LER_TABELA_LOCAL(state, retorno_ler_tabela_local) {
    state.programador_lista = retorno_ler_tabela_local
  },
  AMARELO_PISCANTE_LOCAL(state, retorno_amarelo_piscante_local) {
    state.amarelo_piscante_local = retorno_amarelo_piscante_local
  },
  MUDAR_REDE_BEAGLE(state, retorno_mudar_rede_beagle) {
    state.retorno_mudar_rede_beagle = retorno_mudar_rede_beagle
  },
  VERIFICAR_AUTENTICACAO_TABELA(state, retorno_autenticacao_tabela) {
    state.retorno_autenticacao_tabela = retorno_autenticacao_tabela
  },
  VERIFICAR_AUTENTICACAO(state, retorno_autenticacao) {
    state.retorno_autenticacao = retorno_autenticacao
  },
  ATIVAR_AUTENTICACAO(state, ativar_autenticacao) {
    state.ativar_autenticacao = ativar_autenticacao
  },
  DESATIVAR_AUTENTICACAO(state, desativar_autenticacao) {
    state.desativar_autenticacao = desativar_autenticacao
  },
  PROGRAMADOR_LISTA(state, programador_lista) {
    state.programador_lista = programador_lista
  },
  PROGRAMADOR_STATUS(state, valores) {
    state.programador_editar = valores['editar']
    state.programador_modo = valores['modo']
  },
  AJUSTAR_HORARIO_CPU(state, valor) {
    state.ajustar_horario_cpu = valor
  },
  AJUSTAR_FUSO_HORARIO_CPU(state, valor) {
    state.ajustar_fuso_horario_cpu = valor
  },
}