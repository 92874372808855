import Vue from 'vue'
import VueRouter from 'vue-router'
import Programador from '@/views/VProgramador'
import Login from '@/views/VLogin'
import Home from '@/views/VHome'
import ListaUsuarios from '@/views/VListaUsuarios'
import CadastroUsuario from '@/views/VCadastroUsuario'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/programador',
    name: 'Programador',
    component: Programador
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/lista_usuarios',
    name: 'ListaUsuarios',
    component: ListaUsuarios
  },
  {
    path: '/cadastro_usuario',
    name: 'CadastroUsuario',
    component: CadastroUsuario
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router