import api_programador from '@/api/api_programador'
import Vue from 'vue'
//import { asyncLoading } from 'vuejs-loading-plugin'
import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Carregando', // default 'Loading'
  loading: false, // default false
  background: 'rgba(10,23,55,0.7)', // set custom background
})

export default {
  ENVIO_TABELA_LOCAL({ commit }, { ip, tabela }) { //FiltrosProgramador
    let loading = new Promise((resolve, reject) => {
      api_programador.envio_tabela_local(ip, tabela).then((result) => {
        commit('ENVIO_TABELA_LOCAL', result)
        resolve(result)
      }).catch((error) => {
        console.log(error)
        reject(error)
      })
    })
    return Promise.resolve(loading)
  },
  LER_TABELA_LOCAL({ commit }, { ip }) { //FiltrosProgramador
    let loading = new Promise((resolve, reject) => {
      api_programador.ler_tabela_local(ip).then((result) => {
        commit('LER_TABELA_LOCAL', result)
        resolve(result)
      }).catch((error) => {
        console.log(error)
        reject(error)
      })
    })
    return Promise.resolve(loading)
  },
  AMARELO_PISCANTE_LOCAL({ commit }, { ip }) { //FiltrosProgramador
    let loading = new Promise((resolve, reject) => {
      api_programador.amarelo_piscante_local(ip).then((result) => {
        commit('AMARELO_PISCANTE_LOCAL', result)
        resolve(result)
      }).catch((error) => {
        console.log(error)
        reject(error)
      })
    })
    return Promise.resolve(loading)
  },
  MUDAR_REDE_BEAGLE({ commit }, { rede }) { //FiltrosProgramador
    let loading = new Promise((resolve, reject) => {
      api_programador.mudar_rede_beagle(rede).then((result) => {
        commit('MUDAR_REDE_BEAGLE', result)
        resolve(result)
      }).catch((error) => {
        console.log(error)
        reject(error)
      })
    })
    return Promise.resolve(loading)
  },
  VERIFICAR_AUTENTICACAO({ commit }, { ip, tipo }) { //FiltrosProgramador
    let loading = new Promise((resolve, reject) => {
      api_programador.verificar_autenticacao(ip).then((result) => {
        commit(tipo, result)
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
    return Promise.resolve(loading)
  },
  ATIVAR_AUTENTICACAO({ commit }, { ip, senha }) { //FiltrosProgramador
    let loading = new Promise((resolve, reject) => {
      api_programador.ativar_autenticacao(ip, senha).then((result) => {
        commit('ATIVAR_AUTENTICACAO', result)
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
    return Promise.resolve(loading)
  },
  DESATIVAR_AUTENTICACAO({ commit }, { ip, senha }) { //FiltrosProgramador
    let loading = new Promise((resolve, reject) => {
      api_programador.desativar_autenticacao(ip, senha).then((result) => {
        commit('DESATIVAR_AUTENTICACAO', result)
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
    return Promise.resolve(loading)
  },
  ALTERAR_AUTENTICACAO({ commit }, { ip, antiga, nova }) { //FiltrosProgramador
    let loading = new Promise((resolve, reject) => {
      api_programador.alterar_autenticacao(ip, antiga, nova).then((result) => {
        commit('ALTERAR_AUTENTICACAO', result)
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
    return Promise.resolve(loading)
  },
  PROGRAMADOR_LISTA({ commit }, { programador }) { //FiltrosProgramador
    let loading = new Promise((resolve, reject) => {
      try {
        commit('PROGRAMADOR_LISTA', programador)
        resolve(programador)
      } catch (error) {
        reject(error)
      }
    })
    return Promise.resolve(loading)
  },
  LIMPA_PROGRAMADOR({ commit }, { programador }) { //FiltrosProgramador
    let loading = new Promise((resolve, reject) => {
      try {
        commit('PROGRAMADOR_LISTA', programador)
        resolve(programador)
      } catch (error) {
        reject(error)
      }
    })
    return Promise.resolve(loading)
  },
  PROGRAMADOR_STATUS({ commit }, { editar, modo }) { //FiltrosProgramador, EditarProgramador
    let loading = new Promise((resolve, reject) => {
      try {
        let valores = { 'editar': editar, 'modo': modo }
        commit('PROGRAMADOR_STATUS', valores)
        resolve(editar)
      } catch (error) {
        reject(error)
      }
    })
    Promise.resolve(loading)
  },
  AJUSTAR_HORARIO_CPU({ commit }, { ip, dataAtual }) { //FiltrosProgramador
    let loading = new Promise((resolve, reject) => {
      api_programador.ajustar_horario_cpu(ip, dataAtual).then((result) => {
        commit('AJUSTAR_HORARIO_CPU', result)
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
    return Promise.resolve(loading)
  },
  AJUSTAR_FUSO_HORARIO_CPU({ commit }, { ip }) { //FiltrosProgramador
    let loading = new Promise((resolve, reject) => {
      api_programador.ajustar_fuso_horario_cpu(ip).then((result) => {
        commit('AJUSTAR_FUSO_HORARIO_CPU', result)
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
    return Promise.resolve(loading)
  },

}