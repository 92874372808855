
<script>
import Funcao from "@/components/Programador/FuncaoProgramador"

export default {
  name: "EnviarParaCPU",
  props: { 
    subprogramadores: Array,
    labelBotaoGravar: String,
    tipoBotao: String
  },
  methods:{
    gravarCentral() {
      Funcao.getIpLocalizacao(this, this.login)
      this.$swal
        .fire({
          title: "Login",
          html: `
        <input type="text" id="login" class="swal2-input" placeholder="Usuario">
        <input type="password" id="password" class="swal2-input" placeholder="Senha">`,
          preConfirm: () => {
            let usuario = this.$swal.getPopup().querySelector("#login").value
            let senha = this.$swal.getPopup().querySelector("#password").value
            if (!usuario || !senha) {
              this.$swal.showValidationMessage("Entre com Usuario e Senha")
            } else {
              this.login.usuario = usuario
              this.login.senha = senha
            }
          },
          icon: "question",
          confirmButtonText: "Login",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          allowEscapeKey: true,
          allowEnterKey: true,
          focusConfirm: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.temLogin = true
          } else {
            this.temLogin = false
            Funcao.alertaSimples("Login", "Cancelado!", "error")
          }
        })
    },
  }
}
</script>

<template>
  <b-button class="button-gravar shadow rounded" @click="gravarCentral" :variant="tipoBotao">
    {{ labelBotaoGravar }}
    <a v-show="labelBotaoGravar != '---'">Enviar Para CPU</a>
  </b-button>
</template>


<style>

</style>