import api from '@/api/api'
import Vue from 'vue'
import { asyncLoading } from 'vuejs-loading-plugin'
import VueLoading from 'vuejs-loading-plugin'


Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Carregando', // default 'Loading'
  loading: false, // default false
  background: 'rgba(10,23,55,0.7)', // set custom background
})

export default {
  IP_CLIENTE() {
    let loading = new Promise((resolve, reject) => {
      try {
        let ip = api.ip_cliente()
        resolve(ip)
      } catch (error) {
        alert(`retorno:${error}`)
        reject(error)
      }
    })
    return asyncLoading(Promise.resolve(loading)).then().catch()
  }
}