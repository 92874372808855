
<script>

export default {
  name: "FerramentaPlano",
  methods:{
    ferramentaPlano(){
      let editar = false
      let modo = "ferramenta"
      this.$store.dispatch("PROGRAMADOR_STATUS", { editar, modo })
    },
  }
}
</script>

<template>
  <b-button class="float-left btn_filtro shadow rounded" v-b-toggle.collapse-2 @click="ferramentaPlano" variant="info">
    Ferramenta
  </b-button>
</template>


<style>

</style>