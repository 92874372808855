import axios from 'axios'
import CryptoJS from 'crypto-js'

export default {
    ip_cliente() {
        return axios.get('https://api.ipify.org?format=json').then(result => result.data)
    },
    randomString() {
        for (var e = "", t = 32, a = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", o = t; o > 0; --o) e += a[Math.floor(Math.random() * a.length)];
        return e
    },
    aesEncrypt(e) {
        var t = this.randomString(),
        a = CryptoJS.AES.encrypt(e, CryptoJS.enc.Utf8.parse(t), {mode: CryptoJS.mode.ECB})
        return t + "/" + a.toString()
    },
    aesDencrypt(e) {
        var t = this.randomString(),
        a = CryptoJS.AES.decrypt(e, CryptoJS.enc.Utf8.parse(t), {
            mode: CryptoJS.mode.ECB
        });
        return CryptoJS.enc.Utf8.stringify(a).toString()
    },
}