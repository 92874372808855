<script>
import FiltrosProgramador from "@/components/Programador/FiltrosProgramador"
import EditarProgramador from "@/components/Programador/EditarProgramador"
import FerramentaProgramador from "@/components/Programador/FerramentaProgramador"
import { mapState } from "vuex"
import { version } from "../../package.json"

export default {
  name: "VProgramador",
  components: {
    FiltrosProgramador,
    EditarProgramador,
    FerramentaProgramador
  },
  data() {
    return {
      appVersion: version,
    }
  },
  computed: {
    ...mapState({
      programador_modo: (state) => state.moduleProgramador.programador_modo,
      programador_lista: (state) => state.moduleProgramador.programador_lista,
      user: state => state.moduleUsuario.user,
    }),
  },
  created(){
    var login = this.user.login
    var procedimento = 'Programador'
    var descricao = 'acessou Programador'
    this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
  }
}
</script>

<template>
  <div style="background-color:#505668;">
    <b-container class="container">
      <FiltrosProgramador />
      <EditarProgramador v-show="(programador_modo == 'novo' && programador_lista != 'limpar') || 
                                 (programador_modo == 'alterar' && programador_lista != 'limpar') || 
                                 (programador_lista != '' && programador_lista != 'limpar')"/>
      <FerramentaProgramador v-show="programador_modo == 'ferramenta'"/>
      <p class="estiloRodape">Versão: {{ appVersion }}</p>
    </b-container>
  </div>
</template>

<style scoped>
.container {
  background-color: white;
  border-width: 0.2 rem;
}

.estiloRodape {
  color: rgb(158, 154, 154) !important;
  text-align: left;
}
</style>


