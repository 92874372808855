<script>
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    
  },
  methods: {

  },
  computed: {
    ...mapState({
      user: state => state.moduleUsuario.user
    })
  }
}
</script>

<template>
  <div class="row homeContainer">
    <span> {{ user.loggedIn == true ? $router.push('/programador') : $router.push('/login') }} </span>
    <!--<span> {{ user.loggedIn == false ? $router.push('/programador') : $router.push('/login') }} </span>-->
  </div>
</template>

<style scoped>
  .homeContainer {
    height: 94%;
    text-align: center;
    width: 100vw;
    margin-left: 0px;
    margin-right: 0px;
  }
</style>
