import api_beagle from '@/api/api_beagle'


export default {
  LER_HORA_MAQUINA({ commit }, { ip }) { 
    let loading = new Promise((resolve, reject) => {
      api_beagle.ler_hora_maquina(ip).then((result) => {
        commit('LER_HORA_MAQUINA', result)
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
    return Promise.resolve(loading)
  },
  LER_PLANOS_ESTAGIOS({ commit }, { ip }) { 
    let loading = new Promise((resolve, reject) => {
      api_beagle.ler_planos_estagios(ip).then((result) => {
        commit('LER_PLANOS_ESTAGIOS', result)
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
    return Promise.resolve(loading)
  },
  LER_VERSAO({ commit }, { ip }) { 
    let loading = new Promise((resolve, reject) => {
      api_beagle.ler_versao(ip).then((result) => {
        commit('LER_VERSAO', result)
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
    return Promise.resolve(loading)
  }
}