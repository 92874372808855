import axios from 'axios'
export default {
  ler_hora_maquina (ip) {
    return axios.post('http://' + ip + ':8080/hora_maquina').then(result => result.data)
  },
  ler_planos_estagios (ip) {
    return axios.post('http://' + ip + ':8080/planos_e_estagios').then(result => result.data)
  },
  ler_versao (ip) {
    return axios.post('http://' + ip + ':8080/versao').then(result => result.data)
  },
}
