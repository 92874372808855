
<script>
import Funcao from "@/components/Programador/FuncaoProgramador"
import barramento_filtro from "@/components/Barramentos/Filtros"
import { mapState } from "vuex"

export default {
  name: "SalvarLocal",
  data() {
    return {
      fileName: ''
    }
  },
  created(){
    barramento_filtro.onLoadFileName(valor => {
      this.fileName = valor
    })
  },
  computed: {
    ...mapState({
      user: state => state.moduleUsuario.user,
    }),
  },
  props: { 
    subprogramadores: Array
  },
  methods:{
    salvarLocal() {
      this.$swal
        .fire({
          title: "Salvar Programação Local",
          text: "Digite o nome do arquivo: ",
          input: "text",
          icon: "question",
          inputPlaceholder: this.fileName == '' ? "programacao" : this.fileName.replace('.json', ''),
          confirmButtonText: "Confirmar",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let exportObj = Funcao.gerarJson(this.subprogramadores)
            let exportName = result.value == "" ? this.fileName == '' ? "programacao" : this.fileName.replace('.json', '') : result.value
            var dataStr =
              "data:text/json;charset=utf-8," +
              encodeURIComponent(JSON.stringify(exportObj))
            var downloadAnchorNode = document.createElement("a")
            downloadAnchorNode.setAttribute("href", dataStr)
            downloadAnchorNode.setAttribute("download", exportName + ".json")
            document.body.appendChild(downloadAnchorNode) // required for firefox
            downloadAnchorNode.click()
            downloadAnchorNode.remove()
            var login = this.user.login
            var procedimento = 'Salvar no Computador'
            var descricao = JSON.stringify(exportObj)
            this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
          } else {
            Funcao.alertaSimples("Salvar Programação Local", "Cancelado!","error")
          }
        })
    },
  }
}
</script>

<template>
  <b-button class="button-gravar shadow rounded" @click="salvarLocal" variant="primary">
    Salvar no Computador
  </b-button>
</template>


<style>

</style>