
<script>
import { mapState } from "vuex"


export default {
  name: "CroqueiProgramador",
  props: { 
    programador: Object,
  },
  computed: {
    ...mapState({
      programador_editar: (state) => state.moduleProgramador.programador_editar,
    }),
  }
}
</script>

<template>
  <div>
    <b-row class="text-left" align-v="center">
      <b-col :cols="$isMobile() ? 8 : 3">
        <b-form-text id="croquei_cruzamento">Croquei do Cruzamento</b-form-text>
      </b-col>
    </b-row>
    <b-row class="text-left" align-v="center">
      <b-col v-for="(aux, index) in programador.fasesQtde" :key="index" cols="6">
        <b-form-text>{{ " Fase: " + (programador.inicioFase + index).toString() }}</b-form-text>
        <b-form-input class="input-cadastro shadow rounded" :readonly="programador_editar" v-model="programador.aproximacoes[index]" required trim/>
      </b-col>
    </b-row>
  </div>
</template>

<style>

</style>