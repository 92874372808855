import Vue from 'vue'
export default new Vue({
  methods: {
    setTipoFaseChange(fase, tipo) {
      this.$emit('tipoFaseChange', fase, tipo)
    },
    onTipoFaseChange(onCallbackFase,onCallbackTipo) {
      this.$on('tipoFaseChange', onCallbackFase, onCallbackTipo)
    }
  }
})