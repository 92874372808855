
<script>
import { mapState } from "vuex"
import Vue from "vue"

export default {
  name: "EstagioProgramador",
  props: { 
    programador: Object
  },
  data() {
    return {
      opcaoEstagios: [
        { value: "0", text: "Selecione Entrada", disabled: true },
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
      ],
    }
  },
  computed: {
    ...mapState({
      programador_editar: (state) => state.moduleProgramador.programador_editar,
      programador_modo: (state) => state.moduleProgramador.programador_modo,
    }),
    //estagio(){
    //  return this.programador.estagiosJson
    //}
  },
  methods: {
    inserirEstagio() {
      this.programador.estagios.push({
        faseSelect: [],
        faseSelectConflito: [],
        atuador: 0,
        atuadorEstado: false,
        atuadorSincronizado: false
      })
      this.programador.estagiosOpcoes.push({value: this.programador.estagios.length, text: "Estagio " + (this.programador.estagios.length)})
      this.programador.estagiosFerreaOpcoes.push({value: this.programador.estagios.length, text: "Estagio " + (this.programador.estagios.length), disabled: false })
    },
    removerEstagio(estagio) {
      this.programador.estagios = this.programador.estagios.filter(function (item) {
        return item !== estagio
      })
      this.programador.estagiosOpcoes = []
      this.programador.estagiosFerreaOpcoes = []
      for (let index = 1; index <= this.programador.estagios.length; index++) {
        this.programador.estagiosOpcoes.push({value: index, text: "Estagio " + index})
        this.programador.estagiosFerreaOpcoes.push({value: index, text: "Estagio " + index, disabled: false })
      }
    },
    changeConflito(valor, index, aux) {
      for (let key in this.programador.conflitos) {
        let estado = this.programador.conflitos[key]
        if (estado) {
          let posicao = key.split("_")
          if (posicao[0] == aux) {
            console.log
            Vue.set(this.programador.estagios[index].faseSelectConflito, posicao[1], valor)
            Vue.set(this.programador.estagios[index].faseSelect, posicao[1], false)

          }
        }
      }
      
      this.programador.estagiosFerreaOpcoes = []
      var valoresFerrea = []
      for (const key in this.programador.conflitos) {
        if(key.includes('ferrea')){
          var element = this.programador.conflitos[key];
          if(element){
            valoresFerrea.push(parseInt(key.replace('ferrea_', '')) + 1)
          }
        }
      }
      for (const key in this.programador.estagios) {
        var conflito = false
        element = this.programador.estagios[key].faseSelect;
        for (const key1 in element) {
          var element1 = element[key1]
          if (element1){
            for(const key3 in valoresFerrea){
              var aux1 = valoresFerrea[key3]
              if(aux1 == key1){
                conflito = true
              }
            }
          }
        }
        if(conflito == false){
          this.programador.estagiosFerreaOpcoes.push({value: parseInt(key) + 1, text: "Estagio " + (parseInt(key) + 1), disabled: false})
        }else{
          this.programador.estagiosFerreaOpcoes.push({value: parseInt(key) + 1, text: "Estagio " + (parseInt(key) + 1), disabled: true})
        }
      }
    },
    changeAtuadorEstado(estado, index) {
      if (!estado) {
        this.programador.estagios[index].atuador = 0
      }
    },
    changeatuadorSincronizadoEstado(estado,index) {
      for (let planoIndex in this.programador.planos){
        let plano = this.programador.planos[planoIndex]
        for (let estagioIndex in plano.estagios){
          let estagio = plano.estagios[estagioIndex]
          if (index + 1 == estagio){
            if (estado){
              plano['vd_' + estagio + '_ext'] = plano['vd_' + estagio]
              plano['am_' + estagio + '_ext'] = plano['am_' + estagio]
              plano['vmb_' + estagio + '_ext'] = plano['vmb_' + estagio]
              plano['pa_' + estagio + '_ext'] = plano['pa_' + estagio]
              plano['vd_' + estagio] = 0
              plano['am_' + estagio] = 0
              plano['vmb_' + estagio] = 0
              plano['pa_' + estagio] = 0
              plano['cs_' + estagio] = 0
              plano['cs_' + estagio + '_ext'] = 0
            }else{
              plano['vd_' + estagio] = plano['vd_' + estagio + '_ext']    
              plano['am_' + estagio] = plano['am_' + estagio + '_ext']
              plano['vmb_' + estagio] = plano['vmb_' + estagio + '_ext']
              plano['pa_' + estagio] = plano['pa_' + estagio + '_ext']
            }
          }
        }
        plano.estendido_ativo = estado
      }
    }
  },
  watch: {
    programador_modo() {
      if (this.programador_modo == "novo") {
        this.inserirEstagio()
      }
    },
    programador:{
      handler(val){
        const estagios = []
        const estagiosOpcoes = []
        const estagiosFerreaOpcoes = []
        for (let key in val.estagiosJson) {
          let valor = val.estagiosJson[key]
          let fases = []
          let fasesConflitos = []
          for (const aux in valor.fases) {
            fases[valor.fases[aux].toString()] = true
            for (let key in this.programador.conflitos) {
              let estado = this.programador.conflitos[key]
              if (estado) {
                let posicao = key.split("_")
                if (posicao[0] == valor.fases[aux]) {
                  fasesConflitos[posicao[1]] = true
                }
              }
            }
          }
          estagios.push({
            faseSelect: fases,
            faseSelectConflito: fasesConflitos,
            atuador: valor.atuadorEntrada,
            atuadorEstado: valor.atuador,
            atuadorSincronizado: valor.atuadorSincronizado
          })
          estagiosOpcoes.push({
            value: estagios.length, 
            text: "Estagio " + estagios.length
          })
          estagiosFerreaOpcoes.push({
            value: estagios.length, 
            text: "Estagio " + estagios.length
          })
        }
        var valoresFerrea = []
        for (const key in this.programador.conflitos) {
          if(key.includes('ferrea')){
            var element = this.programador.conflitos[key];
            if(element){
              valoresFerrea.push(parseInt(key.replace('ferrea_', '')) + 1)
            }
          }
        }
        for (const key in this.programador.estagios) {
          var conflito = false
          element = this.programador.estagios[key].faseSelect;
          for (const key1 in element) {
            var element1 = element[key1]
            if (element1){
              for(const key3 in valoresFerrea){
                var aux1 = valoresFerrea[key3]
                if(aux1 == key1){
                  conflito = true
                }
              }
            }
          }
          if(conflito == false){
            estagiosFerreaOpcoes[key] = {value: parseInt(key) + 1, text: "Estagio " + (parseInt(key) + 1), disabled: false}
          }else{
            estagiosFerreaOpcoes[key] = {value: parseInt(key) + 1, text: "Estagio " + (parseInt(key) + 1), disabled: true}
          }
        }
        Vue.set(this.programador, "estagios", estagios)
        Vue.set(this.programador, "estagiosOpcoes", estagiosOpcoes)
        Vue.set(this.programador, "estagiosFerreaOpcoes", estagiosFerreaOpcoes)
      },
      immediate: true 
    },
  },
}
</script>

<template>
  <div>
    <b-row class="text-left"
      v-for="(estagio, index) in programador.estagios" :key="index + Math.random()">
      <b-col class="estagio_estilo">
        <b-row align-v="center">
          <b-col :cols="$isMobile() ? 12 : 1">
            <b-form-text>Estagio: {{ index + 1 }} </b-form-text>
          </b-col>
          <b-col :cols="$isMobile() ? 12 : 3">
            <b-form-checkbox v-for="(aux, index1) in programador.fasesQtde" :key="aux" @input="changeConflito($event, index, aux)"
              :disabled="programador_editar || programador.estagios[index].faseSelectConflito[aux]"
              v-model="programador.estagios[index].faseSelect[aux]" inline switch>
              Fase {{ (programador.inicioFase + index1).toString() }}
            </b-form-checkbox>
            <b-form-checkbox :disabled="programador_editar" @input="changeAtuadorEstado($event, index)"
              v-model="programador.estagios[index].atuadorEstado" inline switch>
              Atuador
            </b-form-checkbox>
          </b-col>
          <b-col :cols="$isMobile() ? 12 : 2">
            <b-form-checkbox :disabled="programador_editar" @input="changeatuadorSincronizadoEstado($event, index)" 
              v-model="programador.estagios[index].atuadorSincronizado"
              v-show="programador.estagios[index].atuadorEstado" inline switch>
              Sincronizado
            </b-form-checkbox>
          </b-col>
          <b-col v-if="$isMobile() ? 6 : 3">
            <b-form-select class="shadow rounded" :disabled="programador_editar" v-model="programador.estagios[index].atuador"
              v-show="programador.estagios[index].atuadorEstado" :options="opcaoEstagios" required/>
          </b-col>
          <b-col :cols="$isMobile() ? 6 : 2">
            <b-button class="button_remover_estagio shadow rounded" variant="danger" @click="removerEstagio(estagio)" :disabled="programador_editar">
              Remover
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br/>
    <b-button class="button_estagio shadow rounded" @click="inserirEstagio" :disabled="programador_editar" :variant="programador_editar == true ? 'outline-success' : 'success'">
      Inserir Estagio
    </b-button>
    <hr/>
  </div>
</template>

<style>
.estagio_estilo {
  margin-right: 15px;
  margin-left: 15px;
  border: 1px solid #6c757d;
  border-radius: 5px;
}

.button_remover_estagio {
  
  margin: 2px;
}

.button_estagio {
  padding: 15px 20px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
}
</style>