
<script>
import { mapState } from "vuex"
import SalvarLocal from "@/components/Botao/SalvarLocal"
import SalvarPdf from "@/components/Botao/SalvarPdf"
import Cancelar from "@/components/Botao/Cancelar"
import EnviarPCpu from "@/components/Botao/EnviarPCpu"
import EnviarTabelaCentral from "@/components/Botao/EnviarTabelaCentral"


export default {
  name: "EntradaProgramador",
  data() {
    return {
      labelBotaoGravar: "",
      tipoBotao: "primary",
      ip: "",
      temLogin: false,
    }
  },
  components: {
    SalvarLocal,
    SalvarPdf,
    Cancelar,
    EnviarPCpu,
    EnviarTabelaCentral
  },
  props: { 
    subprogramadores: Array
  },
  computed: {
    ...mapState({
      programador_modo: (state) => state.moduleProgramador.programador_modo,
      programador_editar: (state) => state.moduleProgramador.programador_editar,
      retorno_envio_tabela_local: (state) => state.moduleProgramador.retorno_envio_tabela_local,
      login: (state) => state.moduleProgramador.login,
    }),
  },
  methods: {
  },
  watch: {
    temLogin() {
      if (this.temLogin) {
        console.log("tem login")
        console.log(this.login)
      } else {
        console.log("sem login")
      }
    },
    programador_modo() {
      if (this.programador_modo == "original") {
        this.labelBotaoGravar = "---"
        this.tipoBotao = "primary"
      }
      if (this.programador_modo == "novo") {
        this.labelBotaoGravar = "Cadastrar"
        this.tipoBotao = "primary"
      }
      if (this.programador_modo == "alterar") {
        this.labelBotaoGravar = "Alterar"
        this.tipoBotao = "warning"
      }
    },
    retorno_envio_tabela_local() {
      console.log("chamou o watch retorno_envio_tabela_local")
      console.log(this.retorno_envio_tabela_local)
    },
  },
}
</script>

<template>
  <div>
    <b-row>
      <b-col :cols="$isMobile() ? 12 : 3">
        <Cancelar />
      </b-col>
      <b-col :cols="$isMobile() ? 12 : 3">
        <SalvarLocal :subprogramadores="subprogramadores"/>
      </b-col>
      <b-col :cols="$isMobile() ? 12 : 3">
        <SalvarPdf />
      </b-col>
      <b-col :cols="$isMobile() ? 12 : 3">
        <EnviarPCpu :subprogramadores="subprogramadores" :labelBotaoGravar="labelBotaoGravar" :tipoBotao="tipoBotao"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col :cols="$isMobile() ? 12 : 4">
        <EnviarTabelaCentral :subprogramadores="subprogramadores" v-show="false"/>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.button-gravar {
  padding: 15px 20px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
}
</style>