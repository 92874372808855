<script>
import { mapState } from 'vuex'

export default {
  name: 'FiltrosCadastroUsuario',
  data () {
    return {
      showCollapse: false,
      selectedUsuario: null,
      selectUsuarioList: [],
      botaoClick: ''
    }
  },
  computed: {
    ...mapState({
        user: state => state.moduleUsuario.user,
        lista_usuarios: state => state.moduleUsuario.lista_usuarios,
        selected_usuario_list: state => state.moduleUsuario.selected_usuario_list,
        campo_usuario: state => state.moduleUsuario.campo_usuario,
        usuario_zero: state => state.moduleUsuario.usuario_zero
    }),   
    nomeIcone() {
      return this.showCollapse ? 'chevron-up' : 'chevron-down'
    },
  },
  created(){
    this.$store.dispatch('LISTA_USUARIOS')
    
  },
  methods: {
    gravar_rastreio(descricao){
      var login = this.user.login
      var procedimento = 'cadastro usuario'
      this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
    },
    alterar(){
      if(this.selectedUsuario == null){
        this.$swal.fire({
          title: 'Alterar o Usuario',
          text: 'Selecione o Usuario para Alterar!',
          icon: 'warning'
        })
        return
      }

      var estado = false
      this.botaoClick = "alterar"
      var modo = this.botaoClick
      this.$store.dispatch('CAMPO_USUARIO', {estado, modo}) 
      this.gravar_rastreio('clicou em alterar: ' + this.selectUsuarioList.find(x => x.value === this.selectedUsuario).text)
    },
    novo(){
      this.selectedUsuario = null
      this.$store.state.moduleUsuario.selected_usuario_list = { ...this.usuario_zero }
      var estado = false
      this.botaoClick = "novo"
      var modo = this.botaoClick
      this.$store.dispatch('CAMPO_USUARIO', {estado, modo}) 
      this.gravar_rastreio('clicou em novo')
    }
  },
  watch:{
    nomeIcone(){
      this.botaoClick = ''
      this.selectedUsuario = null
      var estado = true
      var modo = 'original'
      this.$store.dispatch('CAMPO_USUARIO', {estado, modo}) 
      this.$store.state.moduleUsuario.selected_usuario_list = { ...this.usuario_zero }
    },
    lista_usuarios(){
      this.selectUsuarioList = []
      for(let index in this.lista_usuarios){
        let usuario = this.lista_usuarios[index]
        var selectListOption = {
          value: usuario.login,
          text:  usuario.nome
        }
        this.selectUsuarioList.push(selectListOption)
      }
    },
    selectedUsuario(){
      if(this.botaoClick != "novo"){
        var estado = true
        var modo = 'original'
        this.$store.dispatch('CAMPO_USUARIO', {estado, modo}) 
        for(let index in this.lista_usuarios){
          let usuario = this.lista_usuarios[index]
          if (usuario.login == this.selectedUsuario)
          {
            this.$store.dispatch('SELECTED_USUARIO_LIST', {usuario})
          }  
        }
      }
    }
  }
}
</script>

<template>
  <b-container class="container">
    <b-row class="filtros-row">
      <b-col cols="9" md="10">
        <div class="textoFiltros">Filtros Cadastro de Usuario</div>
      </b-col>
      <b-col>
        <b-button v-b-toggle.collapse-2 class="collapse-button float-right"
          :class="showCollapse ? 'collapsed' : null"
          :aria-expanded="showCollapse ? 'true' : 'false'"
          aria-controls="collapse-4"
          @click="showCollapse = !showCollapse"
        >
          <icon :name="nomeIcone" />
        </b-button>
      </b-col>
    </b-row>
    <b-collapse id="collapse-2">
      <b-row>
        <b-col cols="12">
          <b-form-text class="float-left">Usuario:</b-form-text>
          <b-form-select v-model="selectedUsuario" :options="selectUsuarioList"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="float-right btn_filtro" v-b-toggle="'collapse-2'" @click="alterar" variant="warning">Alterar</b-button>
          <b-button class="float-right btn_filtro" v-b-toggle="'collapse-2'" @click="novo" variant="primary">Novo</b-button>
        </b-col>
      </b-row>
    </b-collapse>
  </b-container>
</template>

<style>
  .btn_filtro {
    margin: 1%;
  }
  
  .container {
    background-color: white;
    border-width: .2 rem;
  }

  .collapse-button {
    margin-top: 2px;
    margin-bottom: 2px;
    right:0;
  }

  .filtros-row {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .textoFiltros {
    font-weight: bold;
    color: #2c3e50;
    left: 0;
    text-align: left;
    padding-top: 1.5vh;
  }

  @media screen and (max-width: 500px){
    .textoLabels {
      color: #2c3e50;
      left: 0;
      text-align: left;
      padding-top: 1.5vh;
    }
  }

  @media screen and (min-width: 501px){
    .textoLabels {
      color: #2c3e50;
      left: 0;
      text-align: left;
      padding-top: 0.75vh;
    }
  }

  .row {
    margin-top: 2px;
  }

  
  .form-pesquisa-filtros {
    padding-bottom: 2vh;
  }
</style>
