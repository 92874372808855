export default {
    MENU(state, menu) {
        menu.then((val) => (
            state.menu = {...val}
        ));
    },
    CADASTRO_PERMISSAO_MENUS(state, cadastro_permissao_menus) {
        cadastro_permissao_menus.then((val) => (
            state.cadastro_permissao_menus = {...val }
        ));
    },
    ALTERA_PERMISSAO_MENUS(state, altera_permissao_menus) {
        altera_permissao_menus.then((val) => (
            state.altera_permissao_menus = {...val }
        ));
    }
}