<script>
import { mapState } from "vuex"
import Vue from "vue"

export default {
  name: "EspecialProgramador",
  props: { programador: Object },
  data() {
    return {
      opcaoDiasSemanasMobile: [
        { text: "Dom", value: "1" },
        { text: "Seg", value: "2" },
        { text: "Ter", value: "3" },
        { text: "Qua", value: "4" },
        { text: "Qui", value: "5" },
        { text: "Sex", value: "6" },
        { text: "Sab", value: "7" },
      ],
      opcaoDiasSemanas: [
        { text: "Domingo", value: "1" },
        { text: "Segunda", value: "2" },
        { text: "Terça", value: "3" },
        { text: "Quarta", value: "4" },
        { text: "Quinta", value: "5" },
        { text: "Sexta", value: "6" },
        { text: "Sabado", value: "7" },
      ],
    }
  },
  computed: {
    ...mapState({
      programador_editar: (state) => state.moduleProgramador.programador_editar,
    }),
  },
  methods: {
    removeEspecial(especial) {
      this.programador.especiais = this.programador.especiais.filter(function (
        item
      ) {
        return item !== especial
      })
    },
    planosEspeciais() {
      this.programador.especiais.push({
        data: "",
        dias_semana: [],
      })
    },
  },
  watch: {
    programador:{
      handler(val){
        let especiais = []
        for (let aux in val.especiais) {
          let especial = val.especiais[aux]
          especiais.push({
            data: new Date(especial.mesDiaAno),
            dias_semana: especial.diaExecutado,
          })
        }
        Vue.set(this.programador, "especiais", especiais)
      },
      immediate: true 
    },
  },
}
</script>

<template>
  <div>
    <b-row v-for="(planoEspecial, index) in programador.especiais" :key="index + Math.random()">
      <b-col>
        <b-row>
          <b-col :cols="$isMobile() ? 4 : 1">
            <b-form-text>Especial: {{ index + 1 }}</b-form-text>
          </b-col>
        </b-row>
        <b-row class="especial_n">
          <b-col :cols="$isMobile() ? 12 : 6" class="text-left">
            <b-form-text>Data</b-form-text>
            <b-form-datepicker class="input-cadastro shadow rounded" v-model="planoEspecial.data" show-seconds
              placeholder="Data" locale="pt-BR" :disabled="programador_editar"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"/>
          </b-col>
          <b-col :cols="$isMobile() ? 12 : 6" class="text-left">
            <b-form-text>Execulta Plano</b-form-text>
            <b-form-radio-group v-model="planoEspecial.dias_semana" :disabled="programador_editar" :options="$isMobile() ? opcaoDiasSemanasMobile : opcaoDiasSemanas"/>
          </b-col>
          <b-col>
            <b-form-text>Opções</b-form-text>
            <b-button class="button_remover_especial shadow rounded"  @click="removeEspecial(planoEspecial)" :disabled="programador_editar" variant="danger">
              Remover
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br/>
    <b-button class="button_especial shadow rounded" @click="planosEspeciais" :disabled="programador_editar" :variant="programador_editar == true ? 'outline-success' : 'success'">
      Inserir Dias Especiais
    </b-button>
    <hr/>
  </div>
</template>


<style>
.input-cadastro {
  box-sizing: border-box;
  margin: 2px;
}

.button_remover_especial {
  padding: 5px 5px 5px 5px;
  margin: 2px;
}

.button_especial {
  padding: 15px 20px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
}

.especial_n {
  margin: 1px;
  border: 1px solid black;
  margin-right: 5px !important;
  margin-left: 5px !important;
}
</style>