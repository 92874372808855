<script>
import { mapState } from "vuex"
import Funcao from "@/components/Programador/FuncaoProgramador"
import barramento_filtro from "@/components/Barramentos/Filtros"
import logo from "../../assets/sdm.png"
import  jsPDF  from "jspdf"
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)

export default {
  name: "SalvarPDF",
  data() {
    return {
      logo: logo,
      fileName: '',
      doc: '',
      opcaoDiasSemana: {
        '1': "Domingo",
        '2': "Segunda",
        '3': "Terça",
        '4': "Quarta",
        '5': "Quinta",
        '6': "Sexta",
        '7': "Sabado"
      },
      opcaoTipoFases: {
        'cm': 'Veicular Comum',
        'cv': 'Veicular Gradativo',
        'p': 'Pedestre Comum'
      },
      opcaoEstados: {
        '0' : "Acre",
        '1' : "Alagoas",
        '2' : "Amapa",
        '3' : "Amazonas - Leste",
        '4' : "Amazonas - Oeste",
        '5' : "Bahia",
        '6' : "Ceara",
        '7' : "Distrito Federal",
        '8' : "Espirito Santo",
        '9' : "Goias",
        '10' : "Maranhao",
        '11' : "Mato Grosso",
        '12' : "Mato Grosso do Sul",
        '13' : "Minas Gerais",
        '14' : "Para - Leste",
        '15' : "Para - Oeste",
        '16' : "Paraiba",
        '17' : "Parana",
        '18' : "Pernambuco",
        '19' : "Piaui",
        '20' : "Rio de Janeiro",
        '21' : "Rio Grande do Norte",
        '22' : "Rio Grande do Sul",
        '23' : "Rondonia",
        '24' : "Roraima",
        '25' : "Santa Catarina",
        '26' : "Sao Paulo",
        '27' : "Sergipe",
        '28' : "Tocantins"
      },
    }
  },
  created(){
    barramento_filtro.onLoadFileName(valor => {
      this.fileName = valor
    })
  },
  computed: {
    ...mapState({
      listaPlanos: (state) => state.moduleProgramador.programador_lista,
      user: state => state.moduleUsuario.user,
    }),
  },
  methods:{
    monitorar(valor){
      var login = this.user.login
      var procedimento = 'salvar pdf'
      var descricao = valor
      this.$store.dispatch('MONITORAMENTO_PROCESSOS', {login, procedimento, descricao})
    },
    salvarLocalPDF(){
      this.$swal.fire({
        title: "Salvar Programação em PDF",
        text: "Digite o nome do arquivo <sem .pdf>: ",
        input: "text",
        icon: "question",
        inputPlaceholder: "programacao",
        confirmButtonText: "Confirmar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          let exportName = result.value == "" ? this.fileName == '' ? "programacao" : this.fileName.replace('.json', '') : result.value
          this.gerarPDF(exportName)
          this.monitorar(exportName)
          //this.gravarMonitoramento('Salvar no PDF')
        } else {
          Funcao.alertaSimples("Salvar Programação Local", "Cancelado!","error")
        }
      })
    },
    tradutorEntradas(element){
      let entradas = []
      for (let aux in element.Entradas) {
        let entrada = element.Entradas[aux]
        let horario = new Date(0, 0, 0, entrada.hora, entrada.minuto, entrada.segundo, 0)
        horario =
          horario.getHours().toString().padStart(2, "0") + ":" +
          horario.getMinutes().toString().padStart(2, "0") + ":" +
          horario.getSeconds().toString().padStart(2, "0")

        if (entradas.length == 0){
          let dias_semana = []
          dias_semana.push(parseInt(entrada.dia))
          entradas.push({
            horario: horario,
            dias_semana: dias_semana,
            plano: entrada.plano,
          })
        }else{
          let possui = false
          let posicao = 0
          for (let aux1 in entradas){
            let entrada1 = entradas[aux1]
            let horario1 = entrada1.horario
            if (horario1 == horario && entrada.plano == entrada1.plano){
              possui = true
              posicao = aux1
            }
          }
          if (possui) {
            entradas[posicao].dias_semana.push(parseInt(entrada.dia))
            entradas[posicao].allSelected = entradas[posicao].dias_semana.length == 7 ? true : false
          } else {
            let dias_semana = []
            dias_semana.push(parseInt(entrada.dia))
            entradas.push({
              horario: horario,
              dias_semana: dias_semana,
              plano: entrada.plano,
            })
          }
        }
      }
     return entradas      
    },
    cabecalho(chave, texto){
      this.controlePosicao(2.5)
      this.doc.setFont("Times", "bold").setFontSize(15).text(chave, 10, this.posicao)
      this.doc.setFont("Times", "normal").setFontSize(15).text(texto, (chave.length * 3) + 10, this.posicao)
      this.controlePosicao(5)
    },
    controlePosicao(espaco){
      if (this.posicao > 280){
        this.doc.addPage('a4','p')
        this.posicao = 10
      }else {
        this.posicao += espaco
      }
    },
    titulo(texto){
      this.controlePosicao(5)
      this.doc.setFont("Times", "bold").setFontSize(20)
      this.doc.text(texto, 10, this.posicao)
      this.controlePosicao(5)
    },
    subTitulo(texto){
      this.controlePosicao(5)
      this.doc.setFont("Times", "bold").setFontSize(15)
      this.doc.text(texto, 10, this.posicao)
      this.controlePosicao(5)
    },
    legenda(texto){
      this.doc.setFont("Times", "normal").setFontSize(10)
      this.doc.text(texto, 15, this.posicao)
      this.controlePosicao(5)
    },
    calculoSincronismo(planos){
      for (let indexTempo in planos.tempos){
        let tempo = planos.tempos[indexTempo]
        if (indexTempo == 0){
          tempo.cs = planos.defasagem 
        }else
        if (indexTempo > 0){
          tempo.cs = planos.defasagem 
          
          for (let aux = indexTempo - 1; aux >= 0; aux--) {
            let tempoAnterior = planos.tempos[aux]
            tempo.cs += tempoAnterior.vd
            tempo.cs += tempoAnterior.am 
            tempo.cs += tempoAnterior.vmb 
          }
          if (tempo.cs > planos.tempoCiclo ){
            tempo.cs -= planos.tempoCiclo 
          }
        }
      }
    },
    calculoSincronismoExt(planos){
      for (let indexTempo in planos.tempos){
        let tempo = planos.tempos[indexTempo]
        if (indexTempo == 0){
          tempo.cse = planos.defasagemE 
        }else
        if (indexTempo > 0){
          tempo.cse = planos.defasagemE 
          
          for (let aux = indexTempo - 1; aux >= 0; aux--) {
            let tempoAnterior = planos.tempos[aux]
            tempo.cse += tempoAnterior.vde
            tempo.cse += tempoAnterior.ame
            tempo.cse += tempoAnterior.vmbe 
          }
          if (tempo.cse > planos.tempoCicloE ){
            tempo.cse -= planos.tempoCicloE 
          }
        }
      }
    },
    gerarPDF(nomePDF) {
      this.doc = new jsPDF()
      this.doc.addImage(this.logo, "PNG", 150, 10, 45, 25)
      this.posicao = 10
      
      this.cabecalho("Cruzamento: ", this.listaPlanos.Cabecalho.nome)
      this.cabecalho("Estado: ", this.opcaoEstados[this.listaPlanos.Cabecalho.estado])
      this.cabecalho("Cidade: ", this.listaPlanos.Cabecalho.cidade)
      this.cabecalho("TimeZone: ", this.listaPlanos.Cabecalho.timeZone)
      let inicioFase = 0
      this.doc.line(10, this.posicao, 195, this.posicao)
      this.controlePosicao(5)
      for (const key in this.listaPlanos.Subcontrolador) {
        const element = this.listaPlanos.Subcontrolador[key]
        if(this.listaPlanos.Subcontrolador.length == 1){
          this.titulo("Anel")
        }else{
          inicioFase += key > 0 ? parseInt(element.FasesQtde) : 0 
          if (element.identificacao == undefined)
            this.titulo("Anel " + (parseInt(key) + 1))
          else
            this.titulo("Anel " + (parseInt(key) + 1) + " - " + element.identificacao)
        }
        //********************** Croquei do Cruzamento **********************/
        this.subTitulo("Croquei do Cruzamento")
        let cruzamento = []
        for (const key in element.Aproximacoes) {
          const aproximacao = element.Aproximacoes[key]
          cruzamento.push({fase: (parseInt(key) + 1 + inicioFase), descricao: aproximacao})
        }
        this.doc.autoTable({
          startY: this.posicao,
          body: cruzamento,
          headStyles: { halign: 'center'},
          columnStyles: { 
            fase: {halign: 'center'},
            descricao: {halign: 'center'},
          },
          columns: [
            { header: 'Fase', dataKey: 'fase' },
            { header: 'Descrição', dataKey: 'descricao' },
          ],
        })
        this.posicao = this.doc.autoTable.previous.finalY + 10
        //********************** Croquei do Cruzamento **********************/

        //********************** Tipos de Fases **********************/
        this.subTitulo("Tipos de Fases")
        let tipo = []
        for (const key in element.TipoFases) {
          const tipoFase = element.TipoFases[key]
          tipo.push({fase: (parseInt(key) + 1 + inicioFase), descricao: this.opcaoTipoFases[tipoFase]})
        }
        this.doc.autoTable({
          startY: this.posicao,
          body: tipo,
          headStyles: { halign: 'center'},
          columnStyles: { 
            fase: {halign: 'center'},
            descricao: {halign: 'center'},
          },
          columns: [
            { header: 'Fase', dataKey: 'fase' },
            { header: 'Descrição', dataKey: 'descricao' },
          ],
        })
        this.posicao = this.doc.autoTable.previous.finalY + 10
        //********************** Tipos de Fases **********************/

        //********************** Conflitos Entre Fases **********************/
        this.subTitulo("Conflitos Entre Fases")
        let conflitoTabela = []
        for (const key in element.TipoFases) {
          const conflito = element.Conflitos['cf'+(parseInt(key) + 1)]
          let aux = Funcao.tradutorConflito(conflito)
          aux = aux.length > 0 ? aux.slice(0, -2) : aux
          conflitoTabela.push({fase: (parseInt(key) + 1 + inicioFase), descricao: aux})
        }
        this.doc.autoTable({
          startY: this.posicao,
          body: conflitoTabela,
          headStyles: { halign: 'center'},
          columnStyles: { 
            fase: {halign: 'center'},
            descricao: {halign: 'center'},
          },
          columns: [
            { header: 'Fase', dataKey: 'fase' },
            { header: 'Descrição', dataKey: 'descricao' },
          ],
        })
        this.posicao = this.doc.autoTable.previous.finalY + 10
        //********************** Conflitos Entre Fases **********************/

        //********************** Estagios **********************/
        this.subTitulo("Estagios")
        let estagioTabela = []
        for (const key in element.Estagios) {
          const estagio = element.Estagios[key]
          let fasesTabela = ''
          for (const key in estagio.fases) {
            fasesTabela += 'Fase' + (parseInt(estagio.fases[key])  + parseInt(inicioFase)) + '| '
          }
          fasesTabela = fasesTabela.length > 0 ? fasesTabela.slice(0, -2) : fasesTabela
          estagioTabela.push({estagio: (parseInt(key) + 1), fases: fasesTabela, atuadorSincronizado: (estagio.atuador ? 'Sim' : 'Não') +' / '+ (estagio.atuadorSincronizado ? 'Sim' : 'Não')})
        }
        this.doc.autoTable({
          startY: this.posicao,
          body: estagioTabela,
          headStyles: { halign: 'center'},
          columnStyles: { 
            estagio: {halign: 'center'},
            fases: {halign: 'center'},
            atuadorSincronizado: {halign: 'center'},
          },
          columns: [
            { header: 'Estagio', dataKey: 'estagio' },
            { header: 'Fases', dataKey: 'fases' },
            { header: 'Atuado / Sincronizado', dataKey: 'atuadorSincronizado' },
          ],
        })
        this.posicao = this.doc.autoTable.previous.finalY + 10
        //********************** Estagios **********************/

        //********************** Ordens **********************/
        this.subTitulo("Ordens")
        let ordemTabela = []
        for (const key in element.Ordem) {
          const ordem = element.Ordem[key]
          let estagios = ''
          for (const key in ordem.estagios) {
            estagios += 'Estagios ' + ordem.estagios[key] +'| '            
          }
          estagios = estagios.length > 0 ? estagios.slice(0, -2) : estagios
          ordemTabela.push({ordem: (parseInt(key) + 1), estagios: estagios})
        }
        this.doc.autoTable({
          startY: this.posicao,
          body: ordemTabela,
          headStyles: { halign: 'center'},
          columnStyles: { 
            ordem: {halign: 'center'},
            estagios: {halign: 'center'},
          },
          columns: [
            { header: 'Ordem', dataKey: 'ordem' },
            { header: 'Estagios', dataKey: 'estagios' },
          ],
        })
        this.posicao = this.doc.autoTable.previous.finalY + 10
        //********************** Ordens **********************/

        //********************** Planos **********************/
        this.subTitulo("Planos")
        let mostrar = false
        for (const key in element.Estagios) {
          const estagio = element.Estagios[key]
          if(estagio.atuadorSincronizado){
            mostrar = estagio.atuadorSincronizado
          }
        }
        for (const key in element.Planos) {
          const plano = element.Planos[key]
          this.calculoSincronismo(plano)
          if (key == 0){
            this.legenda("Plano "+ key +": Piscante")
            this.controlePosicao(5)
          }else{
            this.controlePosicao(5)
            this.legenda("Plano "+ key +": Ordem " + (parseInt(plano.ordem) + 1))
            let tempos = []
            
            const ordem = element.Ordem[plano.ordem]
            for (const key1 in ordem.estagios) {
              const tempo = plano.tempos[key1]
              tempos.push(tempo)
              let index = tempos.lastIndexOf(tempo)
              tempos[index]['estagio'] = ordem.estagios[key1]            
            }

            tempos[0]['defasagem'] = plano.defasagem
            tempos[0]['tempoCiclo'] = plano.tempoCiclo
            this.doc.autoTable({
              startY: this.posicao,
              body: tempos,
              columnStyles: { 
                estagio: {halign: 'center'},
                vd: {halign: 'center'},
                am: {halign: 'center'},
                vmb: {halign: 'center'},
                cs: {halign: 'center'},
                pa: {halign: 'center'},
                defasagem: {halign: 'center'},
                tempoCiclo: {halign: 'center'}
              },
              columns: [
                { header: 'Estagio', dataKey: 'estagio' },
                { header: 'Verde', dataKey: 'vd' },
                { header: 'Amarelo', dataKey: 'am' },
                { header: 'Vermelho Bloqueio', dataKey: 'vmb'},
                { header: 'Calculo Sincronismo', dataKey: 'cs'},
                { header: 'Piscante Adicional', dataKey: 'pa'},
                { header: 'Defasagem Verde', dataKey: 'defasagem' },
                { header: 'Tempo Ciclo', dataKey: 'tempoCiclo' },
              ],
            })
            this.posicao = this.doc.autoTable.previous.finalY

            if (mostrar){ // extra 
              this.controlePosicao(10)
              this.calculoSincronismoExt(plano)

              tempos[0]['defasagemE'] = plano.defasagem
              tempos[0]['tempoCicloE'] = plano.tempoCiclo
              this.legenda("Plano "+ key +": Ordem " + (parseInt(plano.ordem) + 1) + ' estendido')
              this.doc.autoTable({
                startY: this.posicao,
                body: tempos,
                columnStyles: { 
                  estagio: {halign: 'center'},
                  vde: {halign: 'center'},
                  ame: {halign: 'center'},
                  vmbe: {halign: 'center'},
                  cse: {halign: 'center'},
                  pae: {halign: 'center'},
                  defasagemE: {halign: 'center'},
                  tempoCicloE: {halign: 'center'}
                },
                columns: [
                  { header: 'Estagio', dataKey: 'estagio' },
                  { header: 'Verde', dataKey: 'vde' },
                  { header: 'Amarelo', dataKey: 'ame' },
                  { header: 'Vermelho Bloqueio', dataKey: 'vmbe'},
                  { header: 'Calculo Sincronismo', dataKey: 'cse'},
                  { header: 'Piscante Adicional', dataKey: 'pae'},
                  { header: 'Defasagem Verde', dataKey: 'defasagemE' },
                  { header: 'Tempo Ciclo', dataKey: 'tempoCicloE' },
                ],
              })
              this.posicao = this.doc.autoTable.previous.finalY + 10
            }
          }
        }
        //********************** Planos **********************/

        //********************** Entradas **********************/
        this.subTitulo("Entradas")
        let entradaTabela = []
        const entradas = this.tradutorEntradas(element)
        for (const key in entradas) {
          const entrada = entradas[key]
          let semanas = ''
          let count = 0
          let dias = ''
          for (const key in entrada.dias_semana) {
            semanas += this.opcaoDiasSemana[entrada.dias_semana[key]] +'| '
            count += 1
            dias += entrada.dias_semana[key]
          }
          semanas = semanas.length > 0 ? semanas.slice(0, -2) : semanas
          if(count == 7){ semanas = 'Todos os dias' }
          else if (count == 5){
            if (dias == '23456'){ semanas = 'Dias úteis' }
          }else if (count == 2){
            if (dias == '17'){ semanas = 'Finais de semana' }
          }
          entradaTabela.push({entrada: (parseInt(key) + 1), horario: entrada.horario, plano: entrada.plano, semana: semanas})
        }

        this.doc.autoTable({
          startY: this.posicao,
          body: entradaTabela,
          headStyles: { halign: 'center'},
          columnStyles: { 
            entrada: {halign: 'center'},
            horario: {halign: 'center'},
            plano: {halign: 'center'},
            semana: {halign: 'center'},
          },
          columns: [
            { header: 'Entrada', dataKey: 'entrada' },
            { header: 'Horario', dataKey: 'horario' },
            { header: 'Plano', dataKey: 'plano' },
            { header: 'Semana', dataKey: 'semana' },
          ],
        })
        this.posicao = this.doc.autoTable.previous.finalY + 10
        //********************** Entradas **********************/

        //********************** Especiais **********************/
        this.subTitulo("Especiais")
        let especialTabela = []

        for (const key in element.DatasEspeciais) {
          const especial = element.DatasEspeciais[key]
          const data = new Date(especial.data),
          dia  = data.getDate().toString(),
          diaF = (dia.length == 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
          mesF = (mes.length == 1) ? '0'+mes : mes,
          anoF = data.getFullYear();

          especialTabela.push({especial: (parseInt(key) + 1), data: diaF+"/"+mesF+"/"+anoF, semana: this.opcaoDiasSemana[especial.dias_semana]})
        }
        this.doc.autoTable({
          startY: this.posicao,
          body: especialTabela,
          headStyles: { halign: 'center'},
          columnStyles: { 
            especial: {halign: 'center'},
            data: {halign: 'center'},
            semana: {halign: 'center'},
          },
          columns: [
            { header: 'Especial', dataKey: 'especial' },
            { header: 'Data', dataKey: 'data' },
            { header: 'Semana', dataKey: 'semana' },
          ],
        })
        this.posicao = this.doc.autoTable.previous.finalY + 10
        //********************** Especiais **********************/
      }
      this.doc.save(nomePDF + ".pdf");
    },
  }
}
</script>

<template>
  <b-button class="button-gravar shadow rounded" @click="salvarLocalPDF" variant="primary">
    Salvar no PDF
  </b-button>
</template>


<style>

</style>